import { TextField, Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import DeleteBtn from "../../../components/common/table/DeleteBtn";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../components/common/table/BorderRow";
import SaveBtn from "../../../components/common/table/SaveBtn";
import { CertificateNameInputStyles, CertificatesBtnsCell, CertificatesEditRowStyles } from "./CertificatesPage.styles";
import { CertificateItem } from "../../../types/certificates";
import { onCreateNewCertificate, setCertificatesToEdit } from "../../../store/reducers/certificatesReducer";
import { createNewCertificateThunk, deleteCertificateThunk } from "../../../store/thunk/certificatesThunk";
import { compareComplexData } from "../../../common/logic/GroupsUtils";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import TimePickerUIWrap from "../../../components/common/TimePickerUIWrap";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DropDownEmployee from "./DropDownEmployee";
import { useOnNewCertificateAdd } from "../../../store/selectors/certificatesSelectors";
import DocumentPopUp from "./DocumentPopUp/DocumentPopUp";
import { theme } from "../../../theme";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";

interface CertificatesCreateEditUserProps {
    certificateItem?: CertificateItem;
    isNewCertificateAdd?: boolean;
}

const CertificatesCreateOrEdit: FC<
    CertificatesCreateEditUserProps
> = ({ certificateItem, isNewCertificateAdd }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [certificateData, setCertificateData] = useState<CertificateItem>({
        id: 0,
        documentName: "",
        employeeFullName: "",
        employeeId: "",
        endDate: "",
        notificationDate: "",
        documentExchangeWalletId: 0,
        documents: [],
        employeeAccountStatus: ""
    });

    const { documentName, endDate, notificationDate, employeeAccountStatus, documentExchangeWalletId, documents } = certificateData;
    const [certificateEndDate, setCertificateEndDate] = useState<Dayjs | null>(dayjs(`${endDate}`));
    const [certificateNotificationDate, setCertificateNotificationDate] = useState<Dayjs | null>(dayjs(`${notificationDate}`));

    useEffect(() => {
        setCertificateEndDate(dayjs(endDate));
    }, [endDate]);

    useEffect(() => {
        setCertificateNotificationDate(dayjs(notificationDate));
    }, [notificationDate]);

    useEffect(() => {
        if (certificateItem) setCertificateData(certificateItem);
    }, [certificateItem]);

    const newUserMode = useOnNewCertificateAdd();

    const handleOnBlur = (certificate?: CertificateItem) => {
        if (
            !compareComplexData(
                certificate ? certificate : (certificateItem as CertificateItem),
                certificateData
            ) &&
            !isNewCertificateAdd
        ) {
            if (certificate) {
                dispatch(setCertificatesToEdit(certificate));
            } else {
                dispatch(setCertificatesToEdit(certificateData));
            }
        }
    };

    const handleOnEndDateChange = (newValue: dayjs.Dayjs | null) => {
        setCertificateEndDate(newValue);
        let newDateString = newValue?.format('YYYY-MM-DDTHH:mm:ss')
        newDateString && setCertificateData({ ...certificateData, endDate: newDateString })
        newDateString && dispatch(setCertificatesToEdit({ ...certificateData, endDate: newDateString }))
    };

    const handleOnNotificationDateChange = (newValue: dayjs.Dayjs | null) => {
        setCertificateNotificationDate(newValue);
        let newDateString = newValue?.format('YYYY-MM-DDTHH:mm:ss')
        newDateString && setCertificateData({ ...certificateData, notificationDate: newDateString })
        newDateString && dispatch(setCertificatesToEdit({ ...certificateData, notificationDate: newDateString }))
    };

    const handleDropDownEmployeeId = (employeeId: string) => {
        employeeId && setCertificateData({ ...certificateData, employeeId })
        employeeId && dispatch(setCertificatesToEdit({ ...certificateData, employeeId }))
    }

    const handleOnSave = async () => {
        dispatch(setProcessingRequest(true))
        let res = await dispatch(createNewCertificateThunk(certificateData as CertificateItem))
        dispatch(setResponseValue(res.type))
        dispatch(setResponseMessage(t("dialogModals.itemCreatedDocument")))
    };

    const handleOnDelete = async () => {
        if (!certificateItem) {
            dispatch(onCreateNewCertificate(false));
        } else {
            dispatch(setProcessingRequest(true))
            let res = await dispatch(deleteCertificateThunk(certificateItem?.id))
            dispatch(setResponseValue(res.type))
            dispatch(setResponseMessage(t("dialogModals.itemDeletedDocument")))
        }
    };

    return (
        <CertificatesEditRowStyles>
            <CellBox>
                <CertificateNameInputStyles>
                    <TextField
                        value={documentName}
                        placeholder={t("documentManagementPage.documentManagement.placeholders.documentName")}
                        onChange={(e) =>
                            setCertificateData({ ...certificateData, documentName: e.target.value })
                        }
                        onBlur={() => {
                            handleOnBlur();
                        }}
                    />
                </CertificateNameInputStyles>
            </CellBox>
            <CellBox
                sx={{
                    paddingLeft: "35px",
                    height: "114px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "red",
                    [theme.breakpoints.down(1700)]: {
                        "&": {
                            padding: "0 0 0 25px!important",
                        },
                    },
                }}
            >
                <DropDownEmployee
                    currentSelectionId={certificateData.employeeId}
                    handleUpdate={handleDropDownEmployeeId}
                />
            </CellBox>
            <CellBox>
                <TimePickerUIWrap
                    sx={{ maxWidth: "150px", marginLeft: "5px", marginBottom: "0" }}
                >
                    <DatePicker
                        value={certificateEndDate}
                        onChange={handleOnEndDateChange}
                        format="ddd DD.MM.YYYY"
                        slots={{ openPickerIcon: ExpandMoreIcon }}
                    />
                </TimePickerUIWrap>
            </CellBox>
            <CellBox>
                <TimePickerUIWrap
                    sx={{ maxWidth: "150px", marginLeft: "5px", marginBottom: "0" }}
                >
                    <DatePicker
                        value={certificateNotificationDate}
                        onChange={handleOnNotificationDateChange}
                        format="ddd DD.MM.YYYY"
                        slots={{ openPickerIcon: ExpandMoreIcon }}
                    />
                </TimePickerUIWrap>
            </CellBox>
            <CellBox>
                <Typography>
                    <DocumentPopUp documentExchangeWalletId={documentExchangeWalletId} documentData={documents} />
                </Typography>
            </CellBox>
            <CellBox>
                <Typography><StatusDisplay status={employeeAccountStatus} /></Typography>
            </CellBox>

            <CertificatesBtnsCell newUserMode={newUserMode}>
                {isNewCertificateAdd && (
                    <SaveBtn
                        handleOnClick={handleOnSave}
                        hoverText={t("label.saveDocument")}
                    />
                )}
                <DeleteBtn
                    isCreateNewMode={newUserMode}
                    dialogMessage={t("dialogModals.confirmDeleteUser")}
                    handleOnDelete={handleOnDelete}
                />
            </CertificatesBtnsCell>
            <BorderRow />
        </CertificatesEditRowStyles>
    );
};

export default CertificatesCreateOrEdit;
