import { FC, useState } from "react";
import { TaxFreeBenefitUserType } from "../../../../types/taxFreeBenefitUsersType";
import { Tooltip } from "@mui/material";
import { useGroupsData } from "../../../../store/selectors/groupSelectors";
import CellBox from "../../../../components/common/table/CellBox";
import BorderRow from "../../../../components/common/table/BorderRow";
import { GroupItem } from "../../../../types/groupItem";
import { BasicTableRowStyles } from "../../../../components/common/Common.styles";
import StatusDisplay from "../../../../components/common/table/StatusDisplay";
import TooltipTypography from "../../../../components/common/TooltipTypography";

const TaxFreeBenefitTableRow: FC<TaxFreeBenefitUserType> = ({
  firstName,
  lastName,
  position,
  employeeNumber,
  groupId,
  status,
  earnedPointsTotal,
}) => {
  const name = firstName + " " + lastName;
  const groups = useGroupsData();
  const currentGroup = groups
    ? groups.find((group) => group.id === groupId)
    : undefined;
  const [partialUserData] = useState<{
    status: string;
    currentGroup: GroupItem | undefined;
  }>({
    status,
    currentGroup,
  });

  return (
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={name}
        />
      </CellBox>
      <CellBox contentPosition="center">
        <TooltipTypography
          value={position}
        />
      </CellBox>
      <CellBox contentPosition="center">
        <TooltipTypography
          value={employeeNumber}
        />
      </CellBox>
      <CellBox>
      <TooltipTypography
          value={currentGroup?.name}
        />
      </CellBox>
      <CellBox
        lastCol={true}
        contentPosition="center"
        contentDirection="column"
      >
      <>
        <Tooltip title={partialUserData.status}>
          <StatusDisplay status={partialUserData.status} />
        </Tooltip>
        <TooltipTypography
          value={earnedPointsTotal}
          sxTypography={{
            color: (theme) => theme.palette.textMain.secondary,
          }}
        />
      </>
      </CellBox>
      <BorderRow />
    </BasicTableRowStyles>
  );
};

export default TaxFreeBenefitTableRow;
