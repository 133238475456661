import { Box } from "@mui/material";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import { useBillsData, useNewBillMode, usePagination } from "../../../store/selectors/billsSelectors";
import { FC, useEffect } from "react";
import BillsTableRow from "./BillsTableRow";
import { useTableDataPaginated } from "../../../hooks/UseTablePagination";
import Pagination from "../../../components/common/table/Pagination";
import { setPagination } from "../../../store/reducers/billsReducer";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchSuperAdminBillingsThunk } from "../../../store/thunk/superAdminBillingsThunk";
import AddOrEditBillForm from "./edit/AddOrEditBillForm";

const BillsTableBody: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  const isNewBillAdd = useNewBillMode();
  useEffect(() => {
    dispatch(fetchSuperAdminBillingsThunk())
  }, []);
  
  const tableData = useBillsData();

  const { itemsPerPage, currentPage, totalPages } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    ["companyName", "invoiceNumber", "billType", "paymentMethod", "paymentStatus"]
  );

  const billItemsToRender = tableDataToRender.map((bill) => (
    <BillsTableRow key={bill.id} billData={bill} />
  ));

  return (
    <Box sx={{ display: "contents" }}>
      {isNewBillAdd && <AddOrEditBillForm isNewBillAdd={isNewBillAdd} />}
      {billItemsToRender}{" "}
      <Box sx={{ gridColumn: "1/8" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </Box>
  );
};

export default BillsTableBody;
