import { ScoreRange } from '../../../../../types/actionItem'
import Typography from '@mui/material/Typography';
import { ScoreRangesRow, ScoreRangesTable, ScoreRangesWrapper } from './ScoreRanges.styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

interface ScoreRangeLineProps {
  scoreRange: ScoreRange;
}

function ScoreRangeLine(props: ScoreRangeLineProps) {
  return (
    <ScoreRangesRow>
      <Typography style={{ flex: 2 }}>{props.scoreRange.scoreFrom}</Typography>
      <Typography style={{ flex: 1 }}>-</Typography>
      <Typography style={{ flex: 2 }}>&lt;{props.scoreRange.scoreTo}</Typography>
      <div style={{ flex: 1 }}><ArrowForwardIcon /></div>
      <Typography style={{ flex: 2 }}>{props.scoreRange.pointsRewarded}</Typography>
    </ScoreRangesRow>
  );
}

interface Props {
  scoreRanges: ScoreRange[],
}

const ScoreRanges = (props: Props) => {
  const { t } = useTranslation();

  return (
    <ScoreRangesWrapper>
      <Typography variant='h5'>{t("incentivationPage.scoreRangesTitle")}</Typography>

      <ScoreRangesTable>
        <ScoreRangesRow>
          <Typography style={{ flex: 2 }}>{t("incentivationPage.scoreRangesFrom")}</Typography>
          <div style={{ flex: 1 }}></div>
          <Typography style={{ flex: 2 }}>{t("incentivationPage.scoreRangesTo")}</Typography>
          <div style={{ flex: 1 }}></div>
          <Typography style={{ flex: 2 }}>{t("tableColumnHeaders.points")}</Typography>
        </ScoreRangesRow>

        {props.scoreRanges.map(range => <ScoreRangeLine scoreRange={range} />)}
      </ScoreRangesTable>
    </ScoreRangesWrapper>
  )
}

export default ScoreRanges