import { Box, Divider, Typography } from "@mui/material";
import LogoutButton from "../../../../authentication/ui/LogoutButton";
import { useObservable } from "../../../../common/ui/ObservableHook";
import { authenticationStateHandler } from "../../../../authentication/logic/AuthenticationStateHandler";
import { AccountPopoverStyles, MenuListStyles } from "../../Layout.styles";

interface Props {
  anchorEl: any;
  onClose: () => void;
  open: boolean;
}

export function AccountPopover(props: Props) {
  const profile = useObservable(authenticationStateHandler.profileObservable);
  const { anchorEl, onClose, open } = props;

  return (
    <AccountPopoverStyles
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
    >
      <Box>
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {profile?.firstName} {profile?.lastName}
        </Typography>
      </Box>
      <Divider />
      <MenuListStyles disablePadding dense>
        <LogoutButton />
      </MenuListStyles>
    </AccountPopoverStyles>
  );
}
