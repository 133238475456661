import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import {
  SortingDataType,
  TableSortDirection,
} from "../../../common/logic/TableSortUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SortIconStyles, TableColumnHeaderStyles } from "../Common.styles";

interface TableColumnHeaderProps {
  contentPosition?: string;
  firstCol?: boolean;
  lastCol?: boolean;
  label?: string;
  isSorted: boolean;
  button?: JSX.Element;
  dataField?: string;
  dataType?: SortingDataType;
  prevIcon?: React.ReactNode;
  dataSample?: any;
  handleOnTableSort?: (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType,
    dataSample?: any
  ) => void;
}

const TableColumnHeader: FC<TableColumnHeaderProps> = ({
  contentPosition,
  firstCol,
  lastCol,
  label,
  button,
  dataField,
  dataType,
  prevIcon,
  isSorted,
  dataSample,
  handleOnTableSort,
}) => {
  const handleOnSort = (direction: TableSortDirection) => {
    if (dataField && dataType && handleOnTableSort)
      handleOnTableSort(dataField, direction, dataType, dataSample);
  };

  return (
    <TableColumnHeaderStyles
      contentPosition={contentPosition}
      firstCol={firstCol}
      lastCol={lastCol}
    >
      <Box>
        {prevIcon ? prevIcon : null}
        {button ? (
          button
        ) : (
          <>
            <Typography>{label}</Typography>
            {isSorted ? (
              <SortIconStyles>
                <Button onClick={() => handleOnSort("desc")}>
                  <ArrowDropUpIcon sx={{ fontSize: "1.5rem" }} />
                </Button>
                <Button onClick={() => handleOnSort("asc")}>
                  <ArrowDropDownIcon sx={{ fontSize: "1.5rem" }} />
                </Button>
              </SortIconStyles>
            ) : (
              ""
            )}
          </>
        )}
      </Box>
    </TableColumnHeaderStyles>
  );
};

export default TableColumnHeader;
