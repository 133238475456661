import { SortingDataType, TableSortDirection } from "../../../../common/logic/TableSortUtils";
import TableColumnHeader from "../../../../components/common/table/TableColumnHeader";
import BorderRow from "../../../../components/common/table/BorderRow";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { sortCertificates } from "../../../../store/reducers/certificatesReducer";
import { TableHeaderWrapper } from "../../../../components/common/Common.styles";

const PopUpTableHeader = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleOnTableSort = (
        dataField: string,
        direction: TableSortDirection,
        dataType: SortingDataType
    ) => {
        dispatch(sortCertificates({ dataField, dataType, direction }));
    };
    return (
        <TableHeaderWrapper>
            <TableColumnHeader
                dataField="fileName"
                dataType="string"
                isSorted={true}
                label={t("tableColumnHeaders.fileName")}
                firstCol={true}
                handleOnTableSort={handleOnTableSort}
            />
            <TableColumnHeader
                dataField="ownerName"
                dataType="string"
                isSorted={true}
                label={t("tableColumnHeaders.uploadDate")}
                handleOnTableSort={handleOnTableSort}
            />
            <TableColumnHeader
                dataField="uploadTimestamp"
                dataType="string"
                isSorted={true}
                label={t("tableColumnHeaders.owner")}
                handleOnTableSort={handleOnTableSort}
            />
            <TableColumnHeader
                dataField="actions"
                dataType="string"
                isSorted={false}
                label={t("tableColumnHeaders.actions")}
            />
            <BorderRow />
        </TableHeaderWrapper>
    );
};

export default PopUpTableHeader;
