import { FC, useRef, useState } from 'react'
import UploadIcon from "@mui/icons-material/Upload";
import { theme } from "../../../../App";
import { useAppDispatch } from '../../../../hooks/StoreHooks';
import { FileUploaderContainer, UploadButtonStyles, UploadIconContainer } from './DoucmentPopUp.styles';
import { certificateFileUploadThunk } from '../../../../store/thunk/certificatesThunk';
import { Box } from '@mui/material';
import { useTranslation } from "react-i18next";

export interface FileUploadData {
    billingId: number
    fileType: string
    file: string | undefined
}

interface UploadBtnProps {
    title: string;
    documentExchangeWalletId: number;
}

export interface CertificateUploadData {
    documentExchangeWalletId: number
    filePayload: {
        fileName: string
        file: string | undefined
    }
}

const FileUploaderBox: FC<UploadBtnProps> = ({ title, documentExchangeWalletId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [base64, setBase64] = useState<string>();
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onload = onReaderLoaded
            reader.readAsBinaryString(e.target.files[0])
        }
    };

    const onReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        setBase64(btoa(binaryString))
    }

    const handleUpload = async () => {
        if (file) {
            let filePayload = {
                fileName: file.name,
                file: base64
            }
            let fileDataToSend: CertificateUploadData = { documentExchangeWalletId, filePayload }
            await dispatch(certificateFileUploadThunk(fileDataToSend));
            setFile(null);
            setBase64("");
        }
    };

    return (
        <FileUploaderContainer>
            <UploadIconContainer onClick={() => handleClick()}>
                <input type="file" ref={hiddenFileInput} style={{ display: "none" }} onChange={handleChange} />
                <UploadIcon sx={{ color: file ? theme.palette.border.subform : theme.palette.companyMain.main }} />
            </UploadIconContainer>
            <div>
                {file ?
                    <UploadButtonStyles onClick={handleUpload}>{file.name}</UploadButtonStyles>
                    :
                    <Box>{t("label.uploadFile")}</Box>
                }
            </div>
        </FileUploaderContainer>
    )
}

export default FileUploaderBox