import React from "react";
import { useParams } from "react-router-dom";
import ActionModuleCard from "./ActionModuleCard";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

const CreateActionPage = () => {
  const { groupsType } = useParams();
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      title: "actionPage.managementTool",
      link: "",
    },
    {
      title: "actionPage.newActionModule",
      link: `groups/${groupsType}/create-action`,
    },
  ];
  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("groupsPage.groups.title")}
      />
      <ActionModuleCard />
    </>
  );
};

export default CreateActionPage;
