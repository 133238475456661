import {
  Badge,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { AccountPopover } from "./TopNavComponents/Account/AccountPopover";
import { usePopover } from "../../hooks/UsePopover";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import { AvatarStyles, NavStackStyles, TopNavStyles } from "./Layout.styles";
import LanguageSwitcher from "../../common/ui/LanguageSwitcher";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationPopover from "./TopNavComponents/Notification/NotificationPopover";
import { useUnreadNotificationAmount } from "../../store/selectors/notificationSelector";
import { useEffect } from "react";
import { fetchUnreadNotificationAmountThunk } from "../../store/thunk/notificationThunk";
import { useAppDispatch } from "../../hooks/StoreHooks";

interface Props {
  onNavOpen: () => void;
}

export function TopNav(props: Props) {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const notificationPopover = usePopover();
  const dispatch = useAppDispatch();
  const unreadNotificationAmount = useUnreadNotificationAmount();

  useEffect(() => {
    dispatch(fetchUnreadNotificationAmountThunk());
  }, []);

  return (
    <>
      <TopNavStyles component="header">
        <NavStackStyles
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            )}
            <Tooltip title="Search">
              <IconButton>
                <SvgIcon fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Tooltip title="Contacts">
              <IconButton>
                <SvgIcon fontSize="small">
                  <PeopleIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <IconButton
                onClick={notificationPopover.handleOpen}
                ref={notificationPopover.anchorRef} >
                <Badge badgeContent={unreadNotificationAmount && unreadNotificationAmount> 9 ? "9+" : unreadNotificationAmount} color="warning">
                  <SvgIcon fontSize="small">
                    <NotificationsIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <AvatarStyles
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
            />
            <LanguageSwitcher />
          </Stack>
        </NavStackStyles>
      </TopNavStyles >
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
      <NotificationPopover
        anchorEl={notificationPopover.anchorRef.current}
        open={notificationPopover.open}
        onClose={notificationPopover.handleClose} />
    </>
  );
}
