import { Box } from "@mui/material";
import ActionFormWrapper from "./ActionFormWrapper";
import { FC, useEffect, useState } from "react";
import RadioButton from "../../../../components/common/RadioButton";
import RecurrenceForm from "./RecurrenceForm";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../hooks/UseLocale";
import {
  ActionFormBoxStyles,
  PatternsBox,
  RecurrencePatternRadioBtn,
} from "../ActionModuleCard.styles";
import { Container } from "../../../../components/common/Common.styles";

const recurrencePatternsList = [
  "daily",
  "weekly",
  "monthly",
  "yearly",
  "executeOnce",
];

const RecurrencePattern: FC<{
  setDisabledExecutionField: (isDisabled: boolean) => void;
}> = ({ setDisabledExecutionField }) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const [recurrence, setRecurrence] = useState<string>(t("frequency.daily"));

  useEffect(() => {
    setRecurrence(t("frequency.daily"));
  }, [locale]);

  useEffect(() => {
    if (
      recurrence === t("frequency.monthly") ||
      recurrence === t("frequency.yearly") ||
      recurrence === t("actionPage.executeOnce")
    ) {
      setDisabledExecutionField(true);
    } else setDisabledExecutionField(false);
  }, [recurrence]);

  const recurrenceRadioGroup = recurrencePatternsList.map((item) => {
    const itemTranslated = t(`actionPage.${item}`);
    const handleOnClick = () => {
      setRecurrence(itemTranslated);
    };

    const isChecked = recurrence === itemTranslated ? true : false;

    return (
      <RecurrencePatternRadioBtn>
        <RadioButton
          handleOnClick={handleOnClick}
          key={item}
          isChecked={isChecked}
          label={itemTranslated}
        />
      </RecurrencePatternRadioBtn>
    );
  });

  return (
    <ActionFormBoxStyles>
      <ActionFormWrapper label={t("actionPage.recurrencePattern")}>
        <Container vPosition="flex-start">
          <PatternsBox>{recurrenceRadioGroup}</PatternsBox>
          <Box>
            <RecurrenceForm recurrence={recurrence} />
          </Box>
        </Container>
      </ActionFormWrapper>
    </ActionFormBoxStyles>
  );
};

export default RecurrencePattern;
