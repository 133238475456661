import { useEffect, useState } from "react";

export const useTableDataPaginated = (
  tableData: any[],
  itemsPerPage: number,
  pageNum: number
) => {
  const [tableDataPaginated, setTableDataPaginated] = useState<any[]>([]);
  useEffect(() => {
    setTableDataPaginated(
      tableData.slice(itemsPerPage * (pageNum - 1), pageNum * itemsPerPage)
    );
  }, [tableData, itemsPerPage, pageNum]);

  return tableDataPaginated;
};
