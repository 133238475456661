import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import AppDesignContainer from "./AppDesignContainer";


const breadcrumbs = [
  {
    title: "configurationPage.appDesign",
    link: "configuration/app-design",
  },
];

const AppDesignPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("configurationPage.title")}
      />
      <AppDesignContainer />
    </>
  );
};

export default AppDesignPage;