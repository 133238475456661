import { AccountManagementUserType } from "../types/accountManagementUsersType";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";

export const employeesApi = {
  getAccountManagementEmployees: () =>
    doApiGet("/api/companies/current/account-management/employees")
      .then(res => res.result)
      .catch(error => console.log(error)),

  createEmployee: (userData: Partial<AccountManagementUserType>[]) =>
    doApiPost("/api/companies/current/employees", userData)
      .then(res => res.result)
      .catch(error => console.log(error)),

  editAccountManagementEmployee: (id: string, userData: Partial<AccountManagementUserType>) => {
    doApiPatch(`/api/employee/${id}/accountmanagement`, userData)
      .then(res => res)
      .catch(error => console.log(error))
  },

  deleteEmployee: (userId: string) => {
    doApiDelete(`/api/companies/current/employees/${userId}`)
      .then(res => res)
      .catch(error => console.log(error))
  },

  getTaxFreeBenefitUsers: () =>
    doApiGet("/api/companies/current/taxfreebenefits/employees")
      .then(res => res.result)
      .catch(error => error),

  editTaxFreeBenefitUser: (
    userId: string,
    { groupId, status }: { groupId: number; status: string }
  ) =>
    doApiPatch(`/api/employee/${userId}`, { groupId, status })
      .then(res => res)
      .catch(error => console.log(error)),
};
