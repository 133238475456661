import { Box, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { stringValidation, validateDataField } from "../utils";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { setActionData } from "../../../store/reducers/actionsReducer";
import { MAX_ACTIONS_POINTS } from "../constants";
import { useTranslation } from "react-i18next";

const ActionDescription = () => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const pointsRef = useRef(null);
  const [points, setPoints] = useState<string>("0");
  const [pointsPercentages, setPointsPercentages] = useState<string>(
    `${Math.round((Number(points) * 100) / MAX_ACTIONS_POINTS)}%`
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState<{
    titleError: string;
    descriptionError: string;
    pointsError: string;
  }>({ titleError: "", descriptionError: "", pointsError: "" });

  useEffect(() => {
    dispatch(setActionData({ maxPoints: Number(points) }));
  }, []);

  const onTitleBlur = () => {
    const title = stringValidation(
      (titleRef.current! as HTMLInputElement).value
    );
    if (title === "error") setErrors({ ...errors, titleError: title });
    else {
      dispatch(setActionData({ name: title as string }));
      setErrors({ ...errors, titleError: "" });
    }
  };

  const onDescriptionBlur = () => {
    const description = stringValidation(
      (descriptionRef.current! as HTMLInputElement).value
    );
    if (description === "error")
      setErrors({ ...errors, descriptionError: description });
    else {
      dispatch(setActionData({ description: description as string }));
      setErrors({ ...errors, descriptionError: "" });
    }
  };

  const onPointsBlur = () => {
    const points = validateDataField(
      (pointsRef.current! as HTMLInputElement).value as string,
      MAX_ACTIONS_POINTS
    );
    if (points === "error") setErrors({ ...errors, pointsError: points });
    else {
      dispatch(setActionData({ maxPoints: points as number }));
      setErrors({ ...errors, pointsError: "" });
    }
  };

  const handleOnSetPoints = (e: SyntheticEvent) => {
    const pointsValue = (e.target as HTMLInputElement).value as string;
    setPoints(pointsValue);
    const validatedPointsValue = validateDataField(
      pointsValue,
      MAX_ACTIONS_POINTS
    );
    if (validatedPointsValue === "error") {
      setErrors({ ...errors, pointsError: points });
      setPointsPercentages("error");
    } else {
      const percentages = Math.round(
        (Number(validatedPointsValue) * 100) / MAX_ACTIONS_POINTS
      );
      setPointsPercentages(`${percentages}%`);
      dispatch(
        setActionData({ maxPoints: validatedPointsValue as number })
      );
      setErrors({ ...errors, pointsError: "" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginTop: "28px",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "calc(100% - 111px)",
          maxWidth: "413px",
          "input, textarea": {
            boxSizing: "border-box",
            display: "block",
            width: "63px",
            height: "30px",
            padding: "3px 9px",
            fontSize: "1.125rem",
            border: `1px solid ${theme.palette.textMain.main}`,
            borderRadius: "2px",
            color: theme.palette.textMain.main,
            "&::-webkit-input-placeholder": {
              color: theme.palette.textMain.secondary,
            },
          },
          input: {
            width: "100%",
            maxWidth: "248px",
            border:
              errors.titleError &&
              `1px solid ${theme.palette.warningColor.main}`,
          },
          textarea: {
            fontFamily: "inherit",
            marginTop: "14px",
            width: "100%",
            height: "49px",
            resize: "none",
            border:
              errors.descriptionError &&
              `1px solid ${theme.palette.warningColor.main}`,
          },
        })}
      >
        <input
          onBlur={onTitleBlur}
          ref={titleRef}
          type="text"
          placeholder={t("actionPage.actionTitle")}
        />
        <textarea
          onBlur={onDescriptionBlur}
          ref={descriptionRef}
          placeholder={t("actionPage.actionDescription")}
        />
      </Box>
      <Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "1.5rem",
            color: theme.palette.textMain.secondary,
            fontWeight: "600",
            lineHeight: "1.2",
            input: {
              width: "63px",
              height: "30px",
              fontSize: "1.5rem",
              textAling: "center",
              border: `1px solid ${
                errors.pointsError
                  ? theme.palette.warningColor.main
                  : theme.palette.textMain.main
              }`,
              borderRadius: "2px",
              backgroundColor: theme.palette.backgroundColor.secondary,
              color: theme.palette.textMain.secondary,
            },
          })}
        >
          <input
            onBlur={onPointsBlur}
            value={points}
            onChange={handleOnSetPoints}
            ref={pointsRef}
            type="text"
          />
          <span>/{MAX_ACTIONS_POINTS}</span>
        </Box>
        <Typography
          sx={(theme) => ({
            textAlign: "right",
            fontSize: "1.5rem",
            lineHeight: "1.2",
            color: theme.palette.textMain.secondary,
            fontWeight: "400",
          })}
        >
          {pointsPercentages}
        </Typography>
      </Box>
    </Box>
  );
};

export default ActionDescription;
