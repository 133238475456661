import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationItem, NotificationWithPagination } from "../../types/notificationType";
import { fetchNotificationListThunk, fetchUnreadNotificationAmountThunk } from "../thunk/notificationThunk";

export interface Notification {
    unreadNotificationAmount: number;
    notificationList: NotificationWithPagination;
    notificationToEdit: Partial<NotificationItem>
}

const initialState: Notification = {
    unreadNotificationAmount: 0,
    notificationList: {
        sitecount: 0,
        items: []
    },
    notificationToEdit: {}
};

export const notificationReducer = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setUnreadNotificationAmount: (state, action: PayloadAction<number>) => {
            state.unreadNotificationAmount = action.payload;
        },
        setNotificationList: (state, action: PayloadAction<NotificationWithPagination>) => {
            state.notificationList = action.payload;
        },
        setNotificationToEdit: (state, action: PayloadAction<Partial<NotificationItem>>) => {
            state.notificationToEdit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchUnreadNotificationAmountThunk.fulfilled,
            (state, action) => {
                state.unreadNotificationAmount = action.payload as number;
            }
        );
        builder.addCase(
            fetchNotificationListThunk.fulfilled,
            (state, action) => {
                state.notificationList = action.payload as NotificationWithPagination;
            }
        );
    }
})

export const {
    setUnreadNotificationAmount,
    setNotificationList,
    setNotificationToEdit
} = notificationReducer.actions;

export default notificationReducer.reducer;