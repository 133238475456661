import { Stack, TextField } from "@mui/material";
import { Address } from "../model/Address";
import { useTranslation } from "react-i18next";

interface Props {
    address: Address,
    setAddress: (newValue: Address) => void
}

/**
 * This component provides a general purpose address input field for street, number, postal code, town and country
 */
export default function AddressField(props: Props) {
    const { t } = useTranslation();

    return <Stack gap={1}>
        <Stack direction="row" gap={1}>
            <TextField
                required
                label={t("authentication.register.street")}
                fullWidth
                disabled={false}
                variant="outlined"
                sx={{ flex: 2 }}
                value={props.address.street}
                onChange={(v) =>
                    props.setAddress({ ...props.address, street: v.target.value })}
            />
            <TextField
                required
                label={t("authentication.register.streetNo")}
                fullWidth
                disabled={false}
                variant="outlined"
                sx={{ flex: 1 }}
                value={props.address.number}
                onChange={(v) =>
                    props.setAddress({ ...props.address, number: v.target.value })}
            />
        </Stack>
        <Stack direction="row" gap={1}>
            <TextField
                required
                label={t("authentication.register.postalCode")}
                fullWidth
                disabled={false}
                variant="outlined"
                sx={{ flex: 1 }}
                value={props.address.postalCode}
                onChange={(v) =>
                    props.setAddress({ ...props.address, postalCode: v.target.value })}
            />
            <TextField
                required
                label={t("authentication.register.town")}
                fullWidth
                disabled={false}
                variant="outlined"
                sx={{ flex: 2 }}
                value={props.address.town}
                onChange={(v) =>
                    props.setAddress({ ...props.address, town: v.target.value })}
            />
        </Stack>
        <TextField
            required
            label={t("authentication.register.country")}
            fullWidth
            disabled={false}
            variant="outlined"
            sx={{ flex: 2 }}
            value={props.address.country}
            onChange={(v) =>
                props.setAddress({ ...props.address, country: v.target.value })}
        />
    </Stack>
}