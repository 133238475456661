import logo from '../assets/Bonisoft__signet_iceblue.svg'
import Box from "@mui/material/Box";

const BonisoftLogo = ({ imgRatio }: { imgRatio: number }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <img src={logo} width={`${imgRatio}%`} alt="BonisoftLogo" />
    </Box>
  )
}

export default BonisoftLogo