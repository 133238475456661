import { useTableDataToRender } from "../../../../hooks/UseTableDataToRender";
import { FC } from "react";
import { DocumentItem } from "../../../../types/certificates";
import PopUpTableRow from "./PopUpTableRow";

const PopUpTableBody: FC<{ documentData: DocumentItem[], documentExchangeWalletId: number }> = ({ documentData, documentExchangeWalletId }) => {

  const tableDataToRender = useTableDataToRender(
    "",
    documentData,
    ["fileName", "uploadTimestamp", "ownerName", "actions"]
  );

  const documentItemsToRender = tableDataToRender.map((documentItemData: DocumentItem) => (
    <PopUpTableRow key={documentItemData.id} documentItemData={documentItemData} documentExchangeWalletId={documentExchangeWalletId} />
  ));

  return (
    <>
      {documentItemsToRender}
    </>
  );
};

export default PopUpTableBody;
