import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDesign } from "../../types/configurationType";
import { fetchAppDesignThunk } from "../thunk/configurationThunk";

export interface ConfigInterface {
    configEditMode: boolean,
    appDesign: AppDesign
}

const initialState: ConfigInterface = {
    configEditMode: false,
    appDesign: {
        companyLogo: "",
        companyLogoBase64: "",
        accentColor: ""
    }
};

export const configurationReducer = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setAppDesignCompanyLogo: (state, action: PayloadAction<string | null>) => {
            state.appDesign.companyLogo = action.payload;
        },
        setAppDesignCompanyLogoBase64: (state, action: PayloadAction<string | null>) => {
            state.appDesign.companyLogoBase64 = action.payload;
        },
        setAppDesignAccentColor: (state, action: PayloadAction<string | null | undefined>) => {
            state.appDesign.accentColor = action.payload;
        },
        setConfigEditMode: (state, action: PayloadAction<boolean>) => {
            state.configEditMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchAppDesignThunk.fulfilled,
            (state, action) => {
                state.appDesign = action.payload as Partial<AppDesign>;
            }
        );
    }
})

export const {
    setAppDesignCompanyLogo,
    setAppDesignCompanyLogoBase64,
    setAppDesignAccentColor,
    setConfigEditMode
} = configurationReducer.actions;

export default configurationReducer.reducer;