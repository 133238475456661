import { useAppSelector } from "../../hooks/StoreHooks";

export const useEditMode = () =>
  useAppSelector((state) => state.groups.editMode);

export const useOnNewGroupAdd = () =>
  useAppSelector((state) => state.groups.isNewGroupToAdd);

export const useGroupsData = () =>
  useAppSelector((state) => state.groups.groupsData);

export const useEditedGroupsData = () =>
  useAppSelector((state) => state.groups.editedGroupsData);

export const useOnGroupsSave = () =>
  useAppSelector((state) => state.groups.onSave);

export const usePagination = () =>
  useAppSelector((state) => state.groups.pagination);
