import React from "react";
import { useParams } from "react-router-dom";
import { useSpecificAction } from "../../store/selectors/actionsSelectors";
import ActionModuleCard from "./ActionModuleCard";
import PageTitle from "../../components/common/PageTitle/PageTitle";

const EditActionPage = () => {
  const { groupsType, actionId } = useParams();
  const action = useSpecificAction(Number(actionId));

  const breadcrumbs = [
    {
      title: "Management-Tool",
      link: "",
    },
    {
      title: `${action?.name}`,
      link: `groups/${groupsType}/edit-action/${actionId}`,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={"Groups"} />
      <ActionModuleCard actionData={action} />
    </>
  );
};

export default EditActionPage;
