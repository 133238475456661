import { FC } from "react";
import { Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import BorderRow from "../../../components/common/table/BorderRow";
import { CertificatesPageRowStyles } from "./CertificatesPage.styles";
import { CertificateItem } from "../../../types/certificates";
import { useCertificatesEditMode } from "../../../store/selectors/certificatesSelectors";
import CertificatesCreateOrEdit from "./CertificatesCreateOrEdit";
import DocumentPopUp from "./DocumentPopUp/DocumentPopUp";
import TooltipTypography from "../../../components/common/TooltipTypography";
import { useTranslation } from "react-i18next";

const CertificatesTableRow: FC<{ certificateData: CertificateItem }> = ({ certificateData }) => {

  const { documentName, employeeFullName, endDate, notificationDate, documentExchangeWalletId, documents, employeeAccountStatus } =
    certificateData;

  const editMode = useCertificatesEditMode();

  const { t } = useTranslation();

  if (editMode) return <CertificatesCreateOrEdit certificateItem={certificateData} />;

  return (
    <CertificatesPageRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={documentName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={employeeFullName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={endDate
            ?
            new Date(endDate).toLocaleDateString(undefined, {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
              .split("/")
              .join(".")
            :
            t("documentManagementPage.documentManagement.placeholders.noDateSet")}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={notificationDate
            ?
            new Date(notificationDate).toLocaleDateString(undefined, {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
              .split("/")
              .join(".")
            :
            t("documentManagementPage.documentManagement.placeholders.noDateSet")}
        />
      </CellBox>
      <CellBox>
        <Typography>
          <DocumentPopUp documentExchangeWalletId={documentExchangeWalletId} documentData={documents} />
        </Typography>
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={employeeAccountStatus}
        />
      </CellBox>
      <BorderRow />
    </CertificatesPageRowStyles>
  );
};

export default CertificatesTableRow;
