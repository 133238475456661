import { SidebarLinkPathTypes, SidebarLinkTypes } from "../../LinkTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ChevronDownIcon } from "../../../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { SimpleSidebarLink } from "../SimpleSidebarLink";
import { ComplexSideBarLinkUI } from "./ComplexSideBarLinkUI";
import { DocumentIcon } from "../../../../assets/icons/Icons";


export const DocumentManagementComplexLink = ({
  activePathname,
  filteredLinks
}: {
  activePathname: string
  filteredLinks?: string[]
}) => {
  const { t } = useTranslation();
  const path = SidebarLinkPathTypes.documentManagementCertificatesTrainings;
  const isActive = activePathname === path;

  return (
    <ComplexSideBarLinkUI>
      {(!filteredLinks || filteredLinks.length > 0) &&
        <Accordion>
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", padding: "6px 0" }}>
              <DocumentIcon color="white" />
              <Typography>{t(SidebarLinkTypes.documentManagement)}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: "24px!important" }}>
            {
              filteredLinks?.includes("CertificatesAndTraining") &&
              <SimpleSidebarLink
                isActive={isActive}
                linkName={SidebarLinkTypes.documentManagementCertificatesTrainings}
                path={path}
              />
            }
          </AccordionDetails>
        </Accordion>
      }
    </ComplexSideBarLinkUI>
  );
};