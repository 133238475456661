import TaxFreeBenefitTableHeader from "./TaxFreeBenefitTableHeader";
import TaxFreeBenefitTableBody from "./TaxFreeBenefitTableBody";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../../../components/common/Common.styles";

const TaxFreeBenefitTable = (props: { searchQuery: string }) => {
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1230px"
        columnsTemplate="1.8fr 1.6fr 1.6fr 2.7fr 180px"
      >
        <TaxFreeBenefitTableHeader />
        <TaxFreeBenefitTableBody searchQuery={props.searchQuery} />
      </TableWrapper>
    </MainContentWrapper>
  );
};

export default TaxFreeBenefitTable;
