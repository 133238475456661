import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button, Typography } from "@mui/material";
import { TableSortDirection } from "../../../common/logic/TableSortUtils";
import { useEffect, useState } from "react";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { TableColumnHeaderWrapper } from "./ServerSideTableColumnHeader.styles";

export type SortDelegate = (direction: TableSortDirection | null, fieldName: string | null) => void;

interface Props {
    label: string;
    isSorted?: boolean;
    fieldName?: string;
    sortDirection?: TableSortDirection;
    sortFieldName?: string;
    onTableSort?: SortDelegate;
    prevIcon?: React.ReactNode;
    firstCol?: boolean;
    lastCol?: boolean;
}

export default function ServerSideTableColumnHeader(props: Props) {
    const [sortDirection, setSortDirection] = useState<TableSortDirection | null>();

    useEffect(() => {
        if (props.fieldName != props.sortFieldName) {
            setSortDirection(null);
        }
        else {
            setSortDirection(props.sortDirection);
        }

    }, [props.sortDirection, props.sortFieldName]);

    function toggleSort() {
        if (!props.onTableSort || !props.fieldName) return;

        if (sortDirection == null) {
            props.onTableSort("asc", props.fieldName);
        } else if (sortDirection == "asc") {
            props.onTableSort("desc", props.fieldName);
        } else {
            props.onTableSort(null, null);
        }
    };

    return <TableColumnHeaderWrapper style={{
        ...(props.firstCol && {paddingLeft: "25px"}),
        ...(props.lastCol && {paddingRight: "25px"}),
    }}>
        <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>{props.label}</Typography>

        {props.isSorted && sortDirection == null && <Button onClick={toggleSort}>
            <SwapVertIcon sx={{ fontSize: "1.5rem" }} />
        </Button>
        }
        {props.isSorted && sortDirection == "asc" && <Button onClick={toggleSort}>
            <ArrowDownwardIcon sx={{ fontSize: "1.5rem" }} />
        </Button>
        }
        {props.isSorted && sortDirection == "desc" && <Button onClick={toggleSort}>
            <ArrowUpwardIcon sx={{ fontSize: "1.5rem" }} />
        </Button>}
    </TableColumnHeaderWrapper>;
};
