import { FilterOptionsState, InputAdornment, SxProps, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchBox } from "./SearchWithAutocomplete.styles";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { SearchIcon } from "../../../assets/icons/ActionIcons";


interface SearchWithAutocompleteProps {
  options: any[];
  sx?: SxProps;
  liftUpSelectedSearchResult?: (index: any) => void;
  liftUpFilteredList?: (searchListItems: string[]) => void;
  searchIconColor?: string;
  onSearchHandler: (searchQuery: string) => void;
}

type Option = {
  key: string | number;
  label: string;
};

const _filterOptions = createFilterOptions();

const SearchWithAutocomplete: FC<SearchWithAutocompleteProps> = ({
  options,
  onSearchHandler,
  liftUpSelectedSearchResult,
  liftUpFilteredList
}) => {
  const { t } = useTranslation();

  const [optionCount, setOptionCount] = useState(0);

  const eventHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    onSearchHandler(target.value);
  };

  const onChangeHandler = (newValue: string) => onSearchHandler(newValue);

  const renderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option
  ) => (
    <li {...props} key={option.key} onClick={() => liftUpSelectedSearchResult && liftUpSelectedSearchResult(option.label)}>
      {option.label}
    </li>
  );

  const filterOptions: ((options: unknown[], state: FilterOptionsState<unknown>) => unknown[]) = React.useCallback((options, state) => {
    const results: unknown[] = _filterOptions(options, state);

    const filteredListItems: string[] = results.map((item: any) => {
      return item.label
    })

    if (optionCount !== results.length) {
      setOptionCount(results.length);
      liftUpFilteredList && liftUpFilteredList(filteredListItems)
    }

    return results;
  }, [optionCount]);

  return (
    <SearchBox>
      <Autocomplete
        freeSolo
        autoFocus={false}
        options={options}
        onChange={(event: any, newValue: Option | null | string) => {
          event.stopPropagation()
          newValue
            ? onChangeHandler((newValue as Option).label)
            : onChangeHandler("");
        }}
        onInputChange={eventHandler}
        renderOption={(!liftUpFilteredList) ? renderOptions : undefined}
        onKeyDown={e => e.stopPropagation()}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params} placeholder={t("search") + "..."}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </SearchBox>
  );
};

export default SearchWithAutocomplete;
