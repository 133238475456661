import { CertificatesContentWrapper } from "./CertificatesPage.styles";
import { useSearch } from "../../../hooks/UseSearch";
import CertificateTable from "./CertificatesTable";
import CertificatesSearchBar from "./CertificatesSearchBar";

const CertificatesBlock = () => {
  const { searchQuery, handleOnSearch } = useSearch();

  return (
    <CertificatesContentWrapper>
      <CertificatesSearchBar handleOnSearch={handleOnSearch} />
      <CertificateTable searchQuery={searchQuery} />
    </CertificatesContentWrapper>
  );
};

export default CertificatesBlock;
