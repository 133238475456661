import { TablePagination } from "@mui/material";
import { useState } from "react";
import { PaginationParameters } from "../../../api/Pagination";

interface Props {
    totalCount: number;
    pagination: PaginationParameters;
    onUpdatePagination: (newValue: PaginationParameters) => void;
}

/**
 * A component which displays pagination buttons based on PaginationParameters which is used by a few backend apis
 * when pagination is done server side
 */
export function ServerSidePagination(props: Props) {
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);

    function updatePage(page: number, itemsPerPage: number) {
        setCurrentPage(page);
        props.onUpdatePagination({
            ...props.pagination,
            limit: itemsPerPage,
            offset: itemsPerPage * page,
        })
    }

    return <TablePagination
        component="div"
        count={props.totalCount}
        page={currentPage}
        onPageChange={(e, v) => {
            updatePage(v, itemsPerPage);
        }}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={(c) => {
            const itemsPerPage = parseInt(c.target.value, 10)
            setItemsPerPage(itemsPerPage);
            updatePage(0, itemsPerPage);
        }}
    />;
}