import { useParams } from "react-router-dom";
import TaxFreeBenefit from "./taxFreeBenefit/TaxFreeBenefit";

const Incentivation = () => {
  const { groupsType } = useParams();
  switch (groupsType) {
    case "taxfreebenefit":
      return (
        <>
          <TaxFreeBenefit />
        </>
      );
    default:
      return <></>;
  }
};

export default Incentivation;
