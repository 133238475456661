import { FC, ChangeEvent, FocusEvent, useState } from "react";
import CellBox from "../../../components/common/table/CellBox";
import { TextField } from "@mui/material";
import { InputFields } from "../utils";
import { TableSwitch } from "../AccountManagement.styles";
import { StatusTypes } from "../../../types/common";

interface InputCellBoxProps {
  error: string;
  isDisabled: boolean;
  isWarning?: boolean;
  key: string;
  value: string;
  inputField: InputFields;
  placeholder: string;
  handleOnBlur: (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: Partial<InputFields>
  ) => void;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputName: InputFields
  ) => void;
}

const InputCellBox: FC<InputCellBoxProps> = ({
  error,
  isDisabled,
  isWarning = false,
  value,
  inputField,
  placeholder,
  handleOnBlur,
  handleOnChange,
}) => {
  const [status, setStatus] = useState(value);

  return (
    inputField as string !== "status" ?
      <CellBox sx={{ width: "fit-content", minWidth: "0" }}>
        <TextField
          sx={(theme) => ({
            width: "100%",
            input: {
              fontSize: "1.25rem",
              lineHeight: "1.25",
              padding: "8px 9px 7px",
              boxSizing: "border-box",
              height: "auto",
              border: error
                ? `1px solid ${theme.palette.warningColor.main}`
                : isWarning 
                  ? '1px solid red'
                  : `1px solid ${theme.palette.border.main}`,
            },
          })}
          disabled={isDisabled}
          value={value}
          placeholder={placeholder}
          onBlur={(e) => handleOnBlur(e, inputField)}
          onChange={(e) => handleOnChange(e, inputField)}
        />
      </CellBox> : <CellBox>
        <TableSwitch
          checked={value === StatusTypes.Active}
          onChange={(e) => {
            if (status === StatusTypes.Active) {
              setStatus(StatusTypes.Blocked)
             } else {
              setStatus(StatusTypes.Active)
             }
             handleOnChange(e, inputField)
            }
          }
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </CellBox>
  )
};

export default InputCellBox;
