import { Tooltip } from "@mui/material";
import { FC } from "react";
import GroupName from "./GroupName";
import CellBox from "../../../../../components/common/table/CellBox";
import ActionButtonsList from "../../../../../components/common/table/ActionButtonsList";
import { useEditMode } from "../../../../../store/selectors/groupSelectors";
import AddOrEditGroupForm from "./GroupFormComponents/AddOrEditGroupForm";
import { calculateMaxPoints } from "../../../../../common/logic/ActionItemUtils";
import { GroupItem } from "../../../../../types/groupItem";
import BorderRow from "../../../../../components/common/table/BorderRow";
import { GroupsActionsStyles, GroupsRowStyles } from "../../GroupsBlock.styles";
import StatusDisplay from "../../../../../components/common/table/StatusDisplay";
import TooltipTypography from "../../../../../components/common/TooltipTypography";

const GroupsTableRow: FC<GroupItem> = (groupItem: GroupItem) => {
  const editMode = useEditMode();

  if (editMode) {
    return <AddOrEditGroupForm groupItem={groupItem} />;
  }

  return (
    <GroupsRowStyles>
      <GroupName label={groupItem.name} />
      <GroupsActionsStyles>
        <ActionButtonsList groupActions={groupItem.actionModules} />
      </GroupsActionsStyles>
      <CellBox>
        <TooltipTypography
          value={calculateMaxPoints(groupItem.actionModules)}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={groupItem.staff}
        />
      </CellBox>
      <CellBox lastCol={true}>
        <Tooltip title={groupItem.status}>
          <StatusDisplay status={groupItem.status} />
        </Tooltip>
      </CellBox>
      <BorderRow />
    </GroupsRowStyles>
  );
};

export default GroupsTableRow;
