import { BillItem } from "../types/bills";
import { DeleteAttachmentFile } from "../types/deleteAttachemntFileType";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";

export const superAdminBillsApi = {
  getSuperAdminBills: () =>
    doApiGet("/api/administratorusers/companybillings")
      .then(res => res.result)
      .catch(error => error),

  editBills: (id: number, billData: Partial<BillItem>) =>
    doApiPatch(`/api/administratorusers/companybillings/${id}`, billData)
      .then(res => res)
      .catch(error => console.log(error)),

  createBill: (billData: Partial<BillItem>) =>
    doApiPost("/api/administratorusers/companybillings", billData)
      .then(res => res)
      .catch(error => console.log(error)),

  deleteBills: (billingId: number) =>
    doApiDelete(`/api/administratorusers/companybillings/${billingId}`)
      .then(res => res)
      .catch(error => console.log(error)),

  uploadBillFile: (billingId: number, fileType: string, fileContent: string | undefined) =>
    doApiPost(`/api/administratorusers/companybillings/${billingId}/attachments/${fileType}`, fileContent)
      .then(res => res.result)
      .catch(error => console.log(error)),

  deleteAttachmentFiles: (deleteAttachmentFileRequest: DeleteAttachmentFile) =>
    doApiDelete(`/api/administratorusers/companybillings/${deleteAttachmentFileRequest.billingId}/attachments/${deleteAttachmentFileRequest.fileType}`)
      .then(res => res)
      .catch(error => console.log(error)),
};
