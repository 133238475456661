import { EmployeeScores } from "../types/employeeScores";
import { EditUserDataType } from "../types/incentivationTypes";
import { doApiGet, doApiPatch, doApiPost } from "./Requests";

export const incentivationUsersApi = {
  getUsers: () =>
    doApiGet("/api/companies/current/employees")
      .then(res => res.result)
      .catch(error => console.log(error)),

  editUser: (id: string, dataToEdit: EditUserDataType) =>
    doApiPatch(`/api/employee/${id}/incentivation`, dataToEdit),

  importEmployeeScoresAction: (actionModuleId: number, employeeScores: EmployeeScores[]) =>
    doApiPost(`/api/companies/current/actionmodules/${actionModuleId}/import`, employeeScores)
      .then(res => {
        return res;
      })
      .catch(error => console.log(error)),

  importEmployeeScoresForSubtype: (subtype: string, employeeScores: EmployeeScores[]) =>
    doApiPost(`/api/companies/current/actionmodules/subtypes/${subtype}/import`, employeeScores)
      .then(res => {
        return res;
      })
      .catch(error => console.log(error)),
};
