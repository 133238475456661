import TableColumnHeader from "../../../components/common/table/TableColumnHeader";
import {
  SortingDataType,
  TableSortDirection,
} from "../../../common/logic/TableSortUtils";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { sortAccountManagementUsersTable } from "../../../store/reducers/accountManagementReducer";
import BorderRow from "../../../components/common/table/BorderRow";
import { TableHeaderWrapper } from "../../../components/common/Common.styles";

const AccountManagementTableHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    dispatch(
      sortAccountManagementUsersTable({ dataField, direction, dataType })
    );
  };
  return (
    <TableHeaderWrapper>
      <TableColumnHeader
        dataField="firstName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.firstName")}
        firstCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="lastName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.lastName")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="position"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.position")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="employeeNumber"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.employeeNo")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="groupName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.group")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="status"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.status")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="registrationCode"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.invitationCode")}
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default AccountManagementTableHeader;
