import { useAppSelector } from "../../hooks/StoreHooks";

export const useActionManagementEditMode = () =>
  useAppSelector((state) => state.actionManagement.editMode);

export const useCurrentActionModuleId = () =>
  useAppSelector((state) => state.actionManagement.currentActionId);

export const useActionManagementUsers = () =>
  useAppSelector((state) => state.actionManagement.users);

export const useActionManagementFilteredUsers = () =>
  useAppSelector((state) => state.actionManagement.filteredUsers);

export const usePagination = () =>
  useAppSelector((state) => state.actionManagement.pagination);

export const useExcelParsedData = () =>
  useAppSelector((state) => state.actionManagement.excelEmployeeScoresParsedData);
