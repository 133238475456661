import React, { FC } from "react";
import { SidebarLinkPathTypes, SidebarLinkTypes } from "../LinkTypes";
import { useLocale } from "../../../hooks/UseLocale";
import { useTranslation } from "react-i18next";
import { LinkWrapper } from "../Layout.styles";
import {useNavigate} from 'react-router-dom';

interface SimpleSidebarLinkProps {
  linkName: SidebarLinkTypes;
  isActive: boolean;
}

interface SidebarLinkComponentProps extends SimpleSidebarLinkProps {
  icon?: React.ReactNode;
  path: SidebarLinkPathTypes;
}

export const SimpleSidebarLink: FC<SidebarLinkComponentProps> = ({
  isActive,
  linkName,
  icon,
  path,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();

  const handleOnClick = () => navigate(`/${locale}${path ? "/" + path : ""}`);

  return (
    <LinkWrapper isActive={isActive} onClick={handleOnClick}>
        {icon ? icon : null}
        <div>{t(linkName)}</div>
    </LinkWrapper>
  );
};

export default SimpleSidebarLink;
