import {
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { DialogPropsType } from "../../types/common";

interface DocumentPreviewModalProps {
  dialog: DialogPropsType;
  data: any[];
}

const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
  dialog,
  data,
}) => {
  const { open, handleDialogClose } = dialog;
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      sx={{
        ".MuiPaper-root": {
          maxWidth: "1000px",
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {data &&
                Object.keys(data[0]).map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.slice(1).map((row: any, idx: number) => (
                <TableRow key={idx}>
                  {Object.keys(row).map((item) => (
                    <TableCell component="th" key={row[item]} scope="row">
                      {row[item]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default DocumentPreviewModal;
