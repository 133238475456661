import PageTitle from "../../../components/common/PageTitle/PageTitle";
import { useSearch } from "../../../hooks/UseSearch";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import TaxFreeBenefitTable from "./TaxFreeBenefitTable/TaxFreeBenefitTable";
import { useEffect } from "react";
import { fetchGroupsThunk } from "../../../store/thunk/groupsThunk";
import { useTranslation } from "react-i18next";
import TaxFreeBenefitSearchBar from "./TaxFreeBenefitSearchBar";
import { fetchTaxFreeBenefitUsersThunk } from "../../../store/thunk/taxFreeBenefitThunk";
import { TaxFreeBenefitContentWrapper } from "./TaxFreeBenefit.styles";
import { useProcessingRequest } from "../../../store/selectors/tableManagementSelector";
import { setProcessingRequest } from "../../../store/reducers/tableManagementReducer";
import { CircularIndeterminate } from "../../../components/common/CircularIndeterminate";

const breadcrumbs = [
  {
    title: "incentivationPage.taxFreeBenefit",
    link: "incentivation/taxfreebenefit",
  },
  {
    title: "",
    link: "",
  },
];

const TaxFreeBenefit = () => {
  const { searchQuery, handleOnSearch } = useSearch();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const processingRequest = useProcessingRequest()

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchTaxFreeBenefitUsersThunk());
    dispatch(fetchGroupsThunk());
  }, []);
  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("incentivationPage.title")}
      />
      <TaxFreeBenefitContentWrapper>
        <TaxFreeBenefitSearchBar handleOnSearch={handleOnSearch} />
        <TaxFreeBenefitTable searchQuery={searchQuery} />
      </TaxFreeBenefitContentWrapper>
      <CircularIndeterminate processingRequest={processingRequest} />
    </>
  );
};

export default TaxFreeBenefit;
