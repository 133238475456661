import { Box } from "@mui/material";
import React, { FC } from "react";
import {
  useAccountManagementNewUserMode,
  useAccountManagementUsers,
  usePagination,
} from "../../../store/selectors/accountManagementSelectors";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import AccountManagementRow from "./AccountManagementRow";
import AccountManagementCreateEditUser from "./AccountManagementCreateEditUser";
import { useTableDataPaginated } from "../../../hooks/UseTablePagination";
import Pagination from "../../../components/common/table/Pagination";
import { setPagination } from "../../../store/reducers/accountManagementReducer";

interface AccountManagementTableBodyProps {
  searchQuery: string;
}

const AccountManagementTableBody: FC<AccountManagementTableBodyProps> = ({
  searchQuery,
}) => {
  const tableData = useAccountManagementUsers();
  const newUserMode = useAccountManagementNewUserMode();
  const { itemsPerPage, currentPage, totalPages } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    ["firstName", "lastName", "employeeNumber", "position"]
  );

  const userItemsToRender = tableDataToRender
    ? tableDataToRender.map((user) => (
      <AccountManagementRow key={user.id} userData={user} />
    ))
    : [];

  return (
    <>
      {newUserMode && <AccountManagementCreateEditUser />}
      {userItemsToRender}
      <Box sx={{ gridColumn: "1/8" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default AccountManagementTableBody;
