import { FormEvent, useState } from "react";
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack, TextField,
    Typography
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { doApiPost } from "../../api/Requests";
import { useNavigate } from "react-router-dom";
import { authenticationStateHandler } from "../logic/AuthenticationStateHandler";
import { Address } from "../model/Address";
import AddressField from "./AddressField";
import { SimpleLink } from "../../components/SimpleLink";
import { LoginSuccessfulResponse } from "../model/LoginSuccessfulResponse";
import PasswordStrength, { passwordStrengthTest } from "./PasswordStrength"
import BonisoftLogo from "../../common/ui/BonisoftLogo";
import { AuthButton } from "./Authentication.styles";
import AuthFooter from "./AuthFooter";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../common/ui/LanguageSwitcher";
import PasswordRules from "./PasswordRules";
import { keyValueStorage } from "../../common/logic/KeyValueStore";

interface RegisterState {
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordRepeated: string,
    companyName: string,
    companyTaxId: string,
    companyPhoneNumber: string,
    companyAddress: Address,
}

/**
 * This screen provides a form for company admin users to register
 */
export function RegistrationScreen() {
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0)

    const { t } = useTranslation();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatedPassword = () => setShowRepeatedPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseDownRepeatedPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const navigate = useNavigate();
    const [registerState, setRegisterState] = useState<RegisterState>({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        passwordRepeated: "",
        companyName: "",
        companyTaxId: "",
        companyPhoneNumber: "",
        companyAddress: {
            street: "",
            number: "",
            postalCode: "",
            town: "",
            country: "",
        },
    });

    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSignIn = async function (event: FormEvent) {
        // We use a form with preventDefault as button click listener. This is needed to
        // leverage the browsers built in save password feature
        event.preventDefault()

        setIsLoading(true);

        let frontendValidation = passwordStrength < 3 ? false : true

        if (frontendValidation) {
            const result = await doApiPost<any, LoginSuccessfulResponse>(
                "/api/companymanagementuser",
                {
                    email: registerState.email,
                    password: registerState.password,
                    firstName: registerState.firstName,
                    lastName: registerState.lastName,
                    company: {
                        name: registerState.companyName,
                        address: registerState.companyAddress,
                        phoneNumber: registerState.companyPhoneNumber,
                        taxId: registerState.companyTaxId
                    }
                },
                { handleUnauthenticated: false },
            );

            if (result.success()) {
                let success = await authenticationStateHandler.refreshProfileAndSetAuthenticated(
                    result.result?.accessToken ?? null
                );
                if (success) {
                    keyValueStorage.setObject("userRole", result.result?.role);
                    navigate("/");
                    return;
                }
            }
        }
        setIsLoading(false);
        setIsError(true);
    }

    return <Container component="main" maxWidth="xs" sx={{ mt: 10, mb: 10 }}>
        <CssBaseline />
        <LanguageSwitcher />
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <BonisoftLogo imgRatio={40} />
            <Typography variant="h1" sx={{ alignSelf: "center", mb: 5 }}>
                {t("authentication.register.register")}
            </Typography>
            <Typography sx={{ fontSize: "small" }}>
                {t("authentication.register.required")}
            </Typography>
            <Box component="form" onSubmit={handleSignIn} noValidate mb={1}>

                <Stack>
                    <Typography sx={{ padding: "1em 0", margin: "1em auto", fontWeight: "600" }}>
                        {t("authentication.register.personalData")}
                    </Typography>
                    <Stack gap={2}>
                        <TextField
                            required
                            label={t("authentication.register.email")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.email}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, email: v.target.value })}
                        />
                        <TextField
                            required
                            label={t("authentication.register.firstName")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.firstName}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, firstName: v.target.value })}
                        />
                        <TextField
                            required
                            label={t("authentication.register.lastName")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.lastName}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, lastName: v.target.value })}
                        />
                        <OutlinedInput
                            required
                            fullWidth
                            placeholder={t("authentication.register.password")}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={registerState.password}
                            onChange={(v) => {
                                setPasswordStrength(passwordStrengthTest(v.target.value))
                                setRegisterState({ ...registerState, password: v.target.value })
                            }}
                        />
                        <PasswordStrength strength={passwordStrength} />
                        <PasswordRules pw={registerState.password} />
                        <OutlinedInput
                            required
                            fullWidth
                            placeholder={t("authentication.register.repeatPassword")}
                            type={showRepeatedPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRepeatedPassword}
                                        onMouseDown={handleMouseDownRepeatedPassword}
                                        edge="end"
                                    >
                                        {showRepeatedPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={registerState.passwordRepeated}
                            error={registerState.password !== registerState.passwordRepeated}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, passwordRepeated: v.target.value })}
                        />
                    </Stack>

                    <Typography sx={{ padding: "1em 0", margin: "1em auto", fontWeight: "600" }}>
                        {t("authentication.register.companyData")}
                    </Typography>
                    <Stack gap={2}>
                        <TextField
                            required
                            label={t("authentication.register.companyName")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.companyName}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, companyName: v.target.value })}
                        />

                        <AddressField address={registerState.companyAddress}
                            setAddress={
                                (v) => setRegisterState({ ...registerState, companyAddress: v })
                            } />

                        <TextField
                            label={t("authentication.register.phoneNumber")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.companyPhoneNumber}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, companyPhoneNumber: v.target.value })}
                        />
                        <TextField
                            label={t("authentication.register.taxId")}
                            fullWidth
                            disabled={false}
                            variant="outlined"
                            value={registerState.companyTaxId}
                            onChange={(v) =>
                                setRegisterState({ ...registerState, companyTaxId: v.target.value })}
                        />
                    </Stack>
                </Stack>

                <Stack gap={2} mt={3}>
                    {
                        isError &&
                        <Alert severity="error">
                            {t("authentication.register.registerFailed")}
                        </Alert>
                    }
                    <AuthButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                        sx={{ mt: 3 }}
                    >
                        {
                            isLoading && <><CircularProgress /></>
                        }
                        {
                            !isLoading && <>{t("authentication.register.register")}</>
                        }

                    </AuthButton>
                </Stack>
            </Box>
            <SimpleLink to="/login">
                <Typography variant="subtitle2">{t("authentication.register.alreadyRegistered")}</Typography>
            </SimpleLink>
        </Stack>
        <AuthFooter />
    </Container>
}