import { Box, Button, Popover } from "@mui/material";
import { Dispatch, FC, MutableRefObject, SetStateAction } from "react";
import { useSearch } from "../../../../../../hooks/UseSearch";
import SimpleSearch from "../../../../../../components/common/SimpleSearch";
import ActionsListItem from "./ActionsListItem";
import { GroupItem } from "../../../../../../types/groupItem";
import { useActionsData } from "../../../../../../store/selectors/actionsSelectors";
import {
  ActionsPopoverStyles,
  ApplyBtnWrapper,
} from "../../../GroupsBlock.styles";
import { useTranslation } from "react-i18next";

interface ActionsPopoverProps {
  anchorRef: MutableRefObject<null>;
  actions?: any[];
  open: boolean;
  selectedActions: any[];
  handleClose: () => void;
  handleOnEdit: () => void;
  setSelectedActions: Dispatch<SetStateAction<GroupItem>>;
}

const ActionsPopover: FC<ActionsPopoverProps> = ({
  handleClose,
  handleOnEdit,
  anchorRef,
  open,
  selectedActions,
  setSelectedActions,
}) => {
  const { searchQuery, handleOnSearch } = useSearch();

  const { t } = useTranslation();
  const actionsStateData = useActionsData();

  const actionsData = searchQuery
    ? actionsStateData.filter((item) => item.name.toLowerCase().includes(searchQuery))
    : actionsStateData;

  const actionItems = actionsData.map((item) => {
    const selectedAction = selectedActions.find(
      (action) => action.id === item.id
    );

    const isSelected = (selectedAction) ? true : false;
    return (
      <ActionsListItem
        key={item.id}
        item={item}
        isActionSelected={isSelected}
        setSelectedActions={setSelectedActions}
        selectedActions={selectedActions}
      />
    );
  });

  return (
    <Popover
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      anchorEl={anchorRef.current}
      open={open}
    >
      <ActionsPopoverStyles>
        <SimpleSearch
          onSearchHandler={handleOnSearch}
          searchQuery={searchQuery}
        />
        <Box>{actionItems}</Box>
      </ActionsPopoverStyles>
      <ApplyBtnWrapper>
        <Button
          onClick={() => {
            handleClose();
            handleOnEdit();
          }}
        >
          {t("buttons.apply")}
        </Button>
      </ApplyBtnWrapper>
    </Popover>
  );
};

export default ActionsPopover;
