import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack, TextField,
    Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { doApiPost } from "../../api/Requests";
import { AuthButton } from "./Authentication.styles";
import BonisoftLogo from "../../common/ui/BonisoftLogo";
import AuthFooter from "./AuthFooter";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordStrength, { passwordStrengthTest } from "./PasswordStrength";
import LanguageSwitcher from "../../common/ui/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import PasswordRules from "./PasswordRules";

interface ResetState {
    password: string,
    passwordRepeated: string,
    email: string,
}

/**
 * This screen handles the password reset link. It uses the url-provided token and prompts the
 * user for the email and new password. After the password reset the user gets redirected to
 * the login page where he can log in normally.
 */
export default function LinkingResetPasswordScreen() {
    const navigate = useNavigate();
    const [resetState, setResetState] = useState<ResetState>({
        password: "",
        passwordRepeated: "",
        email: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0)
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();
    const token = searchParams.get("token");

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatedPassword = () => setShowRepeatedPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseDownRepeatedPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onHandleResetPassword = async function (event: FormEvent) {
        // We use a form with preventDefault as button click listener. This is needed to
        // leverage the browsers built in save password feature
        event.preventDefault()

        setIsLoading(true);

        const result = await doApiPost<any, never>(
            `/api/users/password_reset/${token}`,
            {
                eMail: resetState.email,
                newPassword: resetState.password
            },
            { handleUnauthenticated: true }
        );

        if (result.success()) {
            navigate("/")
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 10, mb: 10 }}>
            <CssBaseline />
            <LanguageSwitcher />
            <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
                <BonisoftLogo imgRatio={40} />
                <Typography variant="h1" sx={{ alignSelf: "center", textAlign: "center", mb: 5 }}>
                    {t("authentication.linkingResetPassword.resetPassword")}
                </Typography>
                <Box component="form" onSubmit={onHandleResetPassword} noValidate>
                    <Stack gap={2}>
                        <TextField
                            fullWidth
                            disabled={false}
                            placeholder={t("authentication.linkingResetPassword.email")}
                            variant="outlined"
                            value={resetState.email}
                            onChange={(v) =>
                                setResetState({ ...resetState, email: v.target.value })}
                        />
                        <OutlinedInput
                            required
                            fullWidth
                            placeholder={t("authentication.linkingResetPassword.password")}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={resetState.password}
                            onChange={(v) => {
                                setPasswordStrength(passwordStrengthTest(v.target.value))
                                setResetState({ ...resetState, password: v.target.value })
                            }}
                        />
                        <PasswordStrength strength={passwordStrength} />
                        <PasswordRules pw={resetState.password} />
                        <OutlinedInput
                            required
                            fullWidth
                            placeholder={t("authentication.linkingResetPassword.repeatPassword")}
                            type={showRepeatedPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRepeatedPassword}
                                        onMouseDown={handleMouseDownRepeatedPassword}
                                        edge="end"
                                    >
                                        {showRepeatedPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={resetState.passwordRepeated}
                            error={resetState.password !== resetState.passwordRepeated}
                            onChange={(v) =>
                                setResetState({ ...resetState, passwordRepeated: v.target.value })}
                        />
                        {
                            isError &&
                            <Alert severity="error">
                                {t("authentication.linkingResetPassword.resetRailed")}
                            </Alert>
                        }
                        <AuthButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            disabled={isLoading}
                            sx={{ mt: 3 }}
                        >
                            {
                                isLoading && <><CircularProgress /></>
                            }
                            {
                                !isLoading && <>{t("authentication.linkingResetPassword.changePassword")}</>
                            }
                        </AuthButton>
                    </Stack>
                </Box>
            </Stack>
            <AuthFooter />
        </Container>
    )
}
