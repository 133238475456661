import { Box } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { AddActionIcon } from "../../../../../../assets/icons/ActionIcons";
import { usePopover } from "../../../../../../hooks/UsePopover";
import ActionsPopover from "./ActionsPopover";
import { GroupItem } from "../../../../../../types/groupItem";
import { AddActionBtnStyled } from "../../../GroupsBlock.styles";

interface AddActionButtonProps {
  handleOnEdit: () => void;
  setSelectedActions: Dispatch<SetStateAction<GroupItem>>;
  selectedActions: any[];
}

const AddActionButton: FC<AddActionButtonProps> = ({
  handleOnEdit,
  selectedActions,
  setSelectedActions,
}) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover();
  return (
    <Box sx={{ height: "100%" }} ref={anchorRef}>
      <AddActionBtnStyled onClick={handleOpen}>
        <AddActionIcon />
      </AddActionBtnStyled>
      <ActionsPopover
        handleOnEdit={handleOnEdit}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        setSelectedActions={setSelectedActions}
        selectedActions={selectedActions}
      />
    </Box>
  );
};

export default AddActionButton;
