import { Box } from "@mui/material";
import { FC } from "react";
import { useActionsData } from "../../../store/selectors/actionsSelectors";
import { useTranslation } from "react-i18next";
import SearchWithAutocomplete from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";

interface ActionsSearchBarProps {
  searchQuery: string;
  handleOnSearch: (searchQuery: string) => void;
}

const ActionsSearchBar: FC<ActionsSearchBarProps> = ({
  searchQuery,
  handleOnSearch,
}) => {
  const { t } = useTranslation();
  const actionData = useActionsData();

  const actionNames = actionData
    ? actionData.map((action) => ({
        key: action.id,
        label: action.name,
      }))
    : [];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "22px 25px 18px 39px",
      }}
    >
      <SearchWithAutocomplete
        options={actionNames}
        onSearchHandler={handleOnSearch}
      />
    </Box>
  );
};

export default ActionsSearchBar;
