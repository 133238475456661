import { Box, Button, Theme, styled } from "@mui/material";
import { theme } from "../../../theme";
import { ActionItem } from "../../../types/actionItem";
import {
  BasicSelectUI,
  BasicTimePicker,
  Container,
} from "../../../components/common/Common.styles";

const inputBorderStyles = ({
  error = "",
  theme,
}: {
  error?: string;
  theme: Theme;
}) => ({
  border: error
    ? `1px solid ${theme.palette.warningColor.main}`
    : `1px solid ${theme.palette.textMain.secondary}`,
  "&:focus": { outline: "none" },
});

export const ActionModuleCardStyles = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "585px",
  marginTop: "8px",
  padding: "19px 14px 14px 44px",
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.backgroundColor.main,
  "& > .MuiBox-root": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > .MuiBox-root": {
      width: "calc(100% - 24px)",
    },
    "& > .MuiButtonBase-root": {
      padding: "0",
      minWidth: "0",
      width: "fit-content",
      transform: "translateX(50%)",
    },
  },
}));

export const ActionModuleIconStyles = styled(Box)(
  ({ isSelected }: { isSelected: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "33px",
    height: "33px",
    borderRadius: "3px",
    boxSizing: "border-box",
    border: isSelected ? `2px solid ${theme.palette.warningColor.main}` : "",
    svg: {
      width: "21px",
      height: "21px",
    },
  })
);

export const ColorBtnStyles = styled(Box)(({ item }: { item: string }) => ({
  width: "20px",
  height: "20px",
  minWidth: "0",
  borderRadius: "5px",
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.textMain.main}`,
  backgroundColor: item,
}));

export const SelectLogoWrapper = styled(Box)(({ theme }) => ({
  "& > .MuiTypography-root": {
    margin: "6px 0 4px",
    textAlign: "center",
    fontSize: "0.75rem",
    color: theme.palette.border.secondary,
  },
  "& > .MuiBox-root": {
    display: "flex",
    width: "100%",
  },
}));

export const LogoPreviewStyles = styled(Box)(({ theme }) => ({
  "& > .MuiBox-root": {
    width: "46px",
    height: "45px",
    minWidth: "0",
    padding: "0",
    border: `1px solid ${theme.palette.textMain.main}`,
    borderRadius: "5px",
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  "& > .MuiTypography-root": {
    color: theme.palette.textMain.secondary,
    textAlign: "center",
    lineHeight: "1.25",
  },
}));

export const LogoPreviewWithIconStyles = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const EmptyLogoPreviewStyles = styled(Box)(({ theme }) => ({
  position: "relative",
  span: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2px",
    backgroundColor: theme.palette.textMain.secondary,
    "&:first-child": {
      width: "13px",
      height: "2px",
    },
    "&:nth-child(2)": {
      width: "2px",
      height: "13px",
    },
  },
}));

export const IconOptionsSelectWraper = styled(Box)(() => ({
  width: "calc(100% - 67px)",
  maxWidth: "fit-content",
}));

export const IconsBlockStyles = styled(Box)(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  margin: "0 0 4px 21px",
  padding: "6px 10.5px",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.textMain.main}`,
}));

export const ColorsBlockStyles = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "21px",
}));

export const ActionFormBoxStyles = styled(Box)({
  marginTop: "19px",
});

export const ActionFormWrapperStyles = styled(Box)(({ theme }) => ({
  padding: "21px 12px 18px",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.border.subform}`,
  position: "relative",
  "& > label": {
    position: "absolute",
    padding: "3px",
    top: "0",
    left: "9px",
    transform: "translateY(-50%)",
    backgroundColor: theme.palette.backgroundColor.main,
    fontSize: "0.96rem",
    lineHeight: "1.1",
    color: theme.palette.textMain.main,
  },
}));

export const AllocationFormStyles = styled(Box)({
  "& input": {
    margin: "0px 5px",
    padding: "0",
    boxSizing: "border-box",
    width: "37px",
    height: "24px",
    textAlign: "center",
    borderRadius: "2px",
    "&:focus": { outline: "none" },
  },
});

export const AllocationRadioBtnsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  "&:last-child": {
    marginBottom: "0",
  },
});

export const AllocationRadioBtnWrapper = styled(Box)({
  "& > .MuiBox-root": {
    minWidth: "225px",
    maxWidth: "225px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

export const AllocationInputBox = styled(Box)(
  ({ error, maxWidthVal }: { error: string; maxWidthVal: string }) => ({
    display: "flex",
    alignItems: "center",
    marginRight: "28px",
    "& > input": {
      ...inputBorderStyles({ error, theme }),
    },
    span: {
      maxWidth: maxWidthVal,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  })
);

export const RangeOfRecurrenceLeftBox = styled(Container)({
  minWidth: "250px",
  width: "100%",
  paddingRight: "30px",
  "& > .MuiBox-root": {
    maxWidth: "150px",
    marginLeft: "5px",
    marginBottom: "0",
  },
});

export const RangeOfRecurrenceRightBox = styled(Box)({
  minWidth: "280px",
  width: "100%",
  paddingRight: "10px",
  "& > .MuiBox-root": {
    marginBottom: "10px",
    "& > .MuiBox-root:first-child": {
      minWidth: "105px",
    },
  },
  "& > .MuiBox-root:last-child": {
    minWidth: "105px",
  },
});

export const EndByTimePickerUI = styled(BasicTimePicker)(
  ({ error }: { error: string }) => ({
    maxWidth: "150px",
    marginLeft: "5px",
    marginBottom: "0",
    border: `1px solid ${error ? theme.palette.warningColor.main : theme.palette.textMain.main
      }`,
  })
);

export const EndAfterContainer = styled(Container)(
  ({ error }: { error: string }) => ({
    marginBottom: "10px",
    "& > .MuiBox-root:first-child": {
      minWidth: "105px",
    },
    "& > div > input, & > div > div > input": {
      margin: "0px 5px",
      padding: "0",
      boxSizing: "border-box",
      width: "37px",
      height: "24px",
      textAlign: "center",
      borderRadius: "2px",
      "&:focus": { outline: "none" },
    },
    "& > .MuiBox-root:nth-child(2)": {
      "& > input": {
        border: error
          ? `1px solid ${theme.palette.warningColor.main}`
          : `1px solid ${theme.palette.textMain.secondary}`,
        "&:focus": { outline: "none" },
      },
    },
  })
);

export const RecurrenceDailyStyles = styled(Box)(
  ({ error }: { error: string }) => ({
    paddingLeft: "13px",
    "& > .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      marginBottom: "12px",
      "& > input": {
        marginLeft: "20px",
        padding: "0",
        boxSizing: "border-box",
        width: "37px",
        height: "24px",
        textAlign: "center",
        borderRadius: "2px",
        ...inputBorderStyles({ error, theme }),
      },
    },
  })
);

export const RecurrenceExecutionTimesStyles = styled(Box)(({ theme }) => ({
  width: "37px",
  "& > input": {
    width: "100%",
    boxSizing: "border-box",
    textAlign: "center",
    height: "20px",
    borderRadius: "2px",
    border: `1px solid ${theme.palette.textMain.secondary}`,
    "&:focus": { outline: "none" },
  },
  "& + .MuiTypography-root": {
    marginLeft: "7px",
    fontSize: "1rem",
    lineHeight: "1.125",
  },
}));

export const ExecuteOnceStyles = styled(Box)({
  paddingLeft: "12px",
});

export const RecurrenceMonthlyContainer = styled(Container)(
  ({ margin = "0" }: { margin?: string }) => ({
    margin,
    "& > .MuiBox-root:first-child": {
      minWidth: "85px",
    },
  })
);

export const RecurrenceInputBox = styled(Box)(
  ({ error }: { error?: string }) => ({
    "& > input": {
      ...inputBorderStyles({ error, theme }),
    },
  })
);

export const ContainerWithWrap = styled(Container)({
  flexWrap: "wrap",
});

export const RecurrenceMonthlyStyles = styled(Box)({
  paddingLeft: "12px",
  "& > div > div > input, & > div> div > div > div > input": {
    margin: "0px 5px",
    padding: "0",
    boxSizing: "border-box",
    width: "37px",
    height: "24px",
    textAlign: "center",
    borderRadius: "2px",
    "&:focus": { outline: "none" },
  },
});

export const DayNumSelectUI = styled(BasicSelectUI)({
  maxWidth: "100px",
  minWidth: "100px",
  margin: "0 5px",
});

export const DayNameSelectUI = styled(BasicSelectUI)({
  maxWidth: "120px",
  minWidth: "120px",
  margin: "0 5px",
});

export const RecurrencePatternRadioBtn = styled(Box)({
  "&:last-child": {
    marginBottom: "0",
  },
  "& > .MuiBox-root": {
    marginBottom: "7px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    maxWidth: "90px",
  },
});

export const PatternsBox = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.textMain.main}`,
  paddingRight: "17px",
}));

export const RecurrenceTimeWrapper = styled(Box)({
  marginTop: "35px",
});

export const RecurrenceTimeBox = styled(Box)({
  marginRight: "41px",
  width: "100%",
  maxWidth: "350px",
  ".time-picker": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    "&:first-child": {
      marginBottom: "9px",
    },
    "& > span": {
      width: "40px",
      marginRight: "3px",
    },
  },
});

export const RecurrenceTimePicker = styled(BasicTimePicker)({
  maxWidth: "100px",
  marginRight: "13px",
});

export const RecurrenceTimeZoneSelect = styled(BasicSelectUI)({
  maxWidth: "190px",
});

export const RecurenceCheckbox = styled(Box)({
  "& > .MuiBox-root": {
    minWidth: "80px",
  },
});

export const RecurrrenceWeeklyWrapper = styled(Box)(
  ({ error }: { error: string }) => ({
    paddingLeft: "12px",
    "& > .MuiBox-root:first-child": {
      "&>input": {
        margin: "0px 5px",
        padding: "0",
        boxSizing: "border-box",
        width: "37px",
        height: "24px",
        textAlign: "center",
        borderRadius: "2px",
        ...inputBorderStyles({ error, theme }),
      },
    },
    "& > .MuiBox-root:last-child": {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: "35px",
      marginTop: "10px",
      alignItems: "center",
      "& > div": {
        width: "25%",
        minWidth: "125px",
      },
    },
  })
);

export const WeeklyPatternCheckbox = styled(Box)({
  "& > .MuiBox-root": {
    margin: "5px 0",
  },
});

export const RecurrenceYearlyFormWrapper = styled(Box)({
  paddingLeft: "12px",
  "& > div > input, & > div > div > input": {
    margin: "0px 5px",
    padding: "0",
    boxSizing: "border-box",
    width: "37px",
    height: "24px",
    textAlign: "center",
    borderRadius: "2px",
    "&:focus": { outline: "none" },
  },
});

export const RecurrenceYearlyInputStyles = styled(Box)(
  ({ error }: { error?: string }) => ({
    marginBottom: "15px",
    "& > input": {
      ...inputBorderStyles({ error, theme }),
    },
  })
);

export const MonthNameSelectUI = styled(BasicSelectUI)({
  maxWidth: "110px",
  minWidth: "110px",
  margin: "0 5px",
});

export const SpecificDaySelectUI = styled(BasicSelectUI)({
  maxWidth: "100px",
  minWidth: "100px",
  margin: "0 5px",
});

export const SpecificMonthDaySelectUI = styled(BasicSelectUI)({
  maxWidth: "120px",
  minWidth: "120px",
  margin: "0 5px",
});

export const SpecificDateSelectUI = styled(BasicSelectUI)({
  maxWidth: "110px",
  minWidth: "110px",
  margin: "0 5px",
});

export const SpecificDateWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "200px",
  marginTop: "10px",
});

export const SpecificDateRadioBtn = styled(Box)({
  "& > .MuiBox-root": {
    minWidth: "100px",
  },
});

// export const RecurrenceYearlyStyles =

export const SubmitBtnsBoxStyles = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center! important",
  marginTop: "24px",
  "& > .MuiButtonBase-root": {
    margin: "0 6px",
    textTransform: "none",
    fontSize: "1.25rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    lineHeight: "1",
    padding: "5px 10px!important",
    minWidth: "90px!important",
    transform: "translateX(0%)!important",
  },
});

export const DeleteBtnStyles = styled(Button)(
  ({ actionData }: { actionData: ActionItem | undefined }) => ({
    color: theme.palette.warningColor.main,
    borderColor: actionData?.id
      ? theme.palette.warningColor.main
      : theme.palette.border.main,
  })
);
