import { useTranslation } from "react-i18next";
import PageTitle from "../../components/common/PageTitle/PageTitle";

const SuperAdminArea = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      title: "superAdminPage.title",
      link: "superadminarea",
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={"Super Admin Area"} />
      <div>Welcome to Super Admin Area...</div>
    </>
  );
};

export default SuperAdminArea;
