import { BlockIcon, CheckIcon } from "../../../assets/icons/Icons";
import { theme } from "../../../theme";
import { StatusTypes } from "../../../types/common";

const StatusDisplay = ({ status }: { status: string }) => {
  return (
    <>
      {status === StatusTypes.Active ?
        <CheckIcon color={theme.palette.primary.main} />
        :
        <BlockIcon color="red" />
      }
    </>
  );
};

export default StatusDisplay;
