import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { CertificateItem } from "../../types/certificates";
import {
    SortingDataType,
    TableSortDirection,
    sortArrayBy,
} from "../../common/logic/TableSortUtils";
import { PaginationType } from "../../types/common";
import { setInitalPagination } from "./utils";
import { fetchCertificatesThunk } from "../thunk/certificatesThunk";

interface CertificatesInitialState {
    editMode: boolean;
    isNewCertificateToAdd: boolean;
    onSave: boolean;
    certificatesToEdit: CertificateItem[] | [];
    certificatesData: CertificateItem[];
    pagination: PaginationType;
}

const initialState: CertificatesInitialState = {
    certificatesData: [],
    editMode: false,
    isNewCertificateToAdd: false,
    onSave: false,
    certificatesToEdit: [],
    pagination: {
        totalPages: 0,
        currentPage: 1,
        itemsPerPage: 1,
    },
};

const certificatesReducer = createSlice({
    name: "certificates",
    initialState,
    reducers: {
        sortCertificates: (
            state,
            action: PayloadAction<{
                dataField: string;
                direction: TableSortDirection;
                dataType: SortingDataType;
            }>
        ) => {
            const { dataField, dataType, direction } = action.payload;
            const arrayToSort = current(state.certificatesData);
            state.certificatesData = sortArrayBy(
                arrayToSort,
                dataField,
                dataType,
                direction
            );
        },
        setCertificates: (state, action: PayloadAction<CertificateItem[]>) => {
            state.certificatesData = action.payload;
            setInitalPagination(state, action.payload);
        },
        setCertificatesEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        onCreateNewCertificate: (state, payload: PayloadAction<boolean>) => {
            state.isNewCertificateToAdd = payload.payload;
        },
        createNewCertificate: (state, payload: PayloadAction<CertificateItem>) => {
            state.certificatesData = [payload.payload, ...state.certificatesData];
            state.isNewCertificateToAdd = false;
          },
        saveCertificatesTable: (state) => {
            state.editMode = false;
            state.certificatesToEdit = [];
        },
        setCertificatesToEdit: (
            state,
            action: PayloadAction<CertificateItem>
        ) => {
            state.certificatesToEdit = state.certificatesToEdit.length === 0
                ? [action.payload]
                : [
                    ...state.certificatesToEdit.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ];
        },
        setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
            state.pagination = { ...state.pagination, ...action.payload };
        }
    },

    extraReducers: (builder) => {
        builder.addCase(
            fetchCertificatesThunk.fulfilled, (state, action) => {
                state.certificatesData = action.payload as CertificateItem[];
                setInitalPagination(state, action.payload as CertificateItem[]);
            });
    },
});

export const {
    sortCertificates,
    setCertificates,
    setCertificatesEditMode,
    onCreateNewCertificate,
    createNewCertificate,
    saveCertificatesTable,
    setCertificatesToEdit,
    setPagination
} = certificatesReducer.actions;

export default certificatesReducer.reducer;
