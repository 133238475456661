import React, { FC, useState } from "react";
import { SidebarLinkPathTypes, SidebarLinkTypes } from "../LinkTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ChevronDownIcon } from "../../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { SimpleSidebarLink } from "./SimpleSidebarLink";
import { GroupViewIcon } from "../../../assets/icons/Icons";
import { CoinsIcon } from "../../../assets/icons/Icons";
import {
  AccordionDetailsMain,
  AccordionMain,
  AccordionSummaryBox,
  ComplexSideBarLinkUI,
  GroupsComplexLinkAccordionDetails,
  GroupsComplexLinkBox,
  IncentivationComplexLinkBox,
} from "../Layout.styles";
import { ActionModulesComplexLink } from "./SideBarComponents/ActionModulesComplexLink";
import { SuperAdminComplexLink } from "./SideBarComponents/SuperAdminComplexLink";
import { DocumentManagementComplexLink } from "./SideBarComponents/DocumentManagementComplexLink";
import { ConfigurationComplexLink } from "./SideBarComponents/ConfigurationComplexLink";

interface ComplexSidebarLinkProps {
  linkName: SidebarLinkTypes;
  activePathname: string;
  filteredLinks?: string[];
}

const ComplexSidebarLink: FC<ComplexSidebarLinkProps> = ({
  linkName,
  activePathname,
  filteredLinks
}) => {
  switch (linkName) {
    case SidebarLinkTypes.groups:
      return <GroupsComplexLink activePathname={activePathname} />;
    case SidebarLinkTypes.incentivation:
      return <IncentivationComplexLink activePathname={activePathname} filteredLinks={filteredLinks} />;
    case SidebarLinkTypes.actionModules:
      return <ActionModulesComplexLink activePathname={activePathname} />;
    case SidebarLinkTypes.documentManagement:
      return <DocumentManagementComplexLink activePathname={activePathname} filteredLinks={filteredLinks} />;
    case SidebarLinkTypes.configuration:
      return <ConfigurationComplexLink activePathname={activePathname} />;
    case SidebarLinkTypes.superAdmin:
      return <SuperAdminComplexLink activePathname={activePathname} />;
    default:
      return <></>;
  }
};

const GroupsComplexLink = ({ activePathname }: { activePathname: string }) => {
  const { t } = useTranslation();
  const path = SidebarLinkPathTypes.groupsTaxfreebenefit;
  const isActive = activePathname === path;
  return (
    <ComplexSideBarLinkUI>
      <Accordion>
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <GroupsComplexLinkBox>
            <GroupViewIcon />
            <Typography>{t(SidebarLinkTypes.groups)}</Typography>
          </GroupsComplexLinkBox>
        </AccordionSummary>
        <GroupsComplexLinkAccordionDetails>
          <SimpleSidebarLink
            isActive={isActive}
            linkName={SidebarLinkTypes.groupsTaxfreebenefit}
            path={path}
          />
        </GroupsComplexLinkAccordionDetails>
      </Accordion>
    </ComplexSideBarLinkUI>
  );
};

const IncentivationComplexLink = ({
  activePathname,
  filteredLinks
}: {
  activePathname: string;
  filteredLinks?: string[]
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <ComplexSideBarLinkUI>
      {(!filteredLinks || filteredLinks.length > 0) &&
        <Accordion>
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <IncentivationComplexLinkBox>
              <CoinsIcon />
              <Typography>{t(SidebarLinkTypes.incentivation)}</Typography>
            </IncentivationComplexLinkBox>
          </AccordionSummary>
          <AccordionDetailsMain>
            <AccordionMain expanded={expanded}>
              <AccordionSummary
                expandIcon={<ChevronDownIcon />}
                onClick={() => setExpanded((state) => !state)}
              >
                <AccordionSummaryBox>
                  <SimpleSidebarLink
                    isActive={
                      activePathname ===
                      SidebarLinkPathTypes.incentivationTaxfreebenefit
                    }
                    linkName={SidebarLinkTypes.groupsTaxfreebenefit}
                    path={SidebarLinkPathTypes.incentivationTaxfreebenefit}
                  />
                </AccordionSummaryBox>
              </AccordionSummary>
              <AccordionDetails>
                {
                  filteredLinks?.includes("EfficientDriving") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.efficientDriving}
                    linkName={SidebarLinkTypes.efficientDriving}
                    path={SidebarLinkPathTypes.efficientDriving}
                  />
                }
                {
                  filteredLinks?.includes("OfficeIncentive") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.officeIncentive}
                    linkName={SidebarLinkTypes.officeIncentive}
                    path={SidebarLinkPathTypes.officeIncentive}
                  />
                }
                {
                  filteredLinks?.includes("SalesLeads") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.salesLeads}
                    linkName={SidebarLinkTypes.salesLeads}
                    path={SidebarLinkPathTypes.salesLeads}
                  />
                }
                {
                  filteredLinks?.includes("PresenceQuota") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.presenceQuota}
                    linkName={SidebarLinkTypes.presenceQuota}
                    path={SidebarLinkPathTypes.presenceQuota}
                  />
                }
                {
                  filteredLinks?.includes("SignatureControl") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.signatureControl}
                    linkName={SidebarLinkTypes.signatureControl}
                    path={SidebarLinkPathTypes.signatureControl}
                  />
                }
                {
                  filteredLinks?.includes("MonthlyTaxFreeIncentive") &&
                  <SimpleSidebarLink
                    isActive={activePathname === SidebarLinkPathTypes.monthlyTaxFreeIncentive}
                    linkName={SidebarLinkTypes.monthlyTaxFreeIncentive}
                    path={SidebarLinkPathTypes.monthlyTaxFreeIncentive}
                  />
                }
              </AccordionDetails>
            </AccordionMain>
          </AccordionDetailsMain>
        </Accordion>
      }
    </ComplexSideBarLinkUI>
  );
};

export default ComplexSidebarLink;
