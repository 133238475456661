import { FC, useState } from "react";
import { Box } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyTextToClipboard } from "../utils";
import { useAccountManagementEditMode } from "../../../store/selectors/accountManagementSelectors";
import AccountManagementCreateEditUser from "./AccountManagementCreateEditUser";
import BorderRow from "../../../components/common/table/BorderRow";
import { copySuccessVisibleStyle } from "../../../components/common/stylings/GlobalStyles";
import { BasicTableRowStyles } from "../../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { AccountManagementUserType } from "../../../types/accountManagementUsersType";
import { useGroupsData } from "../../../store/selectors/groupSelectors";
import TooltipTypography from "../../../components/common/TooltipTypography";

interface AccountManagementRowProps {
  userData: AccountManagementUserType;
}

const AccountManagementRow: FC<AccountManagementRowProps> = ({ userData }) => {
  const {
    firstName,
    lastName,
    employeeNumber,
    groupId,
    registrationCode,
    position,
    status
  } = userData;

  const [copySuccessVisible, setCopySuccessVisible] = useState(false);

  const editMode = useAccountManagementEditMode();

  const groupList = useGroupsData();
  const groupIndex = groupList.map(i => i.id).indexOf(groupId)
  const groupName = groupIndex < 0 ? "" : groupList[groupIndex].name

  const handleOnCopy = () => {
    copyTextToClipboard(registrationCode);
    setCopySuccessVisible(true);
    setTimeout(() => {
      setCopySuccessVisible(false);
    }, 1500);
  };

  const { t } = useTranslation();

  if (editMode) return <AccountManagementCreateEditUser user={userData} />;

  return (
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={firstName}        
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={lastName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={position}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={employeeNumber}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={groupName ? groupName : "-"}
        />
      </CellBox>
      <CellBox>
        <StatusDisplay status={status} />
      </CellBox>
      <CellBox>
        <div
          className="registrationCodeContainer"
          style={{
            position: "relative",
          }}
        >
          {copySuccessVisible ? (
            <div
              id="CopySuccess"
              style={{
                ...copySuccessVisibleStyle,
                ...{ opacity: "0.8", transition: "opacity 0.2s linear" },
              }}
            >
              {t("label.codeCopySuccess")}
            </div>
          ) : (
            <div
              id="CopySuccess"
              style={{
                ...copySuccessVisibleStyle,
                ...{ opacity: "0.0", transition: "opacity 0.5s linear" },
              }}
            >
              {t("label.codeCopySuccess")}
            </div>
          )}
          <Box
            onClick={handleOnCopy}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <TooltipTypography
              value={registrationCode}
              sxTypography={{
                minWidth: "140px",
                fontFamily: "Courier",
                color: (theme) => theme.palette.textMain.secondary,
              }}
            />
            {registrationCode && <ContentCopyIcon sx={{ marginLeft: "6px" }} />}
          </Box>
        </div>
      </CellBox>
      <BorderRow />
    </BasicTableRowStyles>
  );
};

export default AccountManagementRow;
