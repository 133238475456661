import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionItem } from "../../types/actionItem";
import { fetchActions } from "../thunk/actionsThunk";

export interface ActionsInitialState {
  actionsData: ActionItem[];
  actionToEdit: ActionItem | Partial<ActionItem> | null;
}

const initialState: ActionsInitialState = {
  actionToEdit: null,
  actionsData: [],
};

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setActions: () => { },
    setActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      if (state.actionToEdit)
        state.actionToEdit = { ...state.actionToEdit, ...action.payload };
      else state.actionToEdit = action.payload;
    },
    setEditedActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      state.actionToEdit = action.payload
    },
    deleteAction: (state, action: PayloadAction<number>) => {
      state.actionsData = state.actionsData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActions.fulfilled, (state, action) => {
      if (!action.payload || !(action.payload as ActionItem[]).length)
        state.actionsData = [];
      else state.actionsData = action.payload as ActionItem[];
    });
  },
});

export const { deleteAction, setActions, setActionData, setEditedActionData } = actionsSlice.actions;

export default actionsSlice.reducer;
