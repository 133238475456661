import { SidebarLinkPathTypes, SidebarLinkTypes } from "../../LinkTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ChevronDownIcon, SettingsIcon } from "../../../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { SimpleSidebarLink } from "../SimpleSidebarLink";
import { ComplexSideBarLinkUI } from "./ComplexSideBarLinkUI";


export const ConfigurationComplexLink = ({ activePathname }: { activePathname: string }) => {
  const { t } = useTranslation();
  const path = SidebarLinkPathTypes.appDesign;
  const isActive = activePathname === path;
  return (
    <ComplexSideBarLinkUI>
      <Accordion>
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <Box sx={{ display: "flex", alignItems: "center", padding: "6px 0" }}>
            <SettingsIcon />
            <Typography>{t(SidebarLinkTypes.configuration)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: "24px!important" }}>
          <SimpleSidebarLink
            isActive={isActive}
            linkName={SidebarLinkTypes.appDesign}
            path={path}
          />
        </AccordionDetails>
      </Accordion>
    </ComplexSideBarLinkUI>
  );
};