import { useState } from "react";

export const useDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen((state) => !state);
  };

  return {
    open,
    handleDialogClose,
    handleDialogOpen,
  };
};
