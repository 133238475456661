import { Box, styled } from "@mui/material";

export const ScoreRangesWrapper = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
});

export const ScoreRangesTable = styled(Box)({
    display: "flex",
    flexDirection: "column",
});

export const ScoreRangesRow = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
});
