import { AppDesignWrapper, ButtonWrapper } from './AppDesign.styles'
import AppDesignPreview from './AppDesignPreview'
import AppDesignSettings from './AppDesignSettings'
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { useConfigEditMode } from '../../../store/selectors/configurationSelector'
import { useEffect, useState } from 'react'
import { companyLogoUploadThunk, editAccentColorThunk, fetchAppDesignThunk } from '../../../store/thunk/configurationThunk'
import { setConfigEditMode } from '../../../store/reducers/configurationReducer'
import { SaveIcon } from '../../../assets/icons/ActionIcons'
import { EditIcon } from '../../../assets/icons/Icons'
import { theme } from '../../../theme'
import { Box } from '@mui/material'

const AppDesignContainer = () => {
    const dispatch = useAppDispatch();
    const [configEditState, setConfigEditState] = useState(useConfigEditMode());

    useEffect(() => {
        dispatch(fetchAppDesignThunk());
    }, []);

    const handleEditMode = async () => {
        if (configEditState) {
            await dispatch(companyLogoUploadThunk())
            await dispatch(editAccentColorThunk())
        }
        dispatch(setConfigEditMode(!configEditState))
        setConfigEditState(!configEditState)
    }

    return (
        <AppDesignWrapper>
            <AppDesignSettings />
            <AppDesignPreview />
            <Box style={{ flex: 1 }}></Box>
            <ButtonWrapper onClick={handleEditMode}>
                {configEditState
                    ? <SaveIcon colorHex={theme.palette.companyMain.main} />
                    : <EditIcon />
                }
            </ButtonWrapper>
        </AppDesignWrapper>
    )
}

export default AppDesignContainer