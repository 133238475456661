export enum FulfilledStatusType {
  NotFulfilled = "NotFulfilled",
  Partially = "PartiallyFulfilled",
  Fulfilled = "FullyFulfilled",
}

export interface ActionItem {
  name: string;
  description: string;
  companyId: number;
  iconName: string;
  colorHex: string;
  frequency: string;
  recurring: string;
  id: number;
  maxPoints: number;
  type: string;
  subtype: string;
  lastUploadDate: string | null;
  employeeDescription: string;
  executionTimesPerMonth: number;
  rewardedPointsFullyFullfilled: number;
  rewardedPointsNotFullfilled: number;
  rewardedPointsPartiallyFullfilled: number;
  scoreRanges: ScoreRange[];
}

export type ActionItemIcon = {
  colorHex?: string;
};

export type ActionIconData = {
  iconName: string;
  colorHex: string;
};

export interface ScoreRange {
  scoreFrom: number;
  scoreTo: number;
  pointsRewarded: number;
};
