import { useAppDispatch } from "../../../hooks/StoreHooks";
import { onCreateNewCertificate, setCertificatesEditMode } from "../../../store/reducers/certificatesReducer";
import SaveBtn from "../../../components/common/table/SaveBtn";
import SearchWithAutocomplete from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import EditBtn from "../../../components/common/table/EditBtn";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SearchWithAutocompleteWrapper } from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import { useCertificatesData, useCertificatesEditMode, useOnNewCertificateAdd } from "../../../store/selectors/certificatesSelectors";
import { editCertificatesThunk } from "../../../store/thunk/certificatesThunk";
import { CertificateItem } from "../../../types/certificates";
import NewBtn from "../../../components/common/table/NewBtn";
import { SearchBarButtonContainer } from "./CertificatesPage.styles";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";

interface NewCertificateButtonProps {
  isNewCertificateAdd: boolean;
  onNewCertificateHandler: () => void;
}

const NewCertificateButton: FC<NewCertificateButtonProps> = ({
  isNewCertificateAdd,
  onNewCertificateHandler,
}) => {
  const editMode = useCertificatesEditMode();
  if (editMode) return null;
  return (
    <NewBtn
      handleOnClick={onNewCertificateHandler}
      isActive={isNewCertificateAdd}
    />
  );
};

interface CertificatesSearchBarProps {
  handleOnSearch: (searchQuery: string) => void;
}

const CertificatesSearchBar: FC<CertificatesSearchBarProps> = ({ handleOnSearch }) => {
  const { t } = useTranslation();
  const editMode = useCertificatesEditMode();
  const dispatch = useAppDispatch();
  const certificates = useCertificatesData();

  const certificatesNames = certificates
    ? certificates.map((certificate: CertificateItem) => ({
      key: certificate.id,
      label: certificate.documentName,
    }))
    : [];

  const handleOnSave = async () => {
    dispatch(setProcessingRequest(true))
    let res = await dispatch(editCertificatesThunk());
    dispatch(setResponseValue(res.type))
    dispatch(setResponseMessage(`${t("dialogModals.itemsSaved")}`))
  };

  return (
    <SearchWithAutocompleteWrapper>
      <SearchWithAutocomplete
        options={certificatesNames}
        onSearchHandler={handleOnSearch}
      />
      <SearchBarButtonContainer>
        <NewCertificateButton
          isNewCertificateAdd={useOnNewCertificateAdd()}
          onNewCertificateHandler={() => dispatch(onCreateNewCertificate(true))}
        />
        {!editMode ? (
          <EditBtn handleOnClick={() => dispatch(setCertificatesEditMode(true))} />
        ) : (
          <SaveBtn handleOnClick={handleOnSave} title={t("buttons.save")} />
        )}
      </SearchBarButtonContainer>
    </SearchWithAutocompleteWrapper>
  );
};

export default CertificatesSearchBar;
