import { Box } from "@mui/material";
import React from "react";

const BorderRow = () => {
  return (
    <Box
      sx={(theme) => ({
        gridColumn: "1 / -1",
        height: "1px!important",
        borderBottom: `1px solid ${theme.palette.border?.main}`,
        width: "100%",
      })}
    ></Box>
  );
};

export default BorderRow;
