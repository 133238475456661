import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { ActionManagementUserType } from "../../types/actionManagementUserType";
import { FulfilledStatusType } from "../../types/actionItem";
import {
  SortingDataType,
  TableSortDirection,
  sortArrayBy,
} from "../../common/logic/TableSortUtils";
import { PaginationType } from "../../types/common";
import { setInitalPagination } from "./utils";

type EditUserDataPayload = {
  id: string;
  fulfilledStatus: FulfilledStatusType;
  scoreValue?: number;
  earnedPointsTotal: number;
  dateOfAction: string;
  actionModuleId: number;
};

export interface ActionsInitialState {
  editMode: boolean;
  currentActionId: string | number;
  users: ActionManagementUserType[] | [];
  filteredUsers: ActionManagementUserType[] | [];
  dataToEdit: Partial<ActionManagementUserType>[] | [];
  pagination: PaginationType;
  excelEmployeeScoresParsedData: any | null;
}

const initialState: ActionsInitialState = {
  editMode: false,
  currentActionId: 1,
  dataToEdit: [],
  users: [],
  filteredUsers: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 1,
  },
  excelEmployeeScoresParsedData: [],
};

const accountManagementReducer = createSlice({
  name: "actionModuleManagement",
  initialState,
  reducers: {
    setActionManagementEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    sortActionManagementUsersTable: (
      state,
      action: PayloadAction<{
        dataField: string;
        direction: TableSortDirection;
        dataType: SortingDataType;
        dataSample?: any;
      }>
    ) => {
      const { dataField, dataType, direction, dataSample } = action.payload;
      const arrayToSort = current(state.users);
      state.users = sortArrayBy(
        arrayToSort,
        dataField,
        dataType,
        direction,
        dataSample
      );
    },
    setUsers: (state, action: PayloadAction<ActionManagementUserType[]>) => {
      state.users = action.payload;
    },
    setFilteredUsers: (
      state,
      action: PayloadAction<ActionManagementUserType[]>
    ) => {
      state.filteredUsers = action.payload;
      setInitalPagination(state, action.payload);
    },
    setUsersToEdit: (state, action: PayloadAction<EditUserDataPayload | []>) => {
      if (!Array.isArray(action.payload)) {
        const dataToEdit = state.dataToEdit;
        let userDataToEdit = dataToEdit.find(
          (data) => data.id === (action.payload as EditUserDataPayload).id
        );
        if (userDataToEdit) {
          state.dataToEdit = dataToEdit.map((dataItem) =>
            dataItem.id === (action.payload as EditUserDataPayload).id
              ? { ...dataItem, ...action.payload }
              : dataItem
          );
        } else {
          state.dataToEdit = [...dataToEdit, { ...action.payload }];
        }
      } else {
        state.dataToEdit = [];
      }
    },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setExcelEmployeeScoresParsedData: (state, action: PayloadAction<Partial<any[]> | []>) => {
      state.excelEmployeeScoresParsedData = { ...state.excelEmployeeScoresParsedData, ...action.payload };
    },
  },
});

export const {
  setActionManagementEditMode,
  sortActionManagementUsersTable,
  setUsersToEdit,
  setUsers,
  setFilteredUsers,
  setPagination,
  setExcelEmployeeScoresParsedData,
} = accountManagementReducer.actions;

export default accountManagementReducer.reducer;
