import React from "react";
import { Box } from "@mui/material";

export const ComplexSideBarLinkUI = ({
    children,
  }: {
    children: React.ReactNode | React.ReactNode[];
  }) => {
    return (
      <Box
        sx={{
          ".MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
          },
          ".MuiButtonBase-root": {
            minHeight: "0!important",
          },
          ".MuiAccordionSummary-content": {
            margin: "0!important",
            "& .MuiBox-root": {
              svg: {
                width: "20px",
                height: "20px",
                marginRight: "6px",
              },
              ".MuiTypography-root": {
                fontWeight: "600",
                fontSize: "0.9rem",
                color: (theme) => theme.palette.backgroundColor.main,
              },
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            svg: {
              width: "16px",
              height: "16px",
              path: {
                fill: (theme) => theme.palette.backgroundColor.main,
              },
            },
          },
          ".MuiAccordionDetails-root": {
            padding: "8px",
          },
        }}
      >
        {children}
      </Box>
    );
  };