import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { incentivationUsersApi } from "../../api/incentivationUsersApi";
import { ActionManagementUserType } from "../../types/actionManagementUserType";
import { setUsers, setUsersToEdit } from "../reducers/actionModuleManagementReducer";
import { EmployeeScores } from "../../types/employeeScores";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchActionModuleManagementUsers = createAsyncThunk(
  "actionModuleManagement/getUsers",
  async (_, thunkApi) => {
    try {
      const users = await incentivationUsersApi.getUsers();
      thunkApi.dispatch(setUsers(users as ActionManagementUserType[]));
      thunkApi.dispatch(setProcessingRequest(false))
    } catch (error) {
      console.log(error);
    }
  }
);

export const editActionModuleManagementUserThunk = createAsyncThunk(
  "actionModuleManagement/editUser",
  async (actionModuleId: number, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const usersDataToEdit = state.actionManagement.dataToEdit;
    const usersToEdit = state.actionManagement.users
      .filter((user) =>
        usersDataToEdit.find((dataItem) => dataItem.id === user.id)
          ? true
          : false
      )
      .map((user) => ({
        ...user,
        ...usersDataToEdit.find((dataItem) => dataItem.id === user.id),
      }));

    try {
      const usersToEditRes = await Promise.all(
        usersDataToEdit.map((user) => 
          incentivationUsersApi.editUser(user.id!, {
            fulfilledStatus: user.fulfilledStatus!,
            scoreValue: user.scoreValue,
            actionModuleId: actionModuleId,
          })
        )
      ).then((res) => res);
      const usersSuccessfullyEditedData = usersToEdit.filter(
        (_, idx) => usersToEditRes[idx].status === 200
      );
      const updatedUsersData = state.actionManagement.users.map((user) => {
        const userToEdit = usersSuccessfullyEditedData.find(
          (userData) => userData.id === user.id
        );
        return userToEdit ? userToEdit : user;
      });

      thunkApi.dispatch(setUsers(updatedUsersData));
      
      thunkApi.dispatch(setUsersToEdit([]));
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(setProcessingRequest(false))
    }
  }
);

export const ImportEmployeeScoresForActionModuleThunk = createAsyncThunk(
  "actionModuleManagement/importEmployeeScores",
  async (actionModuleId: number, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const data = state.actionManagement.excelEmployeeScoresParsedData;
      let employeeScores: EmployeeScores[] = [];
      Object.keys(data).forEach(e => {
        employeeScores.push({ employeeNumber: data[e].EmployeeNumber.toString(), score: data[e].Score as number});
      });
      const result = await incentivationUsersApi.importEmployeeScoresAction(actionModuleId, employeeScores);
      return result?.status
    } catch (error) {
      console.log(error);
    }
  }
);

export const ImportEmployeeScoresForSubtypeThunk = createAsyncThunk(
  "actionModuleManagement/importEmployeeScoresForSubtype",
  async (subtype: string, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const data = state.actionManagement.excelEmployeeScoresParsedData;
      let employeeScores: EmployeeScores[] = [];
      Object.keys(data).forEach(e => {
        employeeScores.push({ employeeNumber: data[e].EmployeeNumber.toString(), score: data[e].Score as number});
      });
      const result = await incentivationUsersApi.importEmployeeScoresForSubtype(subtype, employeeScores);
      return result?.status
    } catch (error) {
      console.log(error);
    }
  }
);