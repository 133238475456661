import { Box } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { stringValidation } from "../../utils";
import Checkbox from "../../../../components/common/Checkbox";
import { daysList } from "../../constants";
import { RecurrencySubformProps, WeeklyRecurrenceType } from "./RecurrenceForm";
import { useTranslation } from "react-i18next";
import {
  RecurrrenceWeeklyWrapper,
  WeeklyPatternCheckbox,
} from "../ActionModuleCard.styles";

const RecurrenceWeekly: FC<RecurrencySubformProps> = ({
  handleOnSetRecurrenceFormData,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");
  const [weekNumber, setWeekNumber] = useState<string>("1");
  const [weeklyRecurrence, setWeeklyRecurrence] =
    useState<WeeklyRecurrenceType>({
      numberOfWeeks: 1,
      selectedDays: [t("days.Monday")],
    });

  useEffect(() => {
    handleOnSetRecurrenceFormData(weeklyRecurrence);
  }, [weeklyRecurrence]);

  const handleOnBlur = (e: SyntheticEvent) => {
    const value = stringValidation((e.target as HTMLInputElement).value, true);
    if (value === "error") {
      setError(value);
      return;
    } else {
      if ((value as number) > 4 || (value as number) < 1) {
        setError("error");
        return;
      }
      setError("");
      setWeeklyRecurrence((state) => ({
        ...state,
        numberOfWeeks: value as number,
      }));
    }
  };

  const daysCheckboxes = daysList.map((item) => {
    const isChecked = weeklyRecurrence.selectedDays.find(
      (day) => t(item) === day
    )
      ? true
      : false;

    const translatedDay = t(`days.${item}`);
    const handleOnClick = () => {
      if (isChecked) {
        setWeeklyRecurrence((state) => ({
          ...state,
          selectedDays: [
            ...state.selectedDays.filter((day) => day !== translatedDay),
          ],
        }));
      } else {
        setWeeklyRecurrence((state) => ({
          ...state,
          selectedDays: [...state.selectedDays, translatedDay],
        }));
      }
    };

    return (
      <WeeklyPatternCheckbox>
        <Checkbox
          key={translatedDay}
          label={translatedDay}
          handleOnClick={handleOnClick}
          isChecked={isChecked}
        />
      </WeeklyPatternCheckbox>
    );
  });

  return (
    <RecurrrenceWeeklyWrapper error={error}>
      <Box>
        <span>{t("actionPage.recurEvery")}</span>{" "}
        <input
          type="text"
          onBlur={handleOnBlur}
          onChange={(e) => setWeekNumber(e.target.value)}
          value={weekNumber}
        />{" "}
        <span>{t("actionPage.week(s)On")}:</span>
      </Box>
      <Box>{daysCheckboxes}</Box>
    </RecurrrenceWeeklyWrapper>
  );
};

export default RecurrenceWeekly;
