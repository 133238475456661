import { useAppSelector } from "../../hooks/StoreHooks";

export const useBillsData = () =>
  useAppSelector((state) => state.bills.billsData);

export const useCompanyListData = () =>
  useAppSelector((state) => state.bills.companyList);

export const usePagination = () =>
  useAppSelector((state) => state.bills.pagination);

export const useBillsEditMode = () =>
  useAppSelector((state) => state.bills.editMode);

export const useNewBillMode = () =>
  useAppSelector((state) => state.bills.newBillMode);