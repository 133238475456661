import { SxProps, Theme, Tooltip, Typography, TypographyVariant } from "@mui/material";
import { FC } from "react";

interface TooltipTypographyProps {
    value: any;
    sxTypography?: SxProps<Theme>;
    variantTypography?: TypographyVariant;
}

const TooltipTypography: FC<TooltipTypographyProps> = ({ value, sxTypography}) => {
    return (
        <Tooltip title={value}>
            <Typography sx={{...sxTypography}}>
                {value}
            </Typography>
        </Tooltip>
    );
};

export default TooltipTypography;