import { Box, Button, SxProps } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { CancelIcon } from "../../../assets/icons/ActionIcons";
import { GroupItem } from "../../../types/groupItem";
import DefaultIcon from "../DefaultIcon";
import { ActionButtonWrapperStyles } from "../../../pages/groups/GroupsBlock/GroupsBlock.styles";
interface ActionButtonProps {
  actionName: string;
  iconName: string;
  isEditMode?: boolean;
  colorHex?: string;
  sx?: SxProps;
  handleOnEdit?: () => void;
  setSelectedActions?: Dispatch<SetStateAction<GroupItem>>;
  handleActionButtonDelete?: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({
  iconName,
  isEditMode,
  colorHex,
  sx,
  handleActionButtonDelete,
}) => {
  return (
    <ActionButtonWrapperStyles>
      <Button sx={{
        maxHeight: "30px",
        maxWidth: "30px",
      }}>
        <DefaultIcon iconName={iconName} colorHex={colorHex ? colorHex : ""} />
      </Button>
      {isEditMode && (
        <Box>
          {" "}
          <Button
            onClick={() =>
              handleActionButtonDelete && handleActionButtonDelete()
            }
          >
            <CancelIcon />
          </Button>
        </Box>
      )}
    </ActionButtonWrapperStyles>
  );
};

export default ActionButton;
