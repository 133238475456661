import { Box, SxProps, TextField } from "@mui/material";
import React, { FC } from "react";
import { SimpleSearchIcon } from "../../assets/icons/Icons";
import { SimpleSearchBasicStyles } from "./Common.styles";

interface SimpleSearchProps {
  searchQuery: string;
  sx?: SxProps;
  searchIconColor?: string;
  onSearchHandler: (searchQuery: string) => void;
}

const SimpleSearch: FC<SimpleSearchProps> = ({
  searchQuery,
  sx,
  searchIconColor,
  onSearchHandler,
}) => {
  const eventHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    onSearchHandler(target.value);
  };
  return (
    <SimpleSearchBasicStyles>
      <TextField onChange={eventHandler} value={searchQuery} />
      <Box>
        <SimpleSearchIcon
          color={searchIconColor ? searchIconColor : "#e7e7e7"}
        />
      </Box>
    </SimpleSearchBasicStyles>
  );
};

export default SimpleSearch;
