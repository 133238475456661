import { Button } from "@mui/material";
import { EditIcon } from "../../../assets/icons/Icons";
import { FC } from "react";
import { theme } from "../../../theme";

interface EditBtnProps {
  handleOnClick: () => void;
}

const EditBtn: FC<EditBtnProps> = ({ handleOnClick }) => {
  return (
    <Button
      onClick={handleOnClick}
      sx={{
        width: "fit-content",
        padding: "0",
        minWidth: "0",
      }}
    >
      <EditIcon />
    </Button>
  );
};

export default EditBtn;
