import TableColumnHeader from "../../../../components/common/table/TableColumnHeader";
import {
  SortingDataType,
  TableSortDirection,
} from "../../../../common/logic/TableSortUtils";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { sortTaxFreeBenefitUsersTable } from "../../../../store/reducers/taxFreeBenefitReducer";
import { useGroupsData } from "../../../../store/selectors/groupSelectors";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../../components/common/table/BorderRow";
import { TableHeaderWrapper } from "../../../../components/common/Common.styles";

const TaxFreeBenefitTableHeader = () => {
  const dispatch = useAppDispatch();
  const groups = useGroupsData();
  const { t } = useTranslation();

  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    if (groups && dataField === "groupName") {
      const groupsMapped = groups.map((group) => ({
        id: group.id,
        groupName: group.name,
      }));
      dispatch(
        sortTaxFreeBenefitUsersTable({
          dataField,
          direction,
          dataType,
          arrayToSort: groupsMapped,
        })
      );
    } else
      dispatch(
        sortTaxFreeBenefitUsersTable({ dataField, direction, dataType })
      );
  };

  return (
    <TableHeaderWrapper>
      <TableColumnHeader
        dataField="name"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.name")}
        firstCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="position"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.position")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="employeeNumber"
        dataType="number"
        isSorted={true}
        label={t("tableColumnHeaders.employeeNo")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="groupName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.group")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="status"
        dataType="string"
        isSorted={true}
        lastCol={true}
        label={t("tableColumnHeaders.statusAndPoints")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default TaxFreeBenefitTableHeader;
