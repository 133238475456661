import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import { useSearch } from "../../../hooks/UseSearch";
import SearchWithAutocomplete from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import { useGroupsData } from "../../../store/selectors/groupSelectors";

interface DropDownProps {
    currentSelectionId: number;
    handleUpdate: (arg0: any, column?: string) => void;
    zeroValueAllowed?: boolean;
}

interface NameAndId {
    [key: number]: string;
}

const DropdownGroups: FC<DropDownProps> = (props) => {
    const { currentSelectionId, handleUpdate, zeroValueAllowed } = props;
    const [filteredItems, setFilteredItems] = useState<string[]>([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const { handleOnSearch } = useSearch();

    const groupList = useGroupsData();

    let groupIdAndName: NameAndId = {}
    groupList.forEach((group) => {
        groupIdAndName[group.id] = group.name
    });

    let filteredGroupIdAndName: NameAndId = {}
    for (let key in groupIdAndName) {
        if (filteredItems.indexOf(groupIdAndName[key]) > -1) {
            filteredGroupIdAndName[key] = groupIdAndName[key]
        }
    }

    let listToSearch = Object.keys(groupIdAndName).map((key) => ({
        label: groupIdAndName[key], key: key
    }))

    const handleOnChange = (e: SelectChangeEvent<Number>) => {
        handleUpdate(e.target.value)
    };

    const handleClose = () => {
        setDropdownOpen(false);
    };

    const handleOpen = () => {
        setDropdownOpen(true);
    };

    const liftUpFilteredList = (searchListItems: string[]) => {
        setFilteredItems(searchListItems);
    };

    let selectDropdownItems = Object.keys(filteredGroupIdAndName).length === 0
        ?
        Object.keys(groupIdAndName).map((key) => (
            <MenuItem key={key} autoFocus={false} value={key}>
                {groupIdAndName[key]}
            </MenuItem>))
        :
        Object.keys(filteredGroupIdAndName).map((key) => (
            <MenuItem key={key} autoFocus={false} value={key}>
                {filteredGroupIdAndName[key]}
            </MenuItem>))

    zeroValueAllowed && selectDropdownItems && selectDropdownItems.unshift(
        <MenuItem key='0' value='0'>
            -
        </MenuItem>
    )

    selectDropdownItems && selectDropdownItems.unshift(
        <SearchWithAutocomplete
            options={listToSearch}
            onSearchHandler={handleOnSearch}
            liftUpFilteredList={liftUpFilteredList}
            sx={{
                maxWidth: "499px",
                ".MuiAutocomplete-root": {
                    width: "100%",
                },
                ".search-btn": {
                    display: "none",
                },
            }}
        />
    )

    return (
        <Box
            sx={{
                width: "100%",
                ".MuiSelect-select": { padding: "9px" },
                fieldset: {
                    borderColor: (theme) => theme.palette.border,
                },
            }}
        >
            <Select
                sx={{ width: "100%" }}
                value={currentSelectionId}
                onChange={(e) => handleOnChange(e)}
                IconComponent={ExpandMoreIcon}
                autoFocus={false}
                open={dropdownOpen}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                {selectDropdownItems}
            </Select>
        </Box >
    );
};

export default DropdownGroups