import { Box, Button, styled } from "@mui/material";

export const AppDesignWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  border: `1px solid ${theme.palette.border?.main}`,
  maxWidth: "100%",
  overflowX: "auto",
  background: theme.palette.backgroundColor.main,
  padding: "2em",
  gap: "5em"
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer"
  },
}))

export const AppDesignSettingsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: "250px",
}))


export const CurrentColorPick = styled(Box)({
  marginTop: "1.5em",
  marginBottom: "1.5em"
})

export const CurrentColorDisplay = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
})

export const ColorBox = styled(Box)(
  ({ pickedColor }: { pickedColor: string }) => ({
    backgroundColor: `${pickedColor}`,
    height: "1.5em",
    width: "1.5em",
    borderRadius: "5px",
    border: "1px solid lightgrey"
  }))

export const PreviewContainer = styled(Box)({
  position: "relative",
})

export const PreviewImageContainer = styled(Box)({
  position: "absolute",
  left: "25px",
  top: "57px",
  height: "35px",
  width: "120px",
  display: "flex"
});

export const PreviewFrameContainer = styled(Box)({
  width: "100%",
});
