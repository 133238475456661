import { Box, Button, styled } from "@mui/material";
import { BasicTableRowStyles } from "../../../components/common/Common.styles";

export const GroupsSearchBarStyles = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: "22px 25px 18px 35px",
  marginBottom: "10px",
});

export const GroupsContentStyles = styled(Box)(({ theme }) => ({
  background: theme.palette.backgroundColor?.main,
  border: `1px solid ${theme.palette.border?.main}`,
}));

export const GroupsRowStyles = styled(BasicTableRowStyles)(({ theme }) => ({
  "&:last-child": {
    border: "none",
  },
  display: "contents",
  "& > div": {
    height: "79px",
    padding: "0",
    display: "flex",
    alignItems: "center",
  },

}));

export const GroupsActionsStyles = styled(Box)({
  display: "flex",
  height: "100%",
  alignItems: "center",
  gap: "12px",
  "& > div": {
    display: "flex",
    alignItems: "center",
    height: "42px",
  },
  button: {
    height: "42px",
  },
});

export const ActionBtnsListStyles = styled(Box)({
  display: "flex",
  height: "100%",
  gap: "12px",
});

export const MoreActionsBtnStyles = styled(Button)(({ theme }) => ({
  width: "60px",
  height: "100%",
  minWidth: "0",
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "5px",
  display: "flex",
  gap: "5px",
  justifyContent: "center",
  alignItems: "flex-end",
  fontSize: "1rem",
  span: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: theme.palette.border.main,
    "&:last-child": {
      background: "none",
    },
  },
}));

export const ActionsPopupBoxStyles = styled(Box)({
  width: "100%",
  maxWidth: "276px",
  display: "flex",
  flexWrap: "wrap",
  gap: "12px",
  padding: "12px",
});

export const ActionButtonWrapperStyles = styled(Box)(({ theme }) => ({
  position: "relative",
  "& > .MuiButtonBase-root": {
    width: "60px",
    minWidth: "0",
    border: `1px solid ${theme.palette.border.main}`,
  },
  ".MuiBox-root": {
    position: "absolute",
    top: "-4.5px",
    right: "-4.5px",
    ".MuiButtonBase-root": {
      padding: "0",
      minWidth: "0",
      width: "fit-content",
      height: "auto!important",
      background: theme.palette.backgroundColor.main,
      borderRadius: "50%",
      svg: {
        width: "18px",
        height: "18px",
      },
    },
  },
}));

export const AddEditGroupRowStyles = styled(Box)({
  display: "contents",
  form: {
    display: "contents",
    padding: "19px 35px",
    "&> div": {
      height: "79px",
      display: "flex",
      alignItems: "center",
    },
  },
  ".MuiTypography-root": {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    width: "100%",
    textAlign: "center",
  },
});

export const GroupNameInputStyles = styled(Box)(({ theme }) => ({
  ".MuiFormControl-root": {
    width: "100%",
    input: {
      fontSize: "1.25rem",
      lineHeight: "1.25",
      padding: "8px 9px 7px",
      height: "auto",
    },
  },
}));

export const ActionButtonWrapperOnEditStyles = styled(Box)({
  display: "flex",
  gap: "12px",
  "& > div": {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: {
    height: "42px",
  },
});

export const GroupRowBtnsStyles = styled(Box)(({ theme }) => ({
  display: "contents",
  justifyContent: "flex-start"
}));

export const AddActionBtnStyled = styled(Button)(({ theme }) => ({
  width: "60px",
  height: "100%",
  minWidth: "0",
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ActionsPopoverStyles = styled(Box)(({ theme }) => ({
  width: "300px",
  "& > .MuiBox-root": {
    borderTop: `1px solid ${theme.palette.border.main}`,
    maxHeight: "180px",
    overflowY: "auto",
  },
}));

export const ApplyBtnWrapper = styled(Box)(({ theme }) => ({
  padding: "10px",
  display: "flex",
  justifyContent: "flex-end",
  ".MuiButtonBase-root": {
    color: theme.palette.backgroundColor.main,
    background: theme.palette.companyMain.main,
    padding: "6px 13px",
    fontWeight: "600",
    "&:hover": {
      background: theme.palette.companyMain.main,
    },
  },
}));

export const SearchBarButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "30px",
  width: "10%",
  alignItems: "stretch",
  justifyContent: "space-around"
})

export const ActionListItemStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 10px",
  ".MuiBox-root": {
    display: "flex",
    alignItems: "center",
    ".MuiButtonBase-root": {
      width: "20px",
      background: "none",
      border: "none",
      padding: "0",
      marginRight: "10px",
      svg: {
        width: "auto",
        height: "1rem",
      },
    },
  },
}));
