import dayjs from "dayjs";

export const stringValidation = (string: string, isNumber?: boolean) => {
  if (string.length) {
    if (isNumber) {
      if (!isNaN(Number(string))) {
        return Number(string);
      } else return "error";
    }
    return string;
  } else return "error";
};

export const dateFormatter = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date();
  const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
  const month =
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1);
  const year = date.getFullYear();
  return year + "-" + month + "-" + day;
};

export const validateDataField = (monthValue: string, dataRange: number) => {
  const value = stringValidation(monthValue, true);
  if (value === "error") {
    return value;
  }
  if ((value as number) > dataRange || (value as number) < 1) {
    return "error";
  }
  return value;
};

export const validateStartEndDates = (
  startDateString: string,
  endDateString: string
) => {
  const startDate = Date.parse(startDateString);
  const endDate = Date.parse(endDateString);

  if (startDate > endDate) {
    return "Incorrect date input";
  }
  return "";
};

export const increaseDateByOneDay = (dateToIncrease: string) => {
  const date = new Date(
    Date.parse(dayjs(`${dateFormatter(dateToIncrease)}T00:00`)!.format())
  );
  date.setHours(date.getHours() + 24);
  return dayjs(`${dateFormatter(date.toISOString())}T00:00`);
};
