import { FC } from "react";
import { AwesomeCheckIcon, 
  CalendarMonthIcon, 
  CarWashIcon, 
  ClipboardListIcon, 
  ClockIcon, 
  DocumentIcon, 
  FuelStationIcon, 
  GpsIcon, 
  LeafIcon, 
  PersonIcon, 
  QuickReferenceIcon, 
  SmartphoneIcon, 
  SmileIcon, 
  StylusNoteIcon, 
  TrainingIcon } from "../../assets/icons/ActionIcons";
import { ActionIconData } from "../../types/actionItem";

export const defaultIcons: ActionIconData[] = [
  {
    iconName: "person",
    colorHex: "",
  },
  {
    iconName: "carwash",
    colorHex: "",
  },
  {
    iconName: "smartphone",
    colorHex: "",
  },
  {
    iconName: "checkmark",
    colorHex: "",
  },
  {
    iconName: "smile",
    colorHex: "",
  },
  {
    iconName: "training",
    colorHex: "",
  },
  {
    iconName: "fuel station",
    colorHex: "",
  },
  {
    iconName: "document",
    colorHex: "",
  },
  {
    iconName: "clipboard",
    colorHex: "",
  },
  {
    iconName: "gps",
    colorHex: "",
  },
  {
    iconName: "leaf",
    colorHex: "",
  },
  {
    iconName: "clock",
    colorHex: "",
  },
  {
    iconName: "quick reference",
    colorHex: "",
  },
  {
    iconName: "calendar month",
    colorHex: "",
  }
];

const DefaultIcon: FC<ActionIconData> = ({ iconName, colorHex }) => {
  switch (iconName) {
    case "person":
      return <PersonIcon colorHex={colorHex} />;
    case "carwash":
      return <CarWashIcon colorHex={colorHex} />;
    case "smartphone":
      return <SmartphoneIcon colorHex={colorHex} />;
    case "checkmark":
      return <AwesomeCheckIcon colorHex={colorHex} />;
    case "smile":
      return <SmileIcon colorHex={colorHex} />;
    case "training":
      return <TrainingIcon colorHex={colorHex} />;
    case "fuel station":
      return <FuelStationIcon colorHex={colorHex} />;
    case "document":
      return <DocumentIcon colorHex={colorHex} />;
    case "clipboard":
      return <ClipboardListIcon colorHex={colorHex} />;
    case "gps":
      return <GpsIcon colorHex={colorHex} />;
    case "leaf":
      return <LeafIcon colorHex={colorHex} />;
    case "clock":
      return <ClockIcon colorHex={colorHex} />;
    case "stylus note":
      return <StylusNoteIcon colorHex={colorHex} />;
    case "quick reference":
      return <QuickReferenceIcon colorHex={colorHex} />;
    case "calendar month":
      return <CalendarMonthIcon colorHex={colorHex} />;
    default:
      return <></>;
  }
};

export default DefaultIcon;
