import { Box, Button, styled } from "@mui/material";
import { theme } from "../../../theme";
import { BasicTableRowStyles } from "../../../components/common/Common.styles";

export const CertificatesContentWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));

const activeSettingsStyles = (isActive: boolean) => {
  if (isActive) {
    return {
      position: "absolute",
      top: "0",
      left: "0",
      width: "386px",
      height: "279px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.backgroundColor.main,
      padding: "0px 40px 37px 0px",
      div: {
        height: "auto",
      },
    };
  } else {
    return {};
  }
};

export const SettingsWrapperStyles = styled(Box)(
  ({ isSettingsActive }: { isSettingsActive: boolean }) => ({
    marginLeft: "26px",
    width: "140px",
    height: "31px",
    position: "relative",
    zIndex: "100",
    "& > div": {
      width: "100%",
      height: "100%",
      fontSize: "18px",
      borderRadius: "2px",
      overflow: "hidden",
      border: `1px solid ${theme.palette.textMain.main}`,
      ...activeSettingsStyles(isSettingsActive),
    },
  })
);

export const SettingsBoxStyles = styled(Box)({
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 9px",
  width: "140px",
  height: "31px",
});

export const CheckboxesWrapperStyles = styled(Box)({
  margin: "9px 0 0 9px",
  "& > .MuiTypography-root": {
    marginTop: "25px",
  },
});

export const InputFieldsBoxStyles = styled(Box)({
  height: "auto!important",
  maxHeight: "61px",
  marginBottom: "5px",
  overflowY: "auto",
  ".MuiBox-root": {
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});

export const CertificatesPageRowStyles = styled(BasicTableRowStyles)({
  button: {
    border: "none",
    outline: "none",
    padding: "none",
    background: "none",
    cursor: "pointer",
    svg: {
      width: "30px",
      height: "30px",
    },
  },
});

export const CertificatesBtnsCell = styled(Box)(
  ({ newUserMode }: { newUserMode: boolean }) => ({
    display: "flex",
    width: "100%",
    paddingRight: "35px!important",
    boxSizing: "border-box",
    justifyContent: newUserMode
      ? "space-between!important"
      : "flex-end!important",
    path: {
      fill: theme.palette.warningColor.main,
    },
    [theme.breakpoints.down(1700)]: {
      "&": {
        padding: "0 25px 0 0!important",
      },
    },
  })
);

export const CertificatesEditRowStyles = styled(BasicTableRowStyles)(
  ({ theme }) => ({
    "&:last-child": {
      border: "none",
    },
    "& > div": {
      width: "100%",
      boxSizing: "border-box",
      "&:first-child": {
        paddingLeft: "35px",
        [theme.breakpoints.down(1700)]: {
          "&": {
            padding: "0 0 0 25px!important",
          },
        },
      },
    },
    fieldset: {
      border: "none",
    },
    input: {
      borderRadius: "5px",
    },
  })
);

export const CertificateNameInputStyles = styled(Box)(({ theme }) => ({
  ".MuiFormControl-root": {
    width: "100%",
    input: {
      fontSize: "1.25rem",
      lineHeight: "1.25",
      padding: "8px 9px 7px",
      height: "auto",
    },
  },
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "1em",
  cursor: "pointer"
}));

export const IconContainerHorizontal = styled(Button)(({ theme }) => ({
  cursor: "pointer",
  minWidth: "0px",
  padding: "0px 3px"
}));

export const SearchBarButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "30px",
  width: "10%",
  alignItems: "stretch",
  justifyContent: "space-around"
})
