import { Box, SxProps } from "@mui/material";
import React, { FC } from "react";
import { FulfilledStatusType } from "../../../types/actionItem";
import { theme } from "../../../theme";
import { DoneIcon } from "../../../assets/icons/Icons";

export const FulfilledIconWrapper: FC<{
  children?: React.ReactNode;
  sx?: SxProps;
}> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        padding: "10px",
        position: "relative",
        borderRadius: "50%",
        border: (theme) => `1px solid ${theme.palette.textMain.black}`,
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

const FulfilledStatusIcon: FC<{
  fulfilledStatus: FulfilledStatusType;
  sx?: SxProps;
}> = ({ fulfilledStatus, sx }) => {
  switch (fulfilledStatus) {
    case FulfilledStatusType.NotFulfilled:
      return (
        <FulfilledIconWrapper
          sx={{
            span: {
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "18px",
              height: "2px",
              borderRadius: "2px",
              backgroundColor: theme.palette.textMain.main,
              "&:first-child": {
                transform: "translate(-50%, -50%) rotate(-45deg)",
              },
              "&:last-child": {
                transform: "translate(-50%, -50%) rotate(45deg)",
              },
            },
            ...sx,
          }}
        >
          <span></span>
          <span></span>
        </FulfilledIconWrapper>
      );
    case FulfilledStatusType.Partially:
      return (
        <FulfilledIconWrapper
          sx={{
            span: {
              display: "block",
              position: "absolute",
              top: "0",
              right: "0",
              width: "50%",
              height: "100%",
              backgroundColor: theme.palette.textMain.main,
            },
            ...sx,
          }}
        >
          <span></span>
        </FulfilledIconWrapper>
      );
    case FulfilledStatusType.Fulfilled:
      return (
        <FulfilledIconWrapper
          sx={{
            background: theme.palette.textMain.main,
            svg: {
              position: "absolute",
              width: "75%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            ...sx,
          }}
        >
          <DoneIcon color="#fff" />
        </FulfilledIconWrapper>
      );
    default:
      return <FulfilledIconWrapper sx={{ ...sx }} />;
  }
};

export default FulfilledStatusIcon;
