import GroupsTable from "./GroupsTable/GroupsTable";
import { useSearch } from "../../../hooks/UseSearch";
import GroupsSearchBar from "./GroupsSearchBar";
import { GroupsContentStyles } from "./GroupsBlock.styles";

const GroupsBlock = () => {
  const { searchQuery, handleOnSearch } = useSearch();
  return (
    <GroupsContentStyles>
      <GroupsSearchBar handleOnSearch={handleOnSearch} />
      <GroupsTable searchQuery={searchQuery} />
    </GroupsContentStyles>
  );
};

export default GroupsBlock;
