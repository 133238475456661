import { Box, Popover, Typography } from "@mui/material";
import React, { FC } from "react";
import { usePopover } from "../../../hooks/UsePopover";
import { ChevronDownIcon } from "../../../assets/icons/Icons";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { PaginationType } from "../../../types/common";
import {
  PageBtnStyles,
  PaginationBtnsWrapper,
  PaginationItemsPopoverStyles,
  PaginationStyles,
  pageBtnsSeparator,
} from "../Common.styles";

interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  setPagination: ActionCreatorWithPayload<Partial<PaginationType>>;
}

const Pagination: FC<PaginationProps> = ({
  setPagination,
  totalPages,
  currentPage,
  itemsPerPage,
  totalItems,
}) => {
  const dispatch = useAppDispatch();
  const { anchorRef, handleClose, handleOpen, open } = usePopover();

  const pageNums: number[] = [];
  for (let i = 1; i <= totalPages; i++) pageNums.push(i);
  const pageNumBtns = pageNums.map((item) => {
    const isActive = item === currentPage ? true : false;
    return (
      <PageBtnStyles key={item} isActive={isActive}>
        <button onClick={() => handleOnPageChange(item)}>
          <Typography component="span">{item}</Typography>
        </button>
      </PageBtnStyles>
    );
  });

  let pageBtnsToRender: React.ReactNode = [];

  if (currentPage + 3 < totalPages) {
    pageBtnsToRender = [
      ...pageNumBtns.slice(currentPage - 1, currentPage - 1 + 3),
      pageBtnsSeparator,
      pageNumBtns[pageNumBtns.length - 1],
    ];
  }
  if (currentPage >= totalPages - 3) {
    pageBtnsToRender = [
      ...pageNumBtns.slice(0, 1),
      pageBtnsSeparator,
      ...pageNumBtns.slice(totalPages - 4, totalPages - 1),
    ];
  }
  if (currentPage >= totalPages - 2) {
    pageBtnsToRender = [
      ...pageNumBtns.slice(0, 1),
      pageBtnsSeparator,
      ...pageNumBtns.slice(totalPages - 3, totalPages),
    ];
  }
  if (totalPages <= 5) {
    pageBtnsToRender = pageNumBtns;
  }

  const handleOnItemsCountChange = (itemsPerPage: number) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    dispatch(setPagination({ itemsPerPage, totalPages, currentPage: 1 }));
    handleClose();
  };

  const handleOnPageChange = (pageNum: number) => {
    dispatch(setPagination({ currentPage: pageNum }));
  };

  return (
    <PaginationStyles>
      <Box>
        <button onClick={handleOpen} ref={anchorRef}>
          <Typography component="span"> {itemsPerPage}</Typography>{" "}
          <ChevronDownIcon />
        </button>
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          onClick={() => handleClose()}
          anchorEl={anchorRef.current}
          open={open}
        >
          <PaginationItemsPopoverStyles>
            <button onClick={() => handleOnItemsCountChange(10)}>
              {" "}
              <Typography component="span">10</Typography>
            </button>
            <button onClick={() => handleOnItemsCountChange(25)}>
              {" "}
              <Typography component="span">25</Typography>
            </button>
            <button onClick={() => handleOnItemsCountChange(50)}>
              {" "}
              <Typography component="span">50</Typography>
            </button>
            <button onClick={() => handleOnItemsCountChange(100)}>
              {" "}
              <Typography component="span">100</Typography>
            </button>
          </PaginationItemsPopoverStyles>
        </Popover>
      </Box>
      <PaginationBtnsWrapper>
        {" "}
        <button onClick={() => handleOnPageChange(1)}>
          <KeyboardDoubleArrowLeftIcon />
        </button>
        <button
          onClick={() =>
            handleOnPageChange(currentPage - 1 === 0 ? 1 : currentPage - 1)
          }
        >
          <KeyboardArrowLeftIcon />
        </button>
      </PaginationBtnsWrapper>
      {pageBtnsToRender}
      <PaginationBtnsWrapper>
        {" "}
        <button
          onClick={() =>
            handleOnPageChange(
              currentPage + 1 >= pageNumBtns.length
                ? pageNumBtns.length
                : currentPage + 1
            )
          }
        >
          <KeyboardArrowRightIcon />
        </button>
        <button onClick={() => handleOnPageChange(totalPages)}>
          <KeyboardDoubleArrowRightIcon />
        </button>
      </PaginationBtnsWrapper>
    </PaginationStyles>
  );
};

export default Pagination;
