import { useTranslation } from "react-i18next";
import {
  dayNumbers,
  daysList,
  monthsList,
  periodList,
} from "../../constants";
import { MenuItem } from "@mui/material";

export const useDayNumberItems = () => {
  const { t } = useTranslation();
  return dayNumbers.map((item) => {
    return (
      <MenuItem key={item.strNumber} value={item.number}>
        {t(`dayNumbers.${item.strNumber}`)}
      </MenuItem>
    );
  });
};

export const useDayItems = () => {
  const { t } = useTranslation();
  return daysList.map((item) => (
    <MenuItem key={item} value={item}>
      {t(`days.${item}`)}
    </MenuItem>
  ));
};

export const useMonthListItems = () => {
  const { t } = useTranslation();
  return monthsList.map((item) => (
    <MenuItem key={item} value={item}>
      {t(`monthes.${item}`)}
    </MenuItem>
  ));
};

export const usePeriodItems = () => {
  return periodList.map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  ));
};