import { Box, Popover, styled } from "@mui/material";

export const NotificationLayout = styled(Box)({
    height: "400px",
    width: "400px",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "5px",
    zIndex: "1",
    right: "-5em",
    top: "30px"
});

export const NotificationPopoverStyles = styled(Popover)({
    cursor: "pointer",
    "& > .MuiBox-root": {
        display: "flex",
    },
});

export const SingleNotificationContainer = styled(Box)(
    ({ read }: { read: boolean }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "2px",
        transition: "0.3s",
        backgroundColor: read ? "white" : "lightgrey",
        '&:hover': {
            backgroundColor: 'grey',
        },
    })
)

export const NotificationTitle = styled(Box)({
    fontWeight: "700",
    paddingBottom: "5px"
})

export const NotificationMessage = styled(Box)({
    fontFamily: "serif"
})

export const NotificationDate = styled(Box)({
    fontSize: "smaller",
    fontStyle: "italic",
    color: "grey",
    paddingTop: "5px"
})

export const NotificationBody = styled(Box)({
    width: "80%",
})

export const NotificationHeaderContainer = styled(Box)({
    padding: "10px 0",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "80%",
    margin: "0 auto"
})

export const HeaderMenuPoint = styled(Box)(
    ({ selected }: { selected: boolean }) => ({
        padding: "10px 0",
        textAlign: "center",
        width: "100%",
        transition: "0.3s",
        backgroundColor: selected ? "lightgrey" : "white",
        '&:hover': {
            backgroundColor: 'grey',
        },
    })
)

export const PaginationContainer = styled(Box)({
    textAlign: "center",
    position: "absolute",
    bottom: "0",
    borderTop: "1px solid grey",
    padding: "10px 0px",
    display: "flex",
    right: "0",
    left: "0",
    marginInline: "auto",
    width: "fit-content"
})

export const PaginationElement = styled(Box)({
    width: "30px",
    transition: "0.3s",
    '&:hover': {
        backgroundColor: 'lightgrey',
    },
})







