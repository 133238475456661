import { Box, Modal, styled } from "@mui/material"

interface ModalComponentProps {
    isOpen: boolean,
    onClose: () => void,
    children: React.ReactNode
}

export const ModalComponent = ({ isOpen, onClose, children }: ModalComponentProps) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <ModalContentWrapper>
                <CloseButton onClick={onClose}>X</CloseButton>
                {children}
            </ModalContentWrapper>
        </Modal>
    )
}

const ModalContentWrapper = styled(Box)(({ theme }) => ({
    height: "auto",
    maxHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.backgroundColor.main,
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    border: '2px solid #000',
    textAlign: 'center',
    padding: '5em 0'
}));

const CloseButton = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "0",
    right: "0",
    width: "15px",
    height: "15px",
    margin: "5px",
    backgroundColor: "white",
    color: "lightgrey",
    fontWeight: "bold",
    border: "2px solid lightgrey",
    borderRadius: "10px",
    fontSize: "12px",
    cursor: "pointer",
    '&:hover': {
        color: "grey",
        borderColor: "grey",
        transition: "0.5s"
    },
}));