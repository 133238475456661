import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import SearchWithAutocomplete from "../../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import { useSearch } from "../../../../hooks/UseSearch";

interface DropDownProps {
  initialOption?: string;
  options: string[] | [];
  column: string;
  handleUpdate: (arg0: any, column: string) => void;
  zeroValueAllowed?: boolean;
}

const DropdownSelect: FC<DropDownProps> = (props) => {
  const { options, initialOption, column, handleUpdate, zeroValueAllowed } = props;

  const [filteredItems, setFilteredItems] = useState(options)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { handleOnSearch } = useSearch();


  const liftUpFilteredList = (searchListItems: string[]) => {
    setFilteredItems(searchListItems);
  };

  const handleOnChange = (e: SelectChangeEvent) => {
    handleUpdate(e.target.value, column)
  };

  const handleClose = () => {
    setDropdownOpen(false);
  };

  const handleOpen = () => {
    setDropdownOpen(true);
  };

  let listToSearch = options.map((str, index) => ({ label: str, key: index + 1 }));

  const selectDropdownItems = filteredItems.map((item: string) => (
    <MenuItem key={item} autoFocus={false} value={`${item}`}>
      {item}
    </MenuItem>
  ));

  zeroValueAllowed && selectDropdownItems && selectDropdownItems.unshift(
    <MenuItem key="" value="">
      -
    </MenuItem>
  )

  selectDropdownItems && selectDropdownItems.unshift(
    <SearchWithAutocomplete
      options={listToSearch}
      onSearchHandler={handleOnSearch}
      
      liftUpFilteredList={liftUpFilteredList}
      sx={{
        maxWidth: "499px",
        ".MuiAutocomplete-root": {
          width: "100%",
        },
        ".search-btn": {
          display: "none",
        },
      }}
    />
  )

  return (
    <Box
      sx={{
        width: "100%",
        ".MuiSelect-select": { padding: "9px" },
        fieldset: {
          borderColor: (theme) => theme.palette.border,
        },
      }}
    >
      <Select
        sx={{ width: "100%" }}
        value={initialOption}
        onChange={(e) => handleOnChange(e)}
        IconComponent={ExpandMoreIcon}
        autoFocus={false}
        open={dropdownOpen}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {selectDropdownItems}
      </Select>
    </Box>
  );
};

export default DropdownSelect