import { Box, styled } from "@mui/material";

export const ActionModuleWrapper = styled(Box)(() => ({
  marginBottom: "25px",
}));

export const SearchBarWrapper = styled(Box)(() => ({
  marginTop: "15px",
  marginBottom: "25px",
  marginLeft: "25px",
  marginRight: "25px",
  display: "flex",
  justifyContent: "space-between"
}));

export const ActionModuleManagementStyles = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));
