import { SidebarLinkPathTypes, SidebarLinkTypes } from "../../LinkTypes";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from "@mui/material";
import { ChevronDownIcon } from "../../../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { SimpleSidebarLink } from "../SimpleSidebarLink";
import { ComplexSideBarLinkUI } from "./ComplexSideBarLinkUI";
import { SuperAdminIcon } from "../../../../assets/icons/Icons";


export const SuperAdminComplexLink = ({ activePathname }: { activePathname: string }) => {
    const { t } = useTranslation();
    const path = SidebarLinkPathTypes.superAdminBillingAdministration;
    const isActive = activePathname === path;
    return (
        <ComplexSideBarLinkUI>
            <Accordion>
                <AccordionSummary expandIcon={<ChevronDownIcon />}>
                    <Box sx={{ display: "flex", alignItems: "center", padding: "6px 0" }}>
                        <SuperAdminIcon color="white" />
                        <Typography>{t(SidebarLinkTypes.superAdmin)}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingLeft: "24px!important" }}>
                    <SimpleSidebarLink
                        isActive={isActive}
                        linkName={SidebarLinkTypes.superAdminBillingAdministration}
                        path={path}
                    />
                </AccordionDetails>
            </Accordion>
        </ComplexSideBarLinkUI>
    );
};