import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDesignCompanyLogo, useConfigEditMode } from '../../../store/selectors/configurationSelector';
import { useAppDispatch } from '../../../hooks/StoreHooks';
import { setAppDesignCompanyLogo, setAppDesignCompanyLogoBase64 } from '../../../store/reducers/configurationReducer';
import { theme } from '../../../theme';
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button, Typography } from '@mui/material';

const AppDesignCompanyLogo = () => {
    const { t } = useTranslation();

    let currentCompanyLogoUrl = useAppDesignCompanyLogo()
    let editMode = useConfigEditMode()
    const dispatch = useAppDispatch();

    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const imageRef = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            dispatch(setAppDesignCompanyLogo(URL.createObjectURL(e.target.files[0])));
            const reader = new FileReader();
            reader.onload = onReaderLoaded
            reader.readAsBinaryString(e.target.files[0])
        }
    };

    const onReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        dispatch(setAppDesignCompanyLogoBase64(btoa(binaryString)));
    }

    const displayedLogoUrl = currentCompanyLogoUrl;

    return (
        <Box>
            <Typography variant='h5'>
                {t("configurationPage.companyLogo")}
            </Typography>

            <Box sx={{ mt: 1 }}>
                {displayedLogoUrl
                    ? <img src={displayedLogoUrl} ref={imageRef} width="150px" alt="a" />
                    : t("configurationPage.noCompanyLogo")
                }
            </Box>

            {editMode &&
                <Box sx={{mt: 1}}>
                    <Button onClick={() => handleClick()} variant='outlined'>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            accept="image/gif, image/jpeg, image/png"
                            style={{ display: "none" }}
                            onChange={handleChange} />
                        <UploadIcon sx={{ color: theme.palette.companyMain.main }} />
                        {t("buttons.select")}
                    </Button>
                </Box>
            }
        </Box>
    )
}

export default AppDesignCompanyLogo