import { useEffect, useState } from 'react'
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { useTranslation } from "react-i18next";
import { compareComplexData } from "../../../../common/logic/GroupsUtils";
import { setBillsToEdit, setNewBillMode } from '../../../../store/reducers/billsReducer';
import { Box, TextField } from "@mui/material";
import CellBox from "../../../../components/common/table/CellBox";
import BorderRow from "../../../../components/common/table/BorderRow";
import { theme } from "../../../../App";
import { BillItem, BillType, PaymentMethod, PaymentStatus } from "../../../../types/bills";
import DeleteBtn from "../../../../components/common/table/DeleteBtn";
import SaveBtn from "../../../../components/common/table/SaveBtn";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DropdownSelect from './DropdownSelect'
import TimePickerUIWrap from '../../../../components/common/TimePickerUIWrap';
import { createSuperAdminBillingThunk, deleteSuperAdminAttachmenetFilesThunk, deleteSuperAdminBillingsThunk, editSuperAdminBillingsThunk } from '../../../../store/thunk/superAdminBillingsThunk';
import { DeleteAttachmentFile } from '../../../../types/deleteAttachemntFileType';
import FileUploaderBox from './FileUploaderBox';
import DropDownCompanies from './DropDownCompanies';
import { setProcessingRequest, setResponseMessage, setResponseValue } from '../../../../store/reducers/tableManagementReducer';
import { useNewBillMode } from '../../../../store/selectors/billsSelectors';

interface AddOrEditBillFormProps {
    billItem?: BillItem;
    isNewBillAdd?: boolean;
}

const AddOrEditBillForm = ({
    billItem,
    isNewBillAdd,
}: AddOrEditBillFormProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [billData, setBillData] = useState<BillItem>({
        id: 0,
        companyId: 0,
        companyName: "",
        invoiceNumber: "",
        invoiceDate: "",
        billType: BillType.GeneralLicense,
        paymentMethod: PaymentMethod.OnAccount,
        paymentStatus: PaymentStatus.WaitingForPayment,
        analysisFileId: 0,
        analysisFileDownloadUrl: "",
        invoiceFileId: 0,
        invoiceFileDownloadUrl: ""
    });

    const { id, companyName, invoiceNumber, invoiceDate, analysisFileId, invoiceFileId } = billData;
    const [billDateValue, setBillDateValue] = useState<Dayjs | null>(dayjs(`${invoiceDate}`));

    useEffect(() => {
        billItem && setBillData(billItem);
    }, [billItem]);

    useEffect(() => {
        setBillDateValue(dayjs(`${invoiceDate}`))
    }, [invoiceDate]);

    let handleDropDownChange = (selectedValue: any, column: string) => {
        setBillData({ ...billData, [column]: selectedValue })
        dispatch(setBillsToEdit({ ...billData, [column]: selectedValue }))
    }

    const handleDropDownCompanyId = (selectedCompanyId: number) => {
        setBillData({ ...billData, companyId: Number(selectedCompanyId) })
        dispatch(setBillsToEdit({ ...billData, companyId: Number(selectedCompanyId) }))
    }

    const handleOnAnalysisDelete = () => {
        handleOnFileDelete("Analysis");
    };

    const handleOnInvoiceDelete = () => {
        handleOnFileDelete("Invoice");
    };

    const handleOnFileDelete = (fileType: string) => {
        const deleteAttachmentFileRequest: DeleteAttachmentFile = {
            billingId: id,
            fileType: fileType,
        }

        const response = dispatch(deleteSuperAdminAttachmenetFilesThunk(deleteAttachmentFileRequest));
        response.then((result) => {
            if (result.payload !== undefined) {
                switch (fileType) {
                    case 'Analysis':
                        setBillData({ ...billData, analysisFileId: undefined, analysisFileDownloadUrl: "" });
                        return;
                    case 'Invoice':
                        setBillData({ ...billData, invoiceFileId: undefined, invoiceFileDownloadUrl: "" });
                        return;
                    default:
                        return;
                }
            }
        });
    };

    const handleOnBillDateChange = (newValue: dayjs.Dayjs | null) => {
        setBillDateValue(newValue);
        let newDateString = newValue?.format('YYYY-MM-DDTHH:mm:ss')
        newDateString && setBillData({ ...billData, invoiceDate: newDateString })
        newDateString && dispatch(setBillsToEdit({ ...billData, invoiceDate: newDateString }))
    };

    const handleOnBillSave = async () => {
        dispatch(setProcessingRequest(true))
        let res = await dispatch(createSuperAdminBillingThunk(billData))
        dispatch(setResponseValue(res.type))
        dispatch(setResponseMessage(t("dialogModals.itemCreatedGroup")))
        if (!companyName) return;
    };

    const handleOnBillDelete = () => {
        if (isNewBillAdd) {
            dispatch(setNewBillMode(false))
        }
        dispatch(deleteSuperAdminBillingsThunk(id));
        dispatch(editSuperAdminBillingsThunk());
    };

    const handleOnBlur = (bill?: BillItem) => {
        if (
            !compareComplexData(
                bill ? bill : (billItem as BillItem),
                billData
            ) &&
            !isNewBillAdd
        ) {
            if (bill) {
                dispatch(setBillsToEdit(bill));
            } else {
                dispatch(setBillsToEdit(billData));
            }
        }
    };

    return (
        <Box
            sx={(theme) => ({
                display: "contents",
                "& > div": {
                    height: "114px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    button: {
                        border: "none",
                        outline: "none",
                        padding: "none",
                        cursor: "pointer",
                        svg: {
                            width: "30px",
                            height: "30px",
                        },
                    },
                },
            })}
        >
            <CellBox
                sx={{
                    paddingLeft: "35px",
                    [theme.breakpoints.down(1700)]: {
                        "&": {
                            padding: "0 0 0 25px!important",
                        },
                    },
                }}
            >
                <DropDownCompanies
                    currentSelectionId={billData.companyId}
                    handleUpdate={handleDropDownCompanyId}
                />
            </CellBox>
            <CellBox>
                <TextField
                    sx={() => ({
                        input: {
                            fontSize: "1.25rem",
                            lineHeight: "1.25",
                            padding: "8px 9px 7px",
                            height: "auto",
                        },
                    })}
                    value={invoiceNumber}
                    placeholder="Incentive bill number"
                    onChange={(e) =>
                        setBillData({ ...billData, invoiceNumber: e.target.value })
                    }
                    onBlur={() => {
                        handleOnBlur();
                    }}
                />
            </CellBox>
            <CellBox
                sx={{
                    minWidth: "250px",
                    width: "100%",
                    paddingRight: "30px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <>
                    <span>{t("actionPage.start")}:</span>
                    <TimePickerUIWrap
                        sx={{ maxWidth: "150px", marginLeft: "5px", marginBottom: "0" }}
                    >
                        <DatePicker
                            value={billDateValue}
                            onChange={handleOnBillDateChange}
                            format="ddd DD.MM.YYYY"
                            slots={{
                                openPickerIcon: ExpandMoreIcon,
                            }}
                        />
                    </TimePickerUIWrap>
                </>
            </CellBox>
            <CellBox>
                <DropdownSelect
                    initialOption={billData.billType}
                    options={Object.values(BillType).filter((value) => typeof value === "string") as string[]}
                    column="billType"
                    handleUpdate={handleDropDownChange}
                />
            </CellBox>
            <CellBox>
                <DropdownSelect
                    initialOption={billData.paymentMethod}
                    options={Object.values(PaymentMethod).filter((value) => typeof value === "string") as string[]}
                    column="paymentMethod"
                    handleUpdate={handleDropDownChange}
                />
            </CellBox>
            <CellBox>
                <DropdownSelect
                    initialOption={billData.paymentStatus}
                    options={Object.values(PaymentStatus).filter((value) => typeof value === "string") as string[]}
                    column="paymentStatus"
                    handleUpdate={handleDropDownChange}
                />
            </CellBox>
            <CellBox sx={{ justifyContent: "center!important" }}>
                {analysisFileId === null || analysisFileId === undefined ?
                    <FileUploaderBox
                        billingId={id}
                        fileType="Analysis"
                    /> :
                    <DeleteBtn
                        isDisabled={analysisFileId === null || analysisFileId === undefined}
                        handleOnDelete={handleOnAnalysisDelete}
                        dialogMessage={t("dialogModals.confirmDeleteDoc")}
                    />}
            </CellBox>
            <CellBox sx={{ justifyContent: "center!important" }}>
                {invoiceFileId === null || invoiceFileId === undefined ?
                    <FileUploaderBox
                        billingId={id}
                        fileType="Invoice"
                    /> :
                    <DeleteBtn
                        isDisabled={invoiceFileId === null || invoiceFileId === undefined}
                        handleOnDelete={handleOnInvoiceDelete}
                        dialogMessage={t("dialogModals.confirmDeleteDoc")}
                    />}
            </CellBox>
            <Box
                sx={(theme) => ({
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    paddingRight: "35px",
                    justifyContent: isNewBillAdd ? "space-between" : "flex-end",
                    [theme.breakpoints.down(1700)]: {
                        "&": {
                            padding: "0 25px 0 0!important",
                        },
                    },
                })}
            >
                {isNewBillAdd && (<SaveBtn handleOnClick={handleOnBillSave} />)}
                <DeleteBtn
                    isCreateNewMode={isNewBillAdd}
                    dialogMessage={t("dialogModals.confirmDeleteBill")}
                    handleOnDelete={handleOnBillDelete}
                />
            </Box>
            <BorderRow />
        </Box>

    )
}

export default AddOrEditBillForm