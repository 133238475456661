import React, { useState } from "react";
import ActionFormWrapper from "./ActionFormWrapper";
import RadioButton from "../../../../components/common/RadioButton";
import { useTranslation } from "react-i18next";
import { Container } from "../../../../components/common/Common.styles";
import { ActionFormBoxStyles } from "../ActionModuleCard.styles";

enum RadioGroupStateType {
  Automatic = "automatic",
  Manual = "manualActivation",
}

const DistributionType = () => {
  const { t } = useTranslation();
  const [radioGroupState, setRadioGroupState] = useState<RadioGroupStateType>(
    RadioGroupStateType.Automatic
  );

  const toggleRadioGroupState = () => {
    setRadioGroupState((state) =>
      state === RadioGroupStateType.Automatic
        ? RadioGroupStateType.Manual
        : RadioGroupStateType.Automatic
    );
    // dispatch distribution type
  };

  return (
    <ActionFormBoxStyles>
      <ActionFormWrapper label={t("actionPage.distributionType")}>
        <Container>
          <RadioButton
            label={t(`actionPage.${RadioGroupStateType.Automatic}`)}
            handleOnClick={toggleRadioGroupState}
            isChecked={radioGroupState === RadioGroupStateType.Automatic}
          />
          <RadioButton
            label={t(`actionPage.${RadioGroupStateType.Manual}`)}
            handleOnClick={toggleRadioGroupState}
            isChecked={radioGroupState === RadioGroupStateType.Manual}
          />
        </Container>
      </ActionFormWrapper>
    </ActionFormBoxStyles>
  );
};

export default DistributionType;
