import { Box, Checkbox, Typography } from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import ActionButton from "../../../../../../components/common/table/ActionButton";
import { GroupItem } from "../../../../../../types/groupItem";
import { ActionItem } from "../../../../../../types/actionItem";
import { ActionListItemStyles } from "../../../GroupsBlock.styles";
import { useTranslation } from "react-i18next";
import { ModalComponent } from "../../../../../../components/common/ModalComponent";

interface ActionsListItemProps {
  item: ActionItem;
  isActionSelected: boolean;
  setSelectedActions: Dispatch<SetStateAction<GroupItem>>;
  selectedActions: any[];
}

const ActionsListItem: FC<ActionsListItemProps> = ({
  item,
  isActionSelected,
  setSelectedActions,
  selectedActions
}) => {
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState<boolean>(isActionSelected);
  const [subtypeDuplicateModal, setSubtypeDuplicateModal] = useState<boolean>(false)

  const closeModal = () => {
    setSubtypeDuplicateModal(false)
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let subtypeArray = selectedActions.map(action => {
      return action.subtype
    });
    let isSubtypeDuplicate = subtypeArray.includes(item.subtype)
    if (isSubtypeDuplicate && e.target.checked === true) {
      setSubtypeDuplicateModal(true)
    }

    const isChecked = isSubtypeDuplicate ? false : e.target.checked;
    if (isChecked) {
      setSelectedActions((state: GroupItem) => ({
        ...state,
        actionModules: [...state.actionModules, item],
        actionModuleIds: [...state.actionModuleIds, item.id],
      }));
      setIsSelected(isChecked);
    } else {
      setSelectedActions((state: GroupItem) => ({
        ...state,
        actionModules: state.actionModules.filter(
          (action) => action.id !== item.id
        ),
        actionModuleIds: state.actionModuleIds.filter((id) => id !== item.id),
      }));
      setIsSelected(isChecked);
    }
  };

  return (
    <ActionListItemStyles>
      <Box>
        <ActionButton
          iconName={item.iconName}
          colorHex={item.colorHex ? item.colorHex : ""}
          actionName={item.name}
        />
        <Typography>{item.name}</Typography>
      </Box>
      <Checkbox
        checked={isSelected}
        onChange={onChangeHandler}
        sx={(theme) => ({
          color: theme.palette.companyMain.main,
        })}
        inputProps={{ "aria-label": "controlled" }}
      />

      <ModalComponent isOpen={subtypeDuplicateModal} onClose={closeModal}>
        <Box>{t("dialogModals.subtypeDuplication")} ({item.subtype})</Box>
      </ModalComponent>
    </ActionListItemStyles>
  );
};

export default ActionsListItem;
