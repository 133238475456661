import { FC } from 'react'
import CancelBtn from '../../../../components/common/table/CancelBtn'
import { PopUpButtonWrapper } from './DoucmentPopUp.styles'
import FileUploaderBox from './FileUploaderBox'
import { useTranslation } from "react-i18next";

const PopUpButtons: FC<{ documentExchangeWalletId: number, popUpClose: () => void }> = ({ documentExchangeWalletId, popUpClose }) => {
    const { t } = useTranslation();

    const handleOnCancel = () => {
        popUpClose()
    }

    return (
        <PopUpButtonWrapper>
            <FileUploaderBox
                title={t("buttons.upload")}
                documentExchangeWalletId={documentExchangeWalletId}
            />
            <CancelBtn
                title={t("buttons.cancel")}
                handleOnClick={handleOnCancel}
            />
        </PopUpButtonWrapper>
    )
}

export default PopUpButtons