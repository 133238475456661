import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

interface ProgressDisplayProps {
    processingRequest: boolean,
    labelDisplay?: boolean
}

export const CircularIndeterminate = ({ processingRequest, labelDisplay = false }: ProgressDisplayProps) => {
    return (
        <CircularProgressContainer showAnimation={processingRequest}>
            {labelDisplay
                ?
                <CircularWithValueLabel />
                :
                <CircularProgress />
            }
        </CircularProgressContainer>
    );
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const CircularWithValueLabel = () => {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 300);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return <CircularProgressWithLabel value={progress} />;
}

const CircularProgressContainer = styled(Box)(({ showAnimation }: { showAnimation: boolean }) => ({
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    height: '100vh',
    left: '50%',
    top: '50%',
    width: '100%',
    backdropFilter: 'blur(2px)',
    zIndex: '1',
    display: showAnimation ? "flex" : "none",
    justifyContent: 'center',
    alignItems: 'center'
}))