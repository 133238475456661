import NotificationContent from './NotificationContent';
import { NotificationPopoverStyles } from './Notification.styles';

interface Props {
    anchorEl: any;
    onClose: () => void;
    open: boolean;
}

const NotificationPopover = (props: Props) => {
    const { anchorEl, onClose, open } = props;

    return (
        <NotificationPopoverStyles
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
            }}
            onClose={onClose}
            open={open}
        >
            <NotificationContent />
        </NotificationPopoverStyles>
    )
}

export default NotificationPopover