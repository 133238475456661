import { Box, Button, styled } from "@mui/material";
import { theme } from "../../../theme";

export const TaxFreeBenefitContentWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));

export const TaxFreeBenefitSearchBarStyles = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "30px 39px",
  marginBottom: "25px",
});

export const UserGroupCellStyles = styled(Box)(({ theme }) => ({
  padding: "9px",
  borderRadius: "5px",
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.border.main}`,
  width: "100%",
}));

export const GroupNameBlock = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > .MuiTypography-root": {
    width: "100%",
    maxWidth: "calc(100% - 40px)!important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

export const ExpandGroupBtn = styled(Button)(
  ({ isExpanded }: { isExpanded: boolean }) => ({
    padding: "0",
    minWidth: "0",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    position: "relative",
    border: `1px solid ${theme.palette.textMain.main}`,
    svg: {
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: isExpanded
        ? "translate(-50%, -50%) rotate(180deg)"
        : "translate(-50%, -50%)",
      width: "10px",
      height: "10px",
      transition: "all 0.3s ease",
      marginTop: isExpanded ? "0" : "1px",
    },
  })
);

export const GroupExpandedStyles = styled(Box)(
  ({ isExpanded }: { isExpanded: boolean }) => ({
    height: isExpanded ? "27px" : "0px",
    overflow: "hidden",
    transition: "all 0.3s ease",
    "& > .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "3px 0",
      ".more-actions": {
        width: "30px",
        height: "21px",
        minWidth: "0",
        marginLeft: "27px",
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: "5px",
        display: "flex",
        gap: "2px",
        justifyContent: "center",
        alignItems: "flex-end",
        fontSize: "1rem",
        span: {
          padding: "2px",
          borderRadius: "50%",
          backgroundColor: theme.palette.border.main,
          "&:last-child": {
            background: "none",
          },
        },
      },
    },
  })
);

export const GroupActionsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  ".MuiBox-root": {
    marginRight: "27px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  ".MuiButtonBase-root": {
    width: "21px",
    background: "none",
    border: "none",
    padding: "0",

    svg: {
      width: "auto",
      height: "1rem",
    },
  },
});

export const ActionsPopoverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  width: "100%",
});
