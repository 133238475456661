import { FormEvent, SetStateAction, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { doApiPost } from "../../api/Requests";
import { useSearchParams } from "react-router-dom";
import { authenticationStateHandler } from "../logic/AuthenticationStateHandler";
import { SimpleLink } from "../../components/SimpleLink";
import { useLocale } from "../../hooks/UseLocale";
import { LoginSuccessfulResponse } from "../model/LoginSuccessfulResponse";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { keyValueStorage } from "../../common/logic/KeyValueStore";
import { AuthButton } from "./Authentication.styles";
import AuthFooter from "./AuthFooter";
import BonisoftLogo from "../../common/ui/BonisoftLogo";
import LanguageSwitcher from "../../common/ui/LanguageSwitcher";
import { useTranslation } from "react-i18next";

interface Props {
  navigateToUrlWhenLoggedIn: string;
}

export function loginAndReturnTo(url: string) {
  window.location.href = `/login?returnUrl=${encodeURIComponent(url)}`;
}

export function LoginScreen(props: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const locale = useLocale();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const urlWhenLoggedIn = decodeURIComponent(
    returnUrl ?? props.navigateToUrlWhenLoggedIn
  )

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignIn = async function (event: FormEvent) {
    // We use a form with preventDefault as button click listener. This is needed to
    // leverage the browsers built in save password feature
    event.preventDefault();

    setIsLoading(true);

    const result = await doApiPost<any, LoginSuccessfulResponse>(
      "/api/login",
      { email: email, password: password },
      { handleUnauthenticated: false }
    );

    if (result.success()) {
      let success =
        await authenticationStateHandler.refreshProfileAndSetAuthenticated(
          result.result?.accessToken ?? null
        );
      if (success) {
        keyValueStorage.setObject("userRole", result.result?.role)
        window.location.href = urlWhenLoggedIn;
        return;
      }
    }

    setIsLoading(false);
    setIsError(true);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 10, mb: 10 }}>
      <CssBaseline />
      <LanguageSwitcher />
      <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
        <BonisoftLogo imgRatio={40} />
        <Typography variant="h1" sx={{ alignSelf: "center", mb: 5 }}>
          {t("authentication.login.login")}
        </Typography>
        <Box component="form" onSubmit={handleSignIn} noValidate mb={1}>
          <Stack gap={2}>
            <TextField
              fullWidth
              disabled={false}
              placeholder="E-Mail"
              variant="outlined"
              value={email}
              onChange={(v: { target: { value: SetStateAction<string> } }) =>
                setEmail(v.target.value)
              }
            />
            <OutlinedInput
              required
              fullWidth
              placeholder="Passwort"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(v) => setPassword(v.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {isError && (
              <Alert severity="error">
                {t("authentication.login.loginFailed")}
              </Alert>
            )}
            <AuthButton
              fullWidth
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3 }}
            >
              {isLoading && (
                <>
                  <CircularProgress />
                </>
              )}
              {!isLoading && <>{t("authentication.login.login")}</>}
            </AuthButton>
          </Stack>
        </Box>
        <SimpleLink to={`/${locale}/reset_password/`}>
          <Typography variant="subtitle2">{t("authentication.login.forgotPassword")}</Typography>
        </SimpleLink>
        <SimpleLink to={`/${locale}/register`}>
          <Typography variant="subtitle2">{t("authentication.login.noAccountYet")}</Typography>
        </SimpleLink>
      </Stack>
      <AuthFooter />
    </Container>
  );
}
