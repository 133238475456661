import GroupsTableHeader from "./GroupsTableHeader";
import GroupsTableBody from "./GroupsTableBody";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../../../components/common/Common.styles";

const GroupsTable = (props: { searchQuery: string }) => {
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1230px"
        columnsTemplate="2.57fr 3.05fr 1.17fr 1fr 1fr 175px"
      >
        <GroupsTableHeader />
        <GroupsTableBody searchQuery={props.searchQuery} />
      </TableWrapper>
    </MainContentWrapper>
  );
};

export default GroupsTable;
