import React, { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CompanyInfoTable from "./CompanyInfoTable";
import { companyManagementApi } from "../../api/companyManagementApi";
import { CompanyManagementType } from "../../types/companyManagementTypes";
import {
  CompanyManagementMainContentStyles,
  SEPAInstructionsStyles,
} from "./CompanyManagement.styles";

const CompanyManagement = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<CompanyManagementType | null>(
    null
  );
  const [error, setError] = useState<string>("");
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const company =
          (await companyManagementApi.getCompany()) as CompanyManagementType;
        if (!company)
          throw new Error("Something whent wrong, please try again");
        else {
          setTableData(company);
        }
      } catch (error) {
        setError((error as Error).message as string);
      }
    };
    fetchCompany();
  }, []);
  if (tableData) {
    return (
      <>
        <PageTitle
          title={t("companyManagementPage.title")}
        />
        <CompanyManagementMainContentStyles>
          <CompanyInfoTable
            displayCanNotBeProcessed={true}
            companyTableInfo={tableData}
            title={t("companyManagementPage.companyInfoTable.billAddress")}
          />
          <CompanyInfoTable
            displayCanNotBeProcessed={false}
            companyTableInfo={tableData}
            title={t("companyManagementPage.companyInfoTable.deliveryAddress")}
          />
          <SEPAInstructionsStyles>
            <Typography>{t("companyManagementPage.companyInfoTable.sepaLinkText")}</Typography>
            <Typography>
              {t("companyManagementPage.companyInfoTable.sepaText1")}{" "}
              <a
                href="https://bonisoft.de/anleitung-sepa-mandat/"
                target="_blank"
                rel="noreferrer">
                {t("companyManagementPage.companyInfoTable.sepaLinkText")}
              </a>{" "}
              {t("companyManagementPage.companyInfoTable.sepaText2")}
            </Typography>
          </SEPAInstructionsStyles>
        </CompanyManagementMainContentStyles>
      </>
    );
  }
  return <Box>{error}</Box>;
};

export default CompanyManagement;
