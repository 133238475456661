import { Box, SxProps } from "@mui/material";
import React, { FC } from "react";
import { theme } from "../../../theme";
import { firstColumnStyles, lastColumnStyles } from "../Common.styles";
interface CellBoxProps {
  sx?: SxProps;
  firstCol?: boolean;
  lastCol?: boolean;
  contentPosition?: string;
  contentDirection?: "column" | "row";
  children: JSX.Element;
}

const CellBox: FC<CellBoxProps> = ({
  sx,
  children,
  firstCol,
  lastCol,
  contentPosition,
  contentDirection = "row",
}) => {
  return (
    <Box
      sx={{
        ...(firstCol && firstColumnStyles(theme)),
        ...(lastCol && lastColumnStyles(theme)),
        height: "100%",
        display: "flex",
        flexDirection: contentDirection,
        alignItems: "center",
        justifyContent: contentPosition
          ? `${contentPosition}!important`
          : "center",
        minWidth: "0",
        ...sx,
        "& p ": {
          maxWidth: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default CellBox;
