import React, { FC, useRef, useState } from "react";
import {
  ApplyDocBtn,
  DocActionBtnsBlock,
  ModalContent,
  UploadDocArea,
  UploadDocMenu,
} from "../../ActionsBlock.styles";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Container } from "../../../../components/common/Common.styles";
import DeleteBtn from "../../../../components/common/table/DeleteBtn";
import { useDialog } from "../../../../hooks/UseDialog";
import * as XLSX from "xlsx";
import DocumentPreviewModal from "../../../../components/common/DocumentPreviewModal";
import AlertDialog from "../../../../components/common/AlertDialog";
import { useTranslation } from "react-i18next";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import CheckIcon from "@mui/icons-material/Check";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { ImportEmployeeScoresForActionModuleThunk, ImportEmployeeScoresForSubtypeThunk } from "../../../../store/thunk/actionModuleManagementThunk";
import { setExcelEmployeeScoresParsedData } from "../../../../store/reducers/actionModuleManagementReducer";
import { Close } from "@mui/icons-material";
import red from "@mui/material/colors/red";
import { ModalComponent } from "../../../../components/common/ModalComponent";

const UploadActionDocWidget: FC<{
  actionModuleId?: number;
  actionModuleSubtype?: string;
  uploadPopupIsActive: boolean;
  setUploadPopupIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ actionModuleId, actionModuleSubtype, uploadPopupIsActive, setUploadPopupIsActive }) => {
  const [file, setFile] = useState<any>(false);
  const [arrayBuffer, setArrayBuffer] = useState<any>(null);
  const [parsedData, setParsedData] = useState<any>(null);
  const [uploadFinishedModal, setUploadFinishedModal] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dialog = useDialog();
  const xlsxDataModal = useDialog();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const elementId = `${actionModuleId ?? actionModuleSubtype}_upload_doc`;
  const inputId = `${actionModuleId ?? actionModuleSubtype}_upload_doc_input`;

  const closeUploadFinishedModal = () => {
    setUploadFinishedModal(false);
    handleOnDocDelete();
  };

  const handleOnApplyDoc = async () => {
    if (arrayBuffer !== null) {
      const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
      const workbookName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[workbookName];
      const data = XLSX.utils.sheet_to_json(worksheet, { blankrows: false });
      dispatch(setExcelEmployeeScoresParsedData(data));

      if (actionModuleId) {
        const resultStatus = await dispatch(ImportEmployeeScoresForActionModuleThunk(actionModuleId));

        if (resultStatus.payload === 200) {
          setUploadFinishedModal(true)
        }
      }
      else if (actionModuleSubtype) {
        const resultStatus = await dispatch(ImportEmployeeScoresForSubtypeThunk(actionModuleSubtype));

        if (resultStatus.payload === 200) {
          setUploadFinishedModal(true)
        }
      }

      setUploadPopupIsActive(false)
    }
  };

  const handleOnSelectDoc = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.name.match(/^.*\.(xls|xlsx|csv)$/)) {
        setFile(file);
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          setArrayBuffer(e.target!.result);
        };
      } else {
        dialog.handleDialogOpen();
      }
    }
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleOnDocDelete = () => {
    setFile(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleOnModalClose = () => {
    setUploadPopupIsActive(false);
    setFile(null);
  }

  const handleOnPreviewDoc = () => {
    if (arrayBuffer !== null) {
      const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
      const workbookName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[workbookName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: ['EmployeeNumber', 'Score'], blankrows: false });
      setParsedData(data);
      xlsxDataModal.handleDialogOpen();
    }
  };

  return (
    <>
      <DocumentPreviewModal dialog={xlsxDataModal} data={parsedData} />
      <AlertDialog
        dialog={dialog}
        alertMessage={t("dialogModals.incorrectFileType")}
      />
      <UploadDocMenu
        id={elementId}
        uploadPopupIsActive={uploadPopupIsActive}>
        <Box>
          <Box>
            <Container>
              <CreateNewFolderOutlinedIcon />
              <Typography>{t("buttons.upload")}</Typography>
              <Container>
                <IconButton onClick={handleOnModalClose}>
                  <Close style={{ color: red[700] }} />
                </IconButton>
              </Container>
            </Container>
            <form action="">
              <label htmlFor={inputId}>
                <input
                  ref={inputRef}
                  type="file"
                  id={inputId}
                  onChange={handleOnSelectDoc}
                />
                <UploadDocArea isFile={!!file}>
                  <UploadIcon fontSize="large" />
                  {file ? (
                    <Container hPosition="flex-end">
                      <Tooltip title={file.name}>
                        <Typography sx={{ fontSize: "12px" }}>
                          {file.name.substring(0, 10)}...
                        </Typography>
                      </Tooltip>
                      <Box>
                        <CheckIcon />
                      </Box>
                    </Container>
                  ) : (
                    <Typography>XLS, XLSX & CSV</Typography>
                  )}
                </UploadDocArea>
              </label>
            </form>
            <DocActionBtnsBlock isFile={!!file}>
              <Button disabled={!file} onClick={handleOnPreviewDoc}>
                <VisibilityIcon />
              </Button>
              <DeleteBtn
                isDisabled={!file}
                handleOnDelete={handleOnDocDelete}
                dialogMessage={t("dialogModals.confirmDeleteDoc")}
              />
            </DocActionBtnsBlock>
          </Box>
          <ApplyDocBtn onClick={handleOnApplyDoc} disabled={!file}>
            {t("buttons.upload")}
          </ApplyDocBtn>
        </Box>
      </UploadDocMenu>

      <ModalComponent isOpen={uploadFinishedModal} onClose={closeUploadFinishedModal}>
        <ModalContent>{t(`actionModulesPage.actionModules.file`)} <b>{file?.name}</b> {t(`actionModulesPage.actionModules.uploaded`)}</ModalContent>
      </ModalComponent>
    </>
  );
};

export default UploadActionDocWidget;
