import { Box } from "@mui/material";
import { FC, useEffect } from "react";
import BillsTableHeader from "./BillsTableHeader";
import BillsTableBody from "./BillsTableBody";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchCompanyList } from "../../../store/thunk/companyBillingsThunk";

const BillsTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        await dispatch(fetchCompanyList());
      } catch (error) {
        console.log("error")
      }
    };
    fetchCompanies();
  }, []);
  
  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflowX: "auto",
        background: (theme) => theme.palette.backgroundColor.main,
      }}
    >
      <Box
        sx={{
          minWidth: "1330px",
          width: "100%",
          display: "grid",
          columnGap: "10px",
          gridTemplateColumns: "3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gridTemplateRows: "auto",
        }}
      >
        <BillsTableHeader />
        <BillsTableBody searchQuery={searchQuery} />
      </Box>
    </Box>
  );
};

export default BillsTable;
