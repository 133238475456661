import { Button, Typography } from "@mui/material";
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';

interface NewBtnProps {
  isActive: boolean;
  title?: string;
  hoverText?: string;
  handleOnClick: () => void;
  adding?: boolean
}

const NewBtn: FC<NewBtnProps> = ({ title, hoverText, handleOnClick, adding = true }) => {
  return (
    <Button
      onClick={handleOnClick}
      sx={(theme) => ({
        width: "fit-content",
        minWidth: "30px",
        padding: "0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "140px",
        textAlign: "center",
        fontSize: "1.125rem",
        fontWeight: "700",
        border: `1px solid ${theme.palette.companyMain.main}`,
        backgroundColor: theme.palette.companyMain.main,
        borderRadius: "5px",
        color: theme.palette.backgroundColor.main,
        textTransform: "none",
        lineHeight: "1.2222",
        alignContent: "center",
        "&:hover": {
          backgroundColor: theme.palette.companyMain.hover,
        },
        ".MuiTypography-root": {
          width: "100%",
          maxWidth: "100%!important",
          lineHeight: "1"
        },
      })}
    >
      <Tooltip title={hoverText}>
        <Typography>{title ? title :
          adding ? <AddIcon sx={{ fontSize: "1em" }} /> : <RemoveIcon sx={{ fontSize: "1em" }} />}
        </Typography>
      </Tooltip>
    </Button>
  );
};

export default NewBtn;
