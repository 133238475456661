import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@mui/material";
import CellBox from "../../../../components/common/table/CellBox";
import BorderRow from "../../../../components/common/table/BorderRow";
import { CertificatesPageRowStyles, IconContainerHorizontal } from "../CertificatesPage.styles";
import { DocumentItem } from "../../../../types/certificates";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { deleteCertificateDocumentThunk } from "../../../../store/thunk/certificatesThunk";
import DeleteBtn from "../../../../components/common/table/DeleteBtn";
import TooltipTypography from "../../../../components/common/TooltipTypography";

export interface CertificateDocumentDeleteData {
  documentExchangeWalletId: number
  documentId: number
}

const PopUpTableRow: FC<{ documentItemData: DocumentItem, documentExchangeWalletId: number }> = ({ documentItemData, documentExchangeWalletId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id, fileName, downloadUrl, ownerName, uploadTimestamp } = documentItemData;

  const handleOnDelete = () => {
    let certificateDocumentDeleteData: CertificateDocumentDeleteData = {
      documentExchangeWalletId: documentExchangeWalletId,
      documentId: id
    }
    dispatch(deleteCertificateDocumentThunk(certificateDocumentDeleteData));
  }

  const handleOnDownload = (downloadUrl: string) => {
    window.location.href = downloadUrl;
  };

  return (
    <CertificatesPageRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={fileName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={new Date(uploadTimestamp)
            .toLocaleDateString(undefined, {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .split("/")
            .join(".")}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={ownerName}
        />
      </CellBox>
      <CellBox lastCol={true}>
        <Typography>
          <Tooltip title="Download">
            <IconContainerHorizontal onClick={() => handleOnDownload(downloadUrl)}>
              <DownloadIcon
                sx={{ color: "#000", minWidth: "0px" }}
              />
            </IconContainerHorizontal>
          </Tooltip>
          <Tooltip title="Delete">
            <IconContainerHorizontal>
              <DeleteBtn
                dialogMessage={t("dialogModals.confirmDeleteGroup")}
                handleOnDelete={handleOnDelete}
              />
            </IconContainerHorizontal>
          </Tooltip>
        </Typography>
      </CellBox>
      <BorderRow />
    </CertificatesPageRowStyles>
  );
};

export default PopUpTableRow;
