import React from 'react'
import { PaginationContainer, PaginationElement } from './Notification.styles'
import { useUnreadNotificationAmount } from '../../../../store/selectors/notificationSelector';

const NotificationPagination = ({ paginationSelected, setPagination }) => {
    const unreadNotificationAmount = useUnreadNotificationAmount();
    const pageCounter = Math.floor(unreadNotificationAmount / 10) + 1

    return (
        <PaginationContainer>
            <PaginationElement onClick={() => setPagination(1)}> &lt;&lt; </PaginationElement>
            <PaginationElement onClick={() => setPagination(Math.max(paginationSelected - 1, 1))}> &lt; </PaginationElement>
            <PaginationElement> {paginationSelected}/{pageCounter} </PaginationElement>
            <PaginationElement onClick={() => setPagination(Math.min(paginationSelected + 1), pageCounter)}> &gt; </PaginationElement>
            <PaginationElement onClick={() => setPagination(pageCounter)}> &gt;&gt; </PaginationElement>
        </PaginationContainer>
    )
}

export default NotificationPagination