import { FC, useState } from 'react'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { IconContainer } from '../CertificatesPage.styles';
import Modal from '@mui/material/Modal';
import { PopUpContentWrapper } from './DoucmentPopUp.styles';
import PopUpTable from './PopUpTable';
import { DocumentItem } from '../../../../types/certificates';
import PopUpButtons from './PopUpButtons';
import { Tooltip } from '@mui/material';

const DocumentPopUp: FC<{ documentExchangeWalletId: number, documentData: DocumentItem[] }> = ({ documentExchangeWalletId, documentData }) => {
  const [open, setOpen] = useState(false);

  const popUpOpen = () => {
    setOpen(true);
  };

  const popUpClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Open pop up">
        <IconContainer onClick={popUpOpen}>
          <LibraryBooksIcon />
        </IconContainer>
      </Tooltip>
      <Modal
        open={open}
        onClose={popUpClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <PopUpContentWrapper>
          <PopUpTable documentData={documentData} documentExchangeWalletId={documentExchangeWalletId}/>
          <PopUpButtons documentExchangeWalletId={documentExchangeWalletId} popUpClose={popUpClose}/>
        </PopUpContentWrapper>
      </Modal>
    </div>
  )
}

export default DocumentPopUp