import { SortingDataType, TableSortDirection } from "../../../common/logic/TableSortUtils";
import TableColumnHeader from "../../../components/common/table/TableColumnHeader";
import BorderRow from "../../../components/common/table/BorderRow";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { sortCertificates } from "../../../store/reducers/certificatesReducer";
import { TableHeaderWrapper } from "../../../components/common/Common.styles";

const CertificatesTableHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    dispatch(sortCertificates({ dataField, dataType, direction }));
  };
  return (
    <TableHeaderWrapper>
      <TableColumnHeader
        dataField="documentName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.documentName")}
        firstCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="employeeFullName"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.employeeFullName")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="endDate"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.endDate")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="notificationDate"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.notificationDate")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="documents"
        dataType="string"
        isSorted={false}
        label={t("tableColumnHeaders.documents")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="employeeAccountStatus"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.status")}
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default CertificatesTableHeader;
