import {
  SortingDataType,
  TableSortDirection,
} from "../../common/logic/TableSortUtils";
import TableColumnHeader from "../../components/common/table/TableColumnHeader";
import BorderRow from "../../components/common/table/BorderRow";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { sortBills } from "../../store/reducers/billsReducer";
import { TableHeaderWrapper } from "../../components/common/Common.styles";

const BillsTableHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    dispatch(sortBills({ dataField, dataType, direction }));
  };
  return (
    <TableHeaderWrapper>
      <TableColumnHeader
        dataField="invoiceNumber"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceNumber")}
        firstCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="invoiceDate"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceDate")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="type"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.billType")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="paymentMethod"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.paymentMethod")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="status"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.status")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="analysis"
        dataType="string"
        isSorted={false}
        label={t("tableColumnHeaders.analysis")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="invoice"
        dataType="string"
        isSorted={false}
        label={t("tableColumnHeaders.invoice")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default BillsTableHeader;
