import { AppDesignSettingsWrapper } from './AppDesign.styles'
import AppDesignCompanyLogo from './AppDesignCompanyLogo'
import AppDesignCompanyColor from './AppDesignCompanyColor'
import { Button } from '@mui/material'
import { useConfigEditMode } from '../../../store/selectors/configurationSelector'
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { setAppDesignAccentColor, setAppDesignCompanyLogo, setAppDesignCompanyLogoBase64 } from '../../../store/reducers/configurationReducer'
import { useTranslation } from 'react-i18next'

const AppDesignSettings = () => {
  const { t } = useTranslation();
  let editMode = useConfigEditMode();
  const dispatch = useAppDispatch();

  function reset() {
    dispatch(setAppDesignAccentColor(null));
    dispatch(setAppDesignCompanyLogo(null));
    dispatch(setAppDesignCompanyLogoBase64(null));
  }

  return (
    <AppDesignSettingsWrapper>
      <AppDesignCompanyLogo />
      <AppDesignCompanyColor />

      {editMode && <Button variant='outlined' sx={{ mt: 5 }} onClick={reset}>{t("buttons.reset")}</Button>}
    </AppDesignSettingsWrapper>
  )
}

export default AppDesignSettings