import TableColumnHeader from "../../../../components/common/table/TableColumnHeader";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { sortGroupsTable } from "../../../../store/reducers/groupsReducer";
import { SortingDataType, TableSortDirection } from "../../../../common/logic/TableSortUtils";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../../components/common/table/BorderRow";
import { TableHeaderWrapper } from "../../../../components/common/Common.styles";

const GroupsTableHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    dispatch(sortGroupsTable({ dataField, direction, dataType }));
  };

  return (
    <TableHeaderWrapper>
      <TableColumnHeader
        dataField="name"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.group")}
        handleOnTableSort={handleOnTableSort}
        firstCol={true}
      />
      <TableColumnHeader
        isSorted={true}
        dataType="number"
        dataField={"actionModules"}
        label={t("tableColumnHeaders.actionModules")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        contentPosition="center"
        isSorted={true}
        label={t("tableColumnHeaders.maxPoints")}
        dataType="number"
        dataField="maxPoints"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        contentPosition="center"
        isSorted={true}
        label={t("tableColumnHeaders.staff")}
        dataType="number"
        dataField="staff"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        contentPosition="center"
        isSorted={true}
        label={t("tableColumnHeaders.status")}
        dataField="status"
        dataType="string"
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default GroupsTableHeader;
