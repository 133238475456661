import { Box } from "@mui/material";
import ActionFormWrapper from "./ActionFormWrapper";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { SyntheticEvent, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButton from "../../../../components/common/RadioButton";
import {
  dateFormatter,
  increaseDateByOneDay,
  validateDataField,
  validateStartEndDates,
} from "../../utils";
import { useTranslation } from "react-i18next";
import {
  ActionFormBoxStyles,
  EndAfterContainer,
  EndByTimePickerUI,
  RangeOfRecurrenceLeftBox,
  RangeOfRecurrenceRightBox,
} from "../ActionModuleCard.styles";
import {
  BasicTimePicker,
  Container,
} from "../../../../components/common/Common.styles";

enum RadioGroupStateType {
  EndBy = "endBy",
  EndAfter = "endAfter",
  NoEndDate = "noEndDate",
}

const RangeOfRecurrenceForm = () => {
  const currentDate = dateFormatter();
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>(
    dayjs(`${currentDate}T00:00`)
  );
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>(
    increaseDateByOneDay(currentDate)
  );
  const [occurrence, setOcurrence] = useState<string>("1");
  const [error, setError] = useState<string>("");
  const [incorrectDateError, setIncorrectDateError] = useState<string>("");
  const [radioGroupState, setRadioGroupState] = useState<RadioGroupStateType>(
    RadioGroupStateType.EndBy
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (radioGroupState === RadioGroupStateType.NoEndDate) {
      console.log({ noEndDate: true });
      //dispatch noend date with true
    }
    if (radioGroupState === RadioGroupStateType.EndBy) {
      //   console.log(endDateValue?.format());

      console.log(Date.parse(startDateValue!.format()));
      console.log(Date.parse(endDateValue!.format()));
      //dispatch(setEndtDate(newValue.format() as string));
    }
    if (radioGroupState === RadioGroupStateType.EndAfter) {
      console.log(occurrence);
      //dispatch(setOccurence(value as number))
    }
  }, [radioGroupState]);

  //   console.log(dateValue?.format());

  const handleOnStartDateChange = (newValue: dayjs.Dayjs | null) => {
    setStartDateValue(newValue);

    //dispatch(setStartDate(newValue.format() as string));
  };

  const handleOnEndDateChange = (newValue: dayjs.Dayjs | null) => {
    setEndDateValue(newValue);
    const error = validateStartEndDates(
      startDateValue!.format(),
      newValue!.format()
    );
    if (error) {
      setIncorrectDateError(error);
    } else {
      setIncorrectDateError("");
      //dispatch(setEndtDate(newValue.format() as string));
    }
  };

  const handleOnOcurrensBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      1000
    );
    if (value === "error") setError(value);
    else {
      setError("");
      //dispatch(setOccurence(value as number))
    }
  };

  return (
    <ActionFormBoxStyles>
      <ActionFormWrapper label={t("actionPage.recurrenceRange")}>
        <Container vPosition="flex-start">
          <RangeOfRecurrenceLeftBox>
            <span>{t("actionPage.start")}:</span>
            <BasicTimePicker>
              <DatePicker
                value={startDateValue}
                onChange={handleOnStartDateChange}
                format="ddd DD.MM.YYYY"
                slots={{
                  openPickerIcon: ExpandMoreIcon,
                }}
              />
            </BasicTimePicker>
          </RangeOfRecurrenceLeftBox>
          <RangeOfRecurrenceRightBox>
            <Container>
              <RadioButton
                label={t(`actionPage.${RadioGroupStateType.EndBy}`)}
                isChecked={RadioGroupStateType.EndBy === radioGroupState}
                handleOnClick={() =>
                  setRadioGroupState(RadioGroupStateType.EndBy)
                }
              />
              <EndByTimePickerUI error={incorrectDateError}>
                <DatePicker
                  value={endDateValue}
                  minDate={
                    increaseDateByOneDay(startDateValue!.format()) as Dayjs
                  }
                  disabled={radioGroupState !== RadioGroupStateType.EndBy}
                  onChange={handleOnEndDateChange}
                  format="ddd DD.MM.YYYY"
                  slots={{
                    openPickerIcon: ExpandMoreIcon,
                  }}
                />
              </EndByTimePickerUI>
            </Container>
            <EndAfterContainer error={error}>
              <RadioButton
                label={t(`actionPage.${RadioGroupStateType.EndAfter}`)}
                isChecked={RadioGroupStateType.EndAfter === radioGroupState}
                handleOnClick={() =>
                  setRadioGroupState(RadioGroupStateType.EndAfter)
                }
              />
              <Box>
                <input
                  type="text"
                  disabled={radioGroupState !== RadioGroupStateType.EndAfter}
                  onChange={(e) => setOcurrence(e.target.value)}
                  onBlur={handleOnOcurrensBlur}
                  value={occurrence}
                />
              </Box>
              <span>{t("actionPage.ocurrence")}</span>
            </EndAfterContainer>
            <RadioButton
              label={t(`actionPage.${RadioGroupStateType.NoEndDate}`)}
              isChecked={RadioGroupStateType.NoEndDate === radioGroupState}
              handleOnClick={() =>
                setRadioGroupState(RadioGroupStateType.NoEndDate)
              }
            />
          </RangeOfRecurrenceRightBox>
        </Container>
      </ActionFormWrapper>
    </ActionFormBoxStyles>
  );
};

export default RangeOfRecurrenceForm;
