import { GroupItem } from "../types/groupItem";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";

export const groupsApi = {
  getGroups: (): Promise<GroupItem[] | null> =>
    doApiGet("/api/companies/current/taxbenefitgroups")
      .then(res => res.result as GroupItem[])
      .catch((error) => {
        console.log(error);
        return null
      }),

  createGroup: (groupData: Partial<GroupItem>) =>
    doApiPost("/api/companies/current/groups", groupData)
      .then(res => res)
      .catch(error => console.log(error)),

  editGroup: (id: number, groupData: Partial<GroupItem>) =>
    doApiPatch(`/api/companies/current/groups/${id}`, groupData)
      .then(res => res)
      .catch(error => console.log(error)),

  deleteGroup: (id: number) =>
    doApiDelete(`/api/companies/current/groups/${id}`),
};
