import {BehaviorSubject, map} from "rxjs";
import {keyValueStorage} from "../../common/logic/KeyValueStore";
import {Profile} from "../model/Profile";
import {doApiGet} from "../../api/Requests";


interface AuthenticationState {
    isAuthenticated: boolean,
    profile: Profile | null,
    accessToken: string | null,
}

class AuthenticationStateHandler {
    private authenticationStateSubject = new BehaviorSubject<AuthenticationState>(
        this.readAuthenticationStateFromStorage()
    );

    get isAuthenticatedObservable() {
        return this.authenticationStateSubject.asObservable()
            .pipe(map(s => s.isAuthenticated))
    }

    get isAuthenticated(): boolean {
        return this.authenticationStateSubject.value.isAuthenticated
    }

    get profileObservable() {
        return this.authenticationStateSubject.asObservable()
            .pipe(map(s => s.profile))
    }

    get profile(): Profile | null {
        return this.authenticationStateSubject.value.profile
    }

    get accessToken(): string | null {
        return this.authenticationStateSubject.value.accessToken
    }

    setNotAuthenticated() {
        let authenticationState = {
            isAuthenticated: false,
            profile: null,
            accessToken: null
        } as AuthenticationState

        this.authenticationStateSubject.next(authenticationState);
        this.writeAuthenticationStateToStorage(authenticationState);
    }

    setAuthenticated(profile: Profile, accessToken: string | null) {
        let authenticationState = {
            isAuthenticated: true,
            profile: profile,
            accessToken: accessToken,
        } as AuthenticationState

        this.authenticationStateSubject.next(authenticationState);
        this.writeAuthenticationStateToStorage(authenticationState);
    }

    async refreshProfileAndSetAuthenticated(
        accessToken: string | null
    ): Promise<boolean> {
        const meResult = await doApiGet<Profile>(
            "/api/me",
            false,
            true,
            {accessToken: accessToken ?? undefined}
        )
        const meResultData = meResult.getResultOrNull()

        if (meResult.success() && meResultData != null) {
            authenticationStateHandler.setAuthenticated(
                meResultData,
                accessToken
            )
            return true
        }

        return false
    }

    private readAuthenticationStateFromStorage(): AuthenticationState {
        return keyValueStorage.getObject<AuthenticationState>("authenticationState", {
            profile: null,
            isAuthenticated: false,
            accessToken: null,
        });
    }

    private writeAuthenticationStateToStorage(state: AuthenticationState) {
        keyValueStorage.setObject("authenticationState", state)
    }
}

export const authenticationStateHandler = new AuthenticationStateHandler();
