import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { superAdminBillsApi } from "../../api/superAdminBillsApi";
import { BillItem } from "../../types/bills";
import { saveBillsTable, createBill, setBillsEditMode } from "../reducers/billsReducer";
import { FileUploadData } from "../../pages/superAdminArea/BillingPage/edit/FileUploaderBox";
import { DeleteAttachmentFile } from "../../types/deleteAttachemntFileType";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchSuperAdminBillingsThunk = createAsyncThunk(
  "superAdminBillings/fetchBillings",
  async (_, thunkApi) => {
    try {
      const bills = await superAdminBillsApi.getSuperAdminBills();
      thunkApi.dispatch(setProcessingRequest(false))
      return bills ? (bills as BillItem[]) : [];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const createSuperAdminBillingThunk = createAsyncThunk(
  "superAdminBillings/createBill",
  async (billData: BillItem, thunkApi) => {
    const newBillData: BillItem = billData

    thunkApi.dispatch(createBill(newBillData));
    superAdminBillsApi.createBill(newBillData)
      .then(async () => {
        await thunkApi.dispatch(fetchSuperAdminBillingsThunk())
        thunkApi.dispatch(setProcessingRequest(false))
      })
      .catch(error => console.log(error))
  }
);

export const editSuperAdminBillingsThunk = createAsyncThunk(
  "superAdminBillings/editBillings",
  (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const billsToEdit = state.bills.billsToEdit;
    const billsToEditRequests: any[] = billsToEdit.map((bill) =>
      superAdminBillsApi.editBills(bill.id, {
        companyId: bill.companyId,
        companyName: bill.companyName,
        invoiceNumber: bill.invoiceNumber,
        invoiceDate: bill.invoiceDate,
        billType: bill.billType,
        paymentMethod: bill.paymentMethod,
        paymentStatus: bill.paymentStatus,
        analysisFileId: bill.analysisFileId,
        invoiceFileId: bill.invoiceFileId,
      })
    );

    Promise.all(billsToEditRequests)
      .then(async (res) => {
        thunkApi.dispatch(thunkApi.dispatch(saveBillsTable()))
        thunkApi.dispatch(setBillsEditMode(false));
        thunkApi.dispatch(setProcessingRequest(false))
        await thunkApi.dispatch(fetchSuperAdminBillingsThunk())
        return res
      })
      .catch(error => console.log(error))
  }
);

export const billingFileUploadThunk = createAsyncThunk(
  "superAdmingBillings/uploadFile",
  (fileDataToSend: FileUploadData, thunkApi) => {
    superAdminBillsApi.uploadBillFile(fileDataToSend.billingId, fileDataToSend.fileType, fileDataToSend.fileContent)
      .then(res => {
        thunkApi.dispatch(fetchSuperAdminBillingsThunk())
        return res
      })
      .catch(error => console.log(error))
  }
);

export const deleteSuperAdminBillingsThunk = createAsyncThunk(
  "superAdminBillings/deleteBillings",
  (billingId: number, thunkApi) => {
    superAdminBillsApi.deleteBills(billingId)
      .then(res => {
        thunkApi.dispatch(fetchSuperAdminBillingsThunk())
        return res
      })
      .catch(error => console.log(error))
  }
);

export const deleteSuperAdminAttachmenetFilesThunk = createAsyncThunk(
  "superAdminBillings/deleteBillingsAttachmentFiles",
  (deleteAttachmentFileRequest: DeleteAttachmentFile, thunkApi) => {
    superAdminBillsApi.deleteAttachmentFiles(deleteAttachmentFileRequest)
      .then(res => {
        thunkApi.dispatch(fetchSuperAdminBillingsThunk())
        return res
      })
      .catch(error => console.log(error))
  }
);
