import { FC } from 'react'
import { Box } from '@mui/material';

interface PasswordStrengthProps {
    strength: number;
}

export const passwordStrengthTest = (value: string) => {
    let strength = 0;
    if (value.length >= 6) {
        let arrayTest =
            [/[0-9]/, /[a-z]/, /[A-Z]/, /[^0-9a-zA-Z]/];
        arrayTest.forEach((item) => {
            if (item.test(value)) {
                strength += 1;
            }
        });
    }
    return strength
}

const PasswordStrength: FC<PasswordStrengthProps> = ({ strength }) => {
    let widthPower =
        ["5%", "25%", "50%", "75%", "100%"];
    let colorPower =
        ["#D73F40", "#DC6551", "#F2B84F", "#FFEE47", "#BDE952"];

    let style = {
        width: widthPower[strength],
        height: 10,
        borderRadius: 1,
        bgcolor: colorPower[strength],
        '&:hover': {
            bgcolor: 'primary.dark',
        },
    }
    return (
        <div>
            <Box sx={style} />
        </div>
    )
}

export default PasswordStrength