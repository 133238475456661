import { FC, useEffect, useState } from "react";
import RecurrenceDaily from "./RecurrenceDaily";
import RecurrenceWeekly from "./RecurrenceWeekly";
import RecurrenceMonthly from "./RecurrenceMonthly";
import RecurrenceYearly from "./RecurrenceYearly";
import { useTranslation } from "react-i18next";
import { ExecuteOnceStyles } from "../ActionModuleCard.styles";

interface RecurrenceFormProps {
  recurrence: string;
}

export interface RecurrencySubformProps {
  handleOnSetRecurrenceFormData: (data: RecurenceFormDataType) => void;
}

export type DailyRecurrenceType = {
  weekdayNum: number;
};

export type WeeklyRecurrenceType = {
  numberOfWeeks: number;
  selectedDays: string[];
};

export type MonthlyNumericDayType = {
  dayNum: number;
  monthRecurrence: number;
};

export type MonthlySpecificDayType = {
  dayNum: number;
  dayName: string;
  monthRecurrence: number;
};

export type YearlyRecurrenceType = {
  yearToRecure: number;
  specificDate: {
    dayNum: number;
    monthName: string;
  };
  specificDayOfMonth: {
    dayNum: number;
    dayName: string;
    monthName: string;
  };
};

export type ExecuteOncePattern = {
  executeOnce: true;
};

export type RecurenceFormDataType =
  | DailyRecurrenceType
  | WeeklyRecurrenceType
  | MonthlyNumericDayType
  | MonthlySpecificDayType
  | Partial<YearlyRecurrenceType>
  | ExecuteOncePattern;

const RecurrenceForm: FC<RecurrenceFormProps> = ({ recurrence }) => {
  const [recurrenceFormData, setRecurrenceFormData] =
    useState<RecurenceFormDataType>();
  const { t } = useTranslation();

  const handleOnSetRecurrenceFormData = (
    recurrenceData: RecurenceFormDataType
  ) => {
    setRecurrenceFormData(recurrenceData);
  };

  useEffect(() => {
    if (recurrence === t("actionPage.executeOnce"))
      setRecurrenceFormData({ executeOnce: true });
  }, [recurrence]);

  useEffect(() => {
    console.log(recurrenceFormData);
  }, [recurrenceFormData]);

  switch (recurrence) {
    case t("frequency.daily"):
      return (
        <RecurrenceDaily
          handleOnSetRecurrenceFormData={handleOnSetRecurrenceFormData}
        />
      );
    case t("frequency.weekly"):
      return (
        <RecurrenceWeekly
          handleOnSetRecurrenceFormData={handleOnSetRecurrenceFormData}
        />
      );
    case t("frequency.monthly"):
      return (
        <RecurrenceMonthly
          handleOnSetRecurrenceFormData={handleOnSetRecurrenceFormData}
        />
      );
    case t("frequency.yearly"):
      return (
        <RecurrenceYearly
          handleOnSetRecurrenceFormData={handleOnSetRecurrenceFormData}
        />
      );
    case t("actionPage.executeOnce"):
      return (
        <ExecuteOnceStyles>
          <span>{t("actionPage.executeOnceText")}</span>
        </ExecuteOnceStyles>
      );
    default:
      return <></>;
  }
};

export default RecurrenceForm;
