import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { GroupItem } from "../../types/groupItem";
import {
  SortingDataType,
  TableSortDirection,
  sortArrayBy,
} from "../../common/logic/TableSortUtils";
import {
  createGroupThunk,
  deleteGroupThunk,
  editGroupsThunk,
  fetchGroupsThunk,
} from "../thunk/groupsThunk";
import { PaginationType } from "../../types/common";
import { setInitalPagination } from "./utils";

export interface GroupsState {
  editMode: boolean;
  isNewGroupToAdd: boolean;
  onSave: boolean;
  groupsData: GroupItem[] | [];
  editedGroupsData: GroupItem[] | [];
  pagination: PaginationType;
}

const initialState: GroupsState = {
  editMode: false,
  isNewGroupToAdd: false,
  onSave: true,
  groupsData: [],
  editedGroupsData: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 10,
  },
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    onCreateNewGroup: (state, payload: PayloadAction<boolean>) => {
      state.isNewGroupToAdd = payload.payload;
    },
    createGroup: (state, payload: PayloadAction<GroupItem>) => {
      state.groupsData = [payload.payload, ...state.groupsData];
      state.isNewGroupToAdd = false;
    },
    deleteGroup: (state, payload: PayloadAction<number>) => {
      state.groupsData = state.groupsData.filter(
        (group) => group.id !== payload.payload
      );
    },
    sortGroupsTable: (
      state,
      payload: PayloadAction<{
        dataField: string;
        direction: TableSortDirection;
        dataType: SortingDataType;
      }>
    ) => {
      const { dataField, dataType, direction } = payload.payload;
      const arrayToSort = current(state.groupsData);
      state.groupsData = sortArrayBy(
        arrayToSort,
        dataField,
        dataType,
        direction
      );
    },
    setEditedGroupsData: (state, action: PayloadAction<GroupItem>) => {
      state.editedGroupsData = state.editedGroupsData.length === 0
        ? [action.payload]
        : [
            ...state.editedGroupsData.filter(
              (item) => item.id !== action.payload.id
            ),
            action.payload,
          ];
    },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupsThunk.fulfilled, (state, action) => {
      state.groupsData = action.payload as GroupItem[];
      setInitalPagination(state, action.payload as GroupItem[]);
    });
    builder.addCase(createGroupThunk.fulfilled, (state, action) => {});
    builder.addCase(deleteGroupThunk.fulfilled, (state, action) => {
      state.groupsData = state.groupsData.filter(
        (group) => group.id !== action.payload
      );
    });
    builder.addCase(editGroupsThunk.fulfilled, (state, action) => {
      state.groupsData = state.groupsData.map((item) => {
        const updatedItem = action.payload?.find(
          (group) => group.id === item.id
        );
        return updatedItem ? updatedItem : item;
      });
      state.editedGroupsData = [];
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  createGroup,
  deleteGroup,
  onCreateNewGroup,
  setEditMode,
  sortGroupsTable,
  setEditedGroupsData,
  setPagination,
} = groupsSlice.actions;

export default groupsSlice.reducer;
