import { Box, Button, Typography, styled } from "@mui/material";
import { theme } from "../../../../theme";
import { BasicTableRowStyles } from "../../../../components/common/Common.styles";

export const PopUpContentWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  maxHeight: "calc(100vh - 200px)",
  overflowY: "scroll",
  backgroundColor: theme.palette.backgroundColor.main,
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  border: '2px solid #000'
}));

const activeSettingsStyles = (isActive: boolean) => {
  if (isActive) {
    return {
      position: "absolute",
      top: "0",
      left: "0",
      width: "386px",
      height: "279px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.backgroundColor.main,
      padding: "0px 40px 37px 0px",
      div: {
        height: "auto",
      },
    };
  } else {
    return {};
  }
};

export const SettingsWrapperStyles = styled(Box)(
  ({ isSettingsActive }: { isSettingsActive: boolean }) => ({
    marginLeft: "26px",
    width: "140px",
    height: "31px",
    position: "relative",
    zIndex: "100",
    "& > div": {
      width: "100%",
      height: "100%",
      fontSize: "18px",
      borderRadius: "2px",
      overflow: "hidden",
      border: `1px solid ${theme.palette.textMain.main}`,
      ...activeSettingsStyles(isSettingsActive),
    },
  })
);

export const SettingsBoxStyles = styled(Box)({
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 9px",
  width: "140px",
  height: "31px",
});

export const CheckboxesWrapperStyles = styled(Box)({
  margin: "9px 0 0 9px",
  "& > .MuiTypography-root": {
    marginTop: "25px",
  },
});

export const InputFieldsBoxStyles = styled(Box)({
  height: "auto!important",
  maxHeight: "61px",
  marginBottom: "5px",
  overflowY: "auto",
  ".MuiBox-root": {
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});

export const CertificatesPageRowStyles = styled(BasicTableRowStyles)({
  button: {
    border: "none",
    outline: "none",
    padding: "none",
    background: "none",
    cursor: "pointer",
    svg: {
      width: "30px",
      height: "30px",
    },
  },
});

export const CertificatesBtnsCell = styled(Box)(
  ({ newUserMode }: { newUserMode: boolean }) => ({
    display: "flex",
    width: "100%",
    paddingRight: "35px!important",
    boxSizing: "border-box",
    justifyContent: newUserMode
      ? "space-between!important"
      : "flex-end!important",
    path: {
      fill: theme.palette.warningColor.main,
    },
    [theme.breakpoints.down(1700)]: {
      "&": {
        padding: "0 25px 0 0!important",
      },
    },
  })
);

export const CertificatesEditRowStyles = styled(BasicTableRowStyles)(
  ({ theme }) => ({
    "&:last-child": {
      border: "none",
    },
    "& > div": {
      width: "100%",
      boxSizing: "border-box",
      "&:first-child": {
        paddingLeft: "35px",
        [theme.breakpoints.down(1700)]: {
          "&": {
            padding: "0 0 0 25px!important",
          },
        },
      },
    },
    fieldset: {
      border: "none",
    },
    input: {
      borderRadius: "5px",
    },
  })
);

export const CertificateNameInputStyles = styled(Box)(({ theme }) => ({
  ".MuiFormControl-root": {
    width: "100%",
    input: {
      fontSize: "1.25rem",
      lineHeight: "1.25",
      padding: "8px 9px 7px",
      height: "auto",
    },
  },
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "1em",
  cursor: "pointer"
}));

export const PopUpButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  flexDirection: "column",
  alignItems: "center",
  flexWrap: "nowrap",
  paddingBottom: "2em"
}));

export const NoDocumentWrapper = styled(Typography)(({ theme }) => ({
  padding: "2em",
}))

export const UploadIconContainer = styled(Button)({
  cursor: "pointer",
  font: "inherit",
  minHeight: "48px",
  minWidth: "48px",
});

export const UploadButtonStyles = styled(Box)(({ theme }) => ({
  width: "fit-content",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "140px",
  textAlign: "center",
  fontWeight: "500",
  border: `1px solid ${theme.palette.companyMain.main}`,
  backgroundColor: theme.palette.companyMain.main,
  borderRadius: "5px",
  color: theme.palette.backgroundColor.main,
  textTransform: "none",
  fontSize: "1rem",
  lineHeight: "1.2",
  height: "auto",
  padding: "2px 5px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.companyMain.hover,
  },
  ".MuiTypography-root": {
    width: "100%",
    maxWidth: "100%!important",
  },
}));

export const FileUploaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  padding: "0",
  paddingBottom: "1em",
  minHeight: "0px !important",
}))

export const MainContentWrapper = styled(Box)({
  color: "red",
  width: "80%",
  margin: "0 auto",
  marginTop: "1em",
  border: "1px solid lightgrey",
  borderRadius: "5px"
})
