import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AuthFooter() {
    const { t } = useTranslation();

    return (
        <Paper sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%'
        }}
            component="footer"
            square
            variant="outlined">
            <Container maxWidth="lg">
                <Box
                    sx={{
                        justifyContent: "space-evenly",
                        display: "flex",
                        mb: 1,
                        mt: 1
                    }}
                >
                    <Typography variant="caption" color="initial">
                        <Link to={`https://bonisoft.de/impressum/`}>{t("footer.impressum")}</Link>
                    </Typography>
                    <Typography variant="caption" color="initial">
                        <Link to={`https://bonisoft.de/privacy-policy/`}>{t("footer.privacy")}</Link>
                    </Typography>
                    <Typography variant="caption" color="initial">
                        <span>Copyright ©{new Date().getFullYear()}</span>
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
}