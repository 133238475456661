import { useEffect, useState } from "react";

export const useTableDataToRender = (
  searchQuery: string,
  tableData: any[],
  filterByField: string | string[]
) => {
  const [tableDataToRender, setTableDataToRender] = useState<any[] | []>(
    tableData
  );

  useEffect(() => {
    if (searchQuery) {
      setTableDataToRender(
        tableData.filter((rowData) => {
          if (Array.isArray(filterByField)) {
            let dataToFilter = "";
            filterByField.forEach(
              (field) => (dataToFilter = dataToFilter + " " + rowData[field])
            );
            return dataToFilter
              .toLowerCase()
              .trim()
              .includes(searchQuery.toLowerCase().trim());
          } else {
            return rowData[filterByField]
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }
        })
      );
    } else {
      setTableDataToRender(tableData);
    }
  }, [searchQuery, tableData]);

  return tableDataToRender;
};
