import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageOpt, LanguageSwitch } from "./commonUi.styles";
import Divider from "@mui/material/Divider";

const LanguageOption = ({ lang }: { lang: string }) => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const handleSwitchLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        const path = location.pathname.split("/").slice(2).join("/");
        navigate(`/${lang}/${path}${location.search}`);
    };
    return (
        <LanguageOpt
            sx={{ color: (i18n.language === lang) ? "white" : "black" }}
            onClick={() => handleSwitchLanguage(lang)}
        >
            {lang}
        </LanguageOpt>
    )
}

const LanguageSwitcher = () => {
    return (
        <LanguageSwitch defaultChecked >
            <LanguageOption lang="en" />
            <Divider
                sx={{ margin: "-3px 0", borderWidth: "1px" }}
                orientation="vertical"
                variant="middle"
                flexItem />
            <LanguageOption lang="de" />
        </LanguageSwitch>
    )
}

export default LanguageSwitcher