import { useTranslation } from 'react-i18next';
import { HeaderMenuPoint, NotificationHeaderContainer } from './Notification.styles'

const NotificationHeader = ({ headerMenuSelected, setHeaderMenu }) => {
  const { t } = useTranslation();
  return (
    <NotificationHeaderContainer>
      <HeaderMenuPoint
        selected={headerMenuSelected === 0 ? true : false}
        onClick={() => setHeaderMenu(0)}
      >
        {t("notifications.unread")}
      </HeaderMenuPoint>
      <HeaderMenuPoint
        selected={headerMenuSelected === 1 ? true : false}
        onClick={() => setHeaderMenu(1)}
      >
        {t("notifications.all")}
      </HeaderMenuPoint>
    </NotificationHeaderContainer>
  )
}

export default NotificationHeader