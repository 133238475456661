import { FC } from "react";
import { BasicRadioButtonStyles } from "./Common.styles";

interface RadioButtonProps {
  label: string;
  isChecked: boolean;
  handleOnClick: () => void;
}

const RadioButton: FC<RadioButtonProps> = ({
  label,
  isChecked,
  handleOnClick,
}) => {
  return (
    <BasicRadioButtonStyles key={label}>
      <label htmlFor={label} onClick={handleOnClick}>
        <input
          type="radio"
          name={label}
          value={label}
          onChange={() => {}}
          checked={isChecked}
        />
        <span></span>
        {label}
      </label>
    </BasicRadioButtonStyles>
  );
};

export default RadioButton;
