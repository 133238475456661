import { Button } from "@mui/material";
import { FC } from "react";
import { SaveIcon } from "../../../assets/icons/ActionIcons";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";

interface SaveBtnProps {
  title?: string;
  handleOnClick: () => void;
  hoverText?: string;
}

const SaveBtn: FC<SaveBtnProps> = ({ title, handleOnClick, hoverText }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={hoverText ? hoverText : t("label.save")}>
      <Button
        onClick={handleOnClick}
        sx={(theme) => ({
          fontSize: "1.25rem",
          lineHeight: "1.2",
          fontWeight: "700",
          height: "auto",
          textAlign: "center",
          textTransform: "none",
          width: "fit-content",
          minWidth: "0",
          padding: "2px 5px",
          borderRadius: "5px",
          border: `1px solid ${theme.palette.companyMain.main}`,
          backgroundColor: theme.palette.companyMain.main,
          "&:hover": {
            backgroundColor: theme.palette.companyMain.hover,
          },
        })}
      >
        {title ? title : <SaveIcon/>}
      </Button>
    </Tooltip>
  );
};

export default SaveBtn;
