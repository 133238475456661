export enum BillType {
  GeneralLicense = "GeneralLicense",
  TaxFreeBenefitBill = "TaxFreeBenefitBill",
  NewEmployeesActivationBill = "NewEmployeesActivationBill"
}
export enum PaymentMethod {
  OnAccount = "OnAccount",
  SepaDirectDebit = "SepaDirectDebit",
}
export enum PaymentStatus {
  WaitingForPayment = "WaitingForPayment",
  Paid = "Paid",
}

export interface BillItem {
  id: number;
  companyId: number;
  companyName: string;
  invoiceNumber: string;
  invoiceDate: string;
  billType: BillType;
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatus;
  analysisFileId?: number;
  analysisFileDownloadUrl: string
  invoiceFileId?: number;
  invoiceFileDownloadUrl: string;
}