import { Box } from "@mui/material";
import { useTableDataToRender } from "../../hooks/UseTableDataToRender";
import { useBillsData, usePagination } from "../../store/selectors/billsSelectors";
import { FC } from "react";
import BillsTableRow from "./BillsTableRow";
import { useTableDataPaginated } from "../../hooks/UseTablePagination";
import Pagination from "../../components/common/table/Pagination";
import { setPagination } from "../../store/reducers/billsReducer";
import { BillItem } from "../../types/bills";

const BillsTableBody: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const tableData = useBillsData();
  const { itemsPerPage, currentPage, totalPages } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    ["companyName", "invoiceNumber", "billType", "paymentMethod", "paymentStatus"]
  );

  const billItemsToRender = tableDataToRender.map((bill: BillItem) => (
    <BillsTableRow key={bill.id} billData={bill} />
  ));

  return (
    <>
      {billItemsToRender}
      <Box sx={{ gridColumn: "1/8" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default BillsTableBody;
