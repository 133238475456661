import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TableManagement {
    processingRequest: boolean;
    responseValue: string;
    responseMessage: string;
}

const initialState: TableManagement = {
    processingRequest: false,
    responseValue: "",
    responseMessage: ""
};

export const tableManagementReducer = createSlice({
    name: "tableManagement",
    initialState,
    reducers: {
        setProcessingRequest: (state, action: PayloadAction<boolean>) => {
            state.processingRequest = action.payload;
        },
        setResponseValue: (state, action: PayloadAction<string>) => {
            state.responseValue = action.payload;
        },
        setResponseMessage: (state, action: PayloadAction<string>) => {
            state.responseMessage = action.payload;
        },
    }
})

export const {
    setProcessingRequest,
    setResponseValue,
    setResponseMessage
} = tableManagementReducer.actions;

export default tableManagementReducer.reducer;