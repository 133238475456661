import { Button } from "@mui/material";
import { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { theme } from "../../../theme";

interface DownloadBtnProps {
  isDisabled?: boolean;
  handleOnDownload: () => void;
}

const DownloadBtn: FC<DownloadBtnProps> = ({
  handleOnDownload,
  isDisabled,
}) => {
  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleOnDownload}
        sx={{
          padding: "5px",
          width: "fit-content",
          minWidth: "0",
          "&": {
            path: {
              fill: isDisabled ? theme.palette.border.subform : theme.palette.companyMain.main,
            }
          }
        }}
      >
        <DownloadIcon />
      </Button>
    </>
  );
};

export default DownloadBtn;
