import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { TaxFreeBenefitUserType } from "../../types/taxFreeBenefitUsersType";
import {
  SortingDataType,
  TableSortDirection,
  sortArrayBy,
} from "../../common/logic/TableSortUtils";
import { fetchTaxFreeBenefitUsersThunk } from "../thunk/taxFreeBenefitThunk";
import { setInitalPagination } from "./utils";
import { PaginationType } from "../../types/common";

interface TaxFreeBenefitInitalState {
  editMode: boolean;
  usersToEdit: TaxFreeBenefitUserType[] | [];
  taxFreeBenefitUsers: TaxFreeBenefitUserType[] | [];
  pagination: PaginationType;
}

const initialState: TaxFreeBenefitInitalState = {
  editMode: false,
  usersToEdit: [],
  taxFreeBenefitUsers: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 10,
  },
};

const taxFreeBenefitReducer = createSlice({
  name: "taxFreeBenefit",
  initialState,
  reducers: {
    setTaxFreeBenefitEditMode: (state) => {
      state.editMode = true;
    },
    saveTaxFreeBenefitTable: (state) => {
      state.editMode = false;
      state.usersToEdit = [];
    },
    setUsersToEdit: (
      state,
      action: PayloadAction<Partial<TaxFreeBenefitUserType>>
    ) => {
      const userToEdit = state.taxFreeBenefitUsers.find(
        (item) => item.id === action.payload.id
      );
      if (action.payload.groupId === 0){
        action.payload.groupId = undefined
        action.payload.group = undefined
      }
      const usersSet = new Set([
        ...state.usersToEdit,
        { ...userToEdit, ...action.payload },
      ]);
      state.usersToEdit = Array.from(usersSet) as TaxFreeBenefitUserType[];
    },
    sortTaxFreeBenefitUsersTable: (
      state,
      payload: PayloadAction<{
        dataField: string;
        direction: TableSortDirection;
        dataType: SortingDataType;
        arrayToSort?: { groupName: string; id: number }[];
      }>
    ) => {
      const { dataField, dataType, direction, arrayToSort } = payload.payload;
      if (arrayToSort) {
        const sortedArray = sortArrayBy(
          arrayToSort,
          dataField,
          dataType,
          direction
        );
        let sortedUsers: TaxFreeBenefitUserType[] = [];
        sortedArray.forEach((item) => {
          sortedUsers.push(
            ...current(state.taxFreeBenefitUsers).filter(
              (user) => user.groupId === item.id
            )
          );
        });
        state.taxFreeBenefitUsers = sortedUsers;
        return;
      } else {
        const arr = current(state.taxFreeBenefitUsers);
        state.taxFreeBenefitUsers = sortArrayBy(
          arr,
          dataField,
          dataType,
          direction
        );
      }
    },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchTaxFreeBenefitUsersThunk.fulfilled,
      (state, action) => {
        state.taxFreeBenefitUsers = action.payload;
        setInitalPagination(state, action.payload);
      }
    );
  },
});

export const {
  setTaxFreeBenefitEditMode,
  saveTaxFreeBenefitTable,
  setUsersToEdit,
  sortTaxFreeBenefitUsersTable,
  setPagination,
} = taxFreeBenefitReducer.actions;

export default taxFreeBenefitReducer.reducer;
