import { Box, Button, Dialog, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmDeleteDialogProps {
  open: boolean;
  dialogMessage: string;
  handleDialogClose: () => void;
  handleOnDelete: () => void;
}

const ConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  open,
  dialogMessage,
  handleDialogClose,
  handleOnDelete,
}) => {
  const handleOnClose = () => {
    handleOnDelete();
    handleDialogClose();
  };

  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ ".MuiPaper-root": { padding: "40px" } }}
      open={open}
      onClose={handleDialogClose}
    >
      <Typography>{dialogMessage}</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "35px",
          button: {
            margin: "0 10px",
            borderRadius: "5px",
            border: `1px solid ${theme.palette.companyMain.main}`,
            fontWeight: "600",
            lineHeight: "1.2",
            padding: "8px 15px",
          },
        })}
      >
        <Button
          sx={(theme) => ({
            color: theme.palette.backgroundColor.main,
            background: theme.palette.companyMain.main,
            "&:hover": {
              color: theme.palette.companyMain.main,
              background: theme.palette.backgroundColor.main,
            },
          })}
          onClick={handleOnClose}
        >
          {t("buttons.confirm")}
        </Button>{" "}
        <Button onClick={handleDialogClose}>{t("buttons.cancel")}</Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
