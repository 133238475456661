import { FC } from 'react'
import { NotificationItem } from '../../../../types/notificationType';
import { Box, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NotificationBody, NotificationDate, NotificationMessage, NotificationTitle, SingleNotificationContainer } from './Notification.styles';
import { setNotificationToEdit } from '../../../../store/reducers/notificationReducer';
import { editNotificationVisibilityThunk } from '../../../../store/thunk/notificationThunk';
import { useAppDispatch } from '../../../../hooks/StoreHooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocale } from '../../../../hooks/UseLocale';

interface SingleNotificationProps {
    notificationData: NotificationItem;
}

const SingleNotification: FC<SingleNotificationProps> = ({ notificationData }) => {
    const {
        payloadType,
        status,
        createdTimestamp,
        payload
    } = notificationData;

    const { t } = useTranslation();

    const payloadJSON = payload && JSON.parse(payload)
    const subtypeFirstCharLower = payloadJSON.subtype.substring(0, 1).toLowerCase() + payloadJSON.subtype.substring(1) as String;
    const locale = useLocale();
    const dispatch = useAppDispatch()
    const handleClick = async () => {
        dispatch(setNotificationToEdit({ ...notificationData, status: status === "Read" ? "Unread" : "Read" }));
        await dispatch(editNotificationVisibilityThunk());
    }

    const renderSwitch = (payloadTypeSwitch) => {
        switch (payloadTypeSwitch) {
            case "GenericMessage":
                return (
                    <NotificationBody>
                        <Typography>
                            {payloadJSON.title && <NotificationTitle>{payloadJSON.title}</NotificationTitle>}
                            {payloadJSON.message && <NotificationMessage>{payloadJSON.message}</NotificationMessage>}
                            <NotificationDate>{createdTimestamp}</NotificationDate>
                        </Typography>
                    </NotificationBody>
                )
            case "ActionModuleNoFulfillmentStateReminder":
                return (
                    <NotificationBody>
                        <Typography>
                            {payloadJSON.subtype && <NotificationMessage>{t(`notifications.actionModuleNoFulfillmentStateReminder`, { subtypename: t(`incentivationPage.${subtypeFirstCharLower}`) })} <Link to={ window.location.origin + `/${locale}/incentivation/taxfreebenefit/${subtypeFirstCharLower}`}>{ t(`incentivationPage.${subtypeFirstCharLower}`) }</Link></NotificationMessage>}
                            <NotificationDate>{createdTimestamp}</NotificationDate>
                        </Typography>
                    </NotificationBody>
                )
            case "ActionModuleNoScoreReminder":
                return (
                    <NotificationBody>
                        <Typography>
                            {payloadJSON.subtype && <NotificationMessage>{t(`notifications.actionModuleNoScoreReminder`, { subtypename: t(`incentivationPage.${subtypeFirstCharLower}`) })} <Link to={ window.location.origin + `/${locale}/incentivation/taxfreebenefit/${subtypeFirstCharLower}`}> { t(`incentivationPage.${subtypeFirstCharLower}`) }</Link></NotificationMessage>}
                            <NotificationDate>{createdTimestamp}</NotificationDate>
                        </Typography>
                    </NotificationBody>
                )
            default:
                return (
                    <></>
                )
        }
    }

    return (
        <SingleNotificationContainer
            onClick={handleClick}
            read={status === "Read" ? true : false}
        >
            {renderSwitch(payloadType)}
            <Box>
                {status === "Read" ? <Visibility /> : <VisibilityOff />}
            </Box>
        </SingleNotificationContainer>

    )
}

export default SingleNotification