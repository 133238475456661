import { SetStateAction, useState } from "react";
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    CssBaseline,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { doApiPost } from "../../api/Requests";
import { AuthButton } from "./Authentication.styles";
import AuthFooter from "./AuthFooter";
import BonisoftLogo from "../../common/ui/BonisoftLogo";
import LanguageSwitcher from "../../common/ui/LanguageSwitcher";
import { useTranslation } from "react-i18next";


export function ResetPasswordScreen() {
    const [email, setEmail] = useState("")
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { t } = useTranslation();

    const onResetPassword = async function () {
        setIsError(false);
        setIsSuccess(false);
        setIsLoading(true);

        const result = await doApiPost<any, never>(
            "/api/users/password_reset",
            { eMail: email },
            { handleUnauthenticated: false }
        );

        setIsLoading(false);
        if (result.success()) {
            setIsSuccess(true);
        } else {
            setIsError(true);
        }

    }

    return <Container component="main" maxWidth="xs" sx={{ mt: 10, mb: 10 }}>
        <CssBaseline />
        <LanguageSwitcher />
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <BonisoftLogo imgRatio={40} />
            <Typography variant="h1" sx={{ alignSelf: "center", textAlign: "center", mb: 5 }}>
                {t("authentication.resetPassword.resetPassword")}
            </Typography>
            <Box>
                <Stack gap={2}>
                    {
                        !isSuccess && <>
                            <TextField
                                fullWidth
                                variant="outlined"
                                disabled={false}
                                placeholder="E-Mail"
                                value={email}
                                onChange={(v: { target: { value: SetStateAction<string>; }; }) => setEmail(v.target.value)}
                            />
                            {
                                isError &&
                                <Alert severity="error"
                                    variant="standard">
                                    {t("authentication.resetPassword.resetFailed")}
                                </Alert>
                            }
                            <AuthButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                disabled={isLoading}
                                sx={{ mt: 3 }}
                                onClick={onResetPassword}
                            >
                                {
                                    isLoading && <><CircularProgress /></>
                                }
                                {
                                    !isLoading && <>{t("authentication.resetPassword.resetPassword")}</>
                                }
                            </AuthButton>
                        </>
                    }

                    {
                        isSuccess &&
                        <Alert severity="success">
                            <Typography>
                                {t("authentication.resetPassword.emailSent")}: <b>{email}</b>
                            </Typography>
                        </Alert>
                    }
                </Stack>
            </Box>
        </Stack>
        <AuthFooter />
    </Container>
}