import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationApi } from "../../api/notificationApi";
import { RootState } from "../store";
import { NotificationFetchParameters } from "../../types/notificationType";

export const fetchUnreadNotificationAmountThunk = createAsyncThunk(
    "notification/unreadNotificationAmount",
    async () => {
        try {
            const res = await notificationApi.getUnreadNotificationAmount();
            return res as number;
        } catch (error) {
            console.log(error);
        }
        return [];
    }
);

export const fetchNotificationListThunk = createAsyncThunk(
    "notification/fetchNotificationList",
    async (fetchParameters :NotificationFetchParameters, thunkApi) => {
        try {
            const response = await notificationApi.getNotificationList(fetchParameters.offset, fetchParameters.limit);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
);

export const editNotificationVisibilityThunk = createAsyncThunk(
    "notification/editNotificationVisibility",
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const notificationToEdit = state.notifications.notificationToEdit;
        const notificationId = notificationToEdit.id ?? 0;
        const notificationStatus = notificationToEdit.status ?? "";
        await notificationApi.editNotificationVisibility(notificationId, notificationStatus)
        await thunkApi.dispatch(fetchNotificationListThunk({offset: 0, limit: 10}));
        await thunkApi.dispatch(fetchUnreadNotificationAmountThunk())
        return notificationId;
    })
