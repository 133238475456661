import GroupsBlock from "./GroupsBlock/GroupsBlock";
import { useEffect, useState } from "react";
import { fetchGroupsThunk } from "../../store/thunk/groupsThunk";
import { fetchActions } from "../../store/thunk/actionsThunk";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { setProcessingRequest, setResponseValue } from "../../store/reducers/tableManagementReducer";
import { useProcessingRequest, useResponseMessage, useResponseValue } from "../../store/selectors/tableManagementSelector";
import { ModalComponent } from "../../components/common/ModalComponent";
import { Box } from "@mui/material";

const breadcrumbs = [
  {
    title: "groupsPage.groups.taxFreeBenefit",
    link: "groups/taxfreebenefit",
  },
];

const Groups = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const processingRequest = useProcessingRequest()
  const responseValue = useResponseValue()
  const responseMessage = useResponseMessage()

  const [successModal, setSuccessModal] = useState<boolean>(false)

  const closeModal = () => {
    setSuccessModal(false)
  }

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchGroupsThunk());
    dispatch(fetchActions());
  }, []);

  useEffect(() => {
    if (processingRequest === false && responseValue.includes("fulfilled")) {
      setSuccessModal(true)
      dispatch(setResponseValue(""))
    }
  }, [processingRequest]);

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("groupsPage.groups.title")}
      />
      <GroupsBlock />

      <CircularIndeterminate processingRequest={processingRequest} />
      <ModalComponent isOpen={successModal} onClose={closeModal}>
        <Box>{responseMessage}</Box>
      </ModalComponent>
    </>
  );
};

export default Groups;
