import { useAppSelector } from "../../hooks/StoreHooks";

export const useAccountManagementEditMode = () =>
  useAppSelector((state) => state.accountManagement.editMode);

export const useAccountManagementUsers = () =>
  useAppSelector((state) => state.accountManagement.users);

export const useUsersToEditData = () =>
  useAppSelector((state) => state.accountManagement.usersToEdit);

export const useAccountManagementNewUserMode = () =>
  useAppSelector((state) => state.accountManagement.newUserMode);

export const usePagination = () =>
  useAppSelector((state) => state.accountManagement.pagination);
