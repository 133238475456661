import { createSlice } from "@reduxjs/toolkit";
import {
  fetchActionModulesDoneThunk,
  fetchActivatedAccountsThunk,
  fetchAveragePointThunk,
  fetchControlSignatureMonthlyThunk,
  fetchControlSignatureWeeklyThunk,
  fetchPresenceQuotaThunk,
  fetchTotalPointThunk
} from "../thunk/reportingThunk";
import { setInitalPagination } from "./utils";
import { 
  ActionModulesDoneType, 
  AveragePointType, 
  TotalPointType, 
  PresenceQuotaType, 
  ActivatedAccountsType, 
  ControlSignatureMonthlyType, 
  ControlSignatureWeeklyType 
} from "../../types/reportingTypes";

interface ReportingInitialState {
  averagePoints: AveragePointType[],
  totalPoints: TotalPointType[],
  actionModulesDone: ActionModulesDoneType[],
  presenceQuota: PresenceQuotaType[],
  controlSignatureMonthly: ControlSignatureMonthlyType[],
  controlSignatureWeekly: ControlSignatureWeeklyType[],
  activatedAccounts: ActivatedAccountsType[]
}

const initialState: ReportingInitialState = {
  averagePoints: [],
  totalPoints: [],
  actionModulesDone: [],
  presenceQuota: [],
  controlSignatureMonthly: [],
  controlSignatureWeekly: [],
  activatedAccounts: []
};

const reportingReducer = createSlice({
  name: "reporting",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAveragePointThunk.fulfilled,
      (state, action) => {
        state.averagePoints = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchTotalPointThunk.fulfilled,
      (state, action) => {
        state.totalPoints = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchActionModulesDoneThunk.fulfilled,
      (state, action) => {
        state.actionModulesDone = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchPresenceQuotaThunk.fulfilled,
      (state, action) => {
        state.presenceQuota = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchControlSignatureWeeklyThunk.fulfilled,
      (state, action) => {
        state.controlSignatureWeekly = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchControlSignatureMonthlyThunk.fulfilled,
      (state, action) => {
        state.controlSignatureMonthly = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
    builder.addCase(
      fetchActivatedAccountsThunk.fulfilled,
      (state, action) => {
        state.activatedAccounts = action.payload;
        setInitalPagination(state, action.payload);
      },
    );
  },
});

export const { } = reportingReducer.actions;

export default reportingReducer.reducer;
