import AppPreview from '../../../assets/icons/AppPreview';
import { useAppDesignAccentColor, useAppDesignCompanyLogo } from '../../../store/selectors/configurationSelector';
import { PreviewContainer, PreviewFrameContainer, PreviewImageContainer } from './AppDesign.styles'


function AppDesignPreview() {
  let currentCompanyColor = useAppDesignAccentColor();
  let currentCompanyLogoUrl = useAppDesignCompanyLogo();

  return (
    <PreviewContainer>
      <PreviewImageContainer>
        {currentCompanyLogoUrl && <img
          key={Date.now()}
          src={currentCompanyLogoUrl}
          height="100%"
          style={{ objectFit: "contain", maxWidth: "100%" }} />}
      </PreviewImageContainer>
      <PreviewFrameContainer>
        <AppPreview color={currentCompanyColor ?? "transparent"} />
      </PreviewFrameContainer>
    </PreviewContainer>
  )
}

export default AppDesignPreview