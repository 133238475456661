import { MainContentWrapper, TableWrapper } from "../../../../components/common/Common.styles";
import PopUpTableHeader from "./PopUpTableHeader";
import PopUpTableBody from "./PopUpTableBody";
import { FC } from "react";
import { DocumentItem } from "../../../../types/certificates";
import CellBox from "../../../../components/common/table/CellBox";
import { NoDocumentWrapper } from "./DoucmentPopUp.styles";
import { useTranslation } from "react-i18next";

const PopUpTable: FC<{ documentData: DocumentItem[], documentExchangeWalletId: number }> = ({ documentData, documentExchangeWalletId }) => {

  const { t } = useTranslation();

  return (
    <MainContentWrapper>
      {
        documentData.length > 0 ?
          <TableWrapper
            maxWidth="1200px"
            columnsTemplate="1fr 1fr 1fr 0.5fr 0fr 0fr 0fr"
            width= "80%"
            margin= "0 auto"
            marginTop= "1em"
            border= "1px solid lightgrey"
            borderRadius= "5px"
          >
            <PopUpTableHeader />
            <PopUpTableBody documentData={documentData} documentExchangeWalletId={documentExchangeWalletId}/>
          </TableWrapper>
          :
          <CellBox>
            <NoDocumentWrapper>{t("documentManagementPage.documentManagement.certificatesTrainings.noDocument")}</NoDocumentWrapper>
          </CellBox>
      }
    </MainContentWrapper>
  );
};

export default PopUpTable;