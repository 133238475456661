import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultIcon, {
  defaultIcons,
} from "../../../components/common/DefaultIcon";
import { ActionIconData } from "../../../types/actionItem";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { setActionData } from "../../../store/reducers/actionsReducer";
import { colorsData } from "../constants";
import {
  ActionModuleIconStyles,
  ColorBtnStyles,
  ColorsBlockStyles,
  EmptyLogoPreviewStyles,
  IconOptionsSelectWraper,
  IconsBlockStyles,
  LogoPreviewStyles,
  LogoPreviewWithIconStyles,
  SelectLogoWrapper,
} from "./ActionModuleCard.styles";

interface SelectLogoModuleProps {
  iconName?: string;
  colorHex?: string;
}

const SelectLogoModule: FC<SelectLogoModuleProps> = ({ iconName, colorHex }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [actionIconData, setActionIconData] = useState<ActionIconData | null>(
    null
  );

  useEffect(() => {
    if (iconName)
      if (colorHex) {
        setActionIconData({ iconName, colorHex });
      } else {
        setActionIconData(
          defaultIcons.find(
            (item) => item.iconName === iconName
          ) as ActionIconData
        );
      }
  }, []);

  const handleActionLogoSelect = (logoData: ActionIconData) => {
    setActionIconData({
      iconName: logoData.iconName,
      colorHex: actionIconData ? actionIconData.colorHex : "",
    });
    dispatch(setActionData({ iconName: logoData.iconName }));
  };

  const handleActionLogoColorSelect = (color: string) => {
    setActionIconData({
      iconName: actionIconData ? actionIconData.iconName : "",
      colorHex: color,
    });
    dispatch(setActionData({ colorHex: color }));
  };

  const iconsToSelect = defaultIcons.map((item) => {
    const isSelected =
      iconName === item.iconName ||
      (actionIconData && actionIconData.iconName === item.iconName)
        ? true
        : false;
    return (
      <ActionModuleIconStyles
        isSelected={isSelected}
        key={item.iconName}
        onClick={() => handleActionLogoSelect(item)}
      >
        <DefaultIcon iconName={item.iconName} colorHex={item.colorHex} />
      </ActionModuleIconStyles>
    );
  });

  const colorsToSelect = colorsData.map((item) => (
    <ColorBtnStyles
      item={item}
      onClick={() => handleActionLogoColorSelect(item)}
      key={item}
    />
  ));

  return (
    <SelectLogoWrapper>
      <Typography>{t("actionPage.selectLogoText")}</Typography>
      <Box>
        <LogoPreviewStyles>
          {actionIconData ? (
            <LogoPreviewWithIconStyles>
              <DefaultIcon
                iconName={actionIconData.iconName}
                colorHex={actionIconData && actionIconData.colorHex}
              />
            </LogoPreviewWithIconStyles>
          ) : (
            <EmptyLogoPreviewStyles>
              <span></span>
              <span></span>
            </EmptyLogoPreviewStyles>
          )}
          <Typography variant="subtitle1">{t("actionPage.logo")}</Typography>
        </LogoPreviewStyles>
        <IconOptionsSelectWraper>
          <IconsBlockStyles>{iconsToSelect}</IconsBlockStyles>
          <ColorsBlockStyles>{colorsToSelect}</ColorsBlockStyles>
        </IconOptionsSelectWraper>
      </Box>
    </SelectLogoWrapper>
  );
};

export default SelectLogoModule;
