import { FC } from "react";
import SearchWithAutocomplete from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import {
  useTaxFreeBenefitUsers,
} from "../../../store/selectors/taxFreeBenefitSelectors";
import { useTranslation } from "react-i18next";
import { TaxFreeBenefitSearchBarStyles } from "./TaxFreeBenefit.styles";

interface TaxFreeBenefitSearchBarProps {
  handleOnSearch: (searchQuery: string) => void;
}

const TaxFreeBenefitSearchBar: FC<TaxFreeBenefitSearchBarProps> = ({
  handleOnSearch,
}) => {
  const { t } = useTranslation();
  const usersData = useTaxFreeBenefitUsers();
  const usersDataLabels = usersData.map((user) => ({
    key: user.id,
    label: user.firstName + " " + user.lastName,
  }));

  return (
    <TaxFreeBenefitSearchBarStyles>
      <SearchWithAutocomplete
        options={usersDataLabels}
        onSearchHandler={handleOnSearch}
        sx={{
          ".search-btn": {
            display: "none",
          },
        }}
      />
    </TaxFreeBenefitSearchBarStyles>
  );
};

export default TaxFreeBenefitSearchBar;
