import { useTranslation } from "react-i18next";
import BillingPage from "./BillingPage/BillingPage";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { ModalComponent } from "../../components/common/ModalComponent";
import { Box } from "@mui/material";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { useProcessingRequest, useResponseMessage, useResponseValue } from "../../store/selectors/tableManagementSelector";
import { useEffect, useState } from "react";
import { setProcessingRequest, setResponseValue } from "../../store/reducers/tableManagementReducer";
import { fetchSuperAdminBillingsThunk } from "../../store/thunk/superAdminBillingsThunk";
import { setBills } from "../../store/reducers/billsReducer";
import { useBillsData } from "../../store/selectors/billsSelectors";
import { useAppDispatch } from "../../hooks/StoreHooks";

const BillingAdministration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const processingRequest = useProcessingRequest()
  const responseValue = useResponseValue()
  const responseMessage = useResponseMessage()
  const bills = useBillsData();

  const [successModal, setSuccessModal] = useState<boolean>(false)

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchSuperAdminBillingsThunk())
    dispatch(setBills(bills));
  }, []);

  useEffect(() => {
    if (processingRequest === false && responseValue.includes("fulfilled")) {
      setSuccessModal(true)
      dispatch(setResponseValue(""))
    }
  }, [processingRequest]);

  const closeModal = () => {
    setSuccessModal(false)
  }

  const breadcrumbs = [
    {
      title: "superAdminPage.title",
      link: "superadminarea",
    },
    {
      title: "superAdminPage.billing.billingAdministration",
      link: "superadminarea/billingadministration",
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={t("superAdminPage.billing.billingAdministration")} />
      <BillingPage />

      <CircularIndeterminate processingRequest={processingRequest} />
      <ModalComponent isOpen={successModal} onClose={closeModal}>
        <Box>{responseMessage}</Box>
      </ModalComponent>
    </>
  );
};

export default BillingAdministration;
