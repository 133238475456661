import { useEffect } from "react";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../hooks/UseSearch";
import SearchWithAutocomplete from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import { useBillsData } from "../../store/selectors/billsSelectors";
import BillsTable from "./BillsTable";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { SearchWithAutocompleteWrapper } from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import { Container } from "../../components/common/Common.styles";
import { BillingContentWrapper } from "./BillingPage.styles";
import { fetchCompanyBillingsThunk } from "../../store/thunk/companyBillingsThunk";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { setProcessingRequest } from "../../store/reducers/tableManagementReducer";
import { useProcessingRequest } from "../../store/selectors/tableManagementSelector";

const BillingPage = () => {
  const { t } = useTranslation();
  const { searchQuery, handleOnSearch } = useSearch();
  const dispatch = useAppDispatch();
  const bills = useBillsData();
  const processingRequest = useProcessingRequest()

  const billsOptions = bills
    ? bills.map((bill) => ({
        key: bill.id,
        label: bill.invoiceNumber,
      }))
    : [];

  const breadcrumbs = [];

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchCompanyBillingsThunk());
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={t("billingPage.title")} />
      <BillingContentWrapper>
        <SearchWithAutocompleteWrapper>
          <Container>
            <SearchWithAutocomplete
              options={billsOptions}
              onSearchHandler={handleOnSearch}
            />
          </Container>
        </SearchWithAutocompleteWrapper>
        <BillsTable searchQuery={searchQuery} />
      </BillingContentWrapper>
      <CircularIndeterminate processingRequest={processingRequest} />
    </>
  );
};

export default BillingPage;
