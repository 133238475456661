import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { BillItem } from "../../types/bills";
import {
  SortingDataType,
  TableSortDirection,
  sortArrayBy,
} from "../../common/logic/TableSortUtils";
import { PaginationType } from "../../types/common";
import { setInitalPagination } from "./utils";
import { fetchCompanyBillingsThunk } from "../thunk/companyBillingsThunk";
import { fetchSuperAdminBillingsThunk } from "../thunk/superAdminBillingsThunk";
import { CompanyManagementType } from "../../types/companyManagementTypes";

interface BillsInitialState {
  editMode: boolean;
  newBillMode: boolean;
  billsToEdit: BillItem[] | [];
  billsData: BillItem[];
  companyList: CompanyManagementType[];
  pagination: PaginationType;
}

const initialState: BillsInitialState = {
  billsData: [],
  editMode: false,
  newBillMode: false,
  billsToEdit: [],
  companyList: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 1,
  },
};

const billsReducer = createSlice({
  name: "bills",
  initialState,
  reducers: {
    sortBills: (
      state,
      action: PayloadAction<{
        dataField: string;
        direction: TableSortDirection;
        dataType: SortingDataType;
      }>
    ) => {
      const { dataField, dataType, direction } = action.payload;
      const arrayToSort = current(state.billsData);
      state.billsData = sortArrayBy(
        arrayToSort,
        dataField,
        dataType,
        direction
      );
    },
    setBills: (state, action: PayloadAction<BillItem[]>) => {
      state.billsData = action.payload;
      setInitalPagination(state, action.payload);
    },
    setBillsEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setNewBillMode: (state, action: PayloadAction<boolean>) => {
      state.newBillMode = action.payload;
    },
    createBill: (state, payload: PayloadAction<BillItem>) => {
      state.billsData = [payload.payload, ...state.billsData];
      state.newBillMode = false;
      state.editMode = false;
    },
    saveBillsTable: (state) => {
      state.editMode = false;
      state.billsToEdit = [];
    },
    setCompanyList: (state, action: PayloadAction<CompanyManagementType[]>) => {
      state.companyList = action.payload;
    },
    setBillsToEdit: (state, action: PayloadAction<BillItem>) => {
      state.billsToEdit = state.billsToEdit.length === 0
          ? [action.payload]
          : [
              ...state.billsToEdit.filter(
                  (item) => item.id !== action.payload.id
              ),
              action.payload,
          ];
  },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      fetchCompanyBillingsThunk.fulfilled,
      (state, action) => {
        state.billsData = action.payload;
        setInitalPagination(state, action.payload);
      }
    );
    builder.addCase(
      fetchSuperAdminBillingsThunk.fulfilled,
      (state, action) => {
        state.billsData = action.payload;
        setInitalPagination(state, action.payload);
      }
    );
  },
});

export const {
  sortBills,
  setBills,
  setBillsEditMode,
  setNewBillMode,
  createBill,
  setCompanyList,
  saveBillsTable,
  setBillsToEdit,
  setPagination
} = billsReducer.actions;

export default billsReducer.reducer;
