import { TimePicker } from "@mui/x-date-pickers";
import ActionFormWrapper from "./ActionFormWrapper";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { dateFormatter } from "../../utils";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import Checkbox from "../../../../components/common/Checkbox";
import { useTranslation } from "react-i18next";
import { Container } from "../../../../components/common/Common.styles";
import {
  RecurenceCheckbox,
  RecurrenceTimeBox,
  RecurrenceTimePicker,
  RecurrenceTimeWrapper,
  RecurrenceTimeZoneSelect,
} from "../ActionModuleCard.styles";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

const RecurrenceTime = () => {
  const { t } = useTranslation();
  const currentDate = dateFormatter();

  const [startTime, setStartTime] = useState<Dayjs>(
    dayjs(`${currentDate}T00:00`)
  );
  const [endTime, setEndTime] = useState<Dayjs>(dayjs(`${currentDate}T23:59`));
  const [timezone, setTimezone] = useState<string>("");

  const [isFullDay, setIsFullDay] = useState<boolean>(false);

  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    const timeZoneStr = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentLocation =
      timeZoneStr.split("/")[1] === "Kiev" ? "Kyiv" : timeZoneStr.split("/")[1];
    const currentTimeZoneOption = options.find((item) =>
      item.label.includes(currentLocation)
    );
    if (currentTimeZoneOption) setTimezone(currentTimeZoneOption.value);
    else
      setTimezone(
        options.find((item) => item.label === "(GMT+0:00) Edinburgh, London")!
          .value
      );
  }, []);

  const handleTimezone = (e: SelectChangeEvent) => {
    setTimezone(e.target.value);
  };

  const toggleFullDay = () => {
    setIsFullDay((state) => !state);
  };

  const timezoneItems = options.map((item) => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <RecurrenceTimeWrapper>
      <ActionFormWrapper label={t("actionPage.recurrenceTime")}>
        <Container vPosition="flex-start">
          <RecurrenceTimeBox>
            <Box className="time-picker">
              <span>{t("actionPage.start")}:</span>
              <RecurrenceTimePicker>
                <TimePicker
                  disabled={isFullDay}
                  defaultValue={startTime}
                  ampmInClock={false}
                  ampm={false}
                  slots={{ openPickerIcon: ExpandMoreIcon }}
                  onChange={(newValue) => setStartTime(newValue as Dayjs)}
                />
              </RecurrenceTimePicker>
              <RecurrenceTimeZoneSelect>
                <Select
                  disabled={isFullDay}
                  value={timezone}
                  onChange={handleTimezone}
                  IconComponent={ExpandMoreIcon}
                >
                  {timezoneItems}
                </Select>
              </RecurrenceTimeZoneSelect>
            </Box>
            <Box className="time-picker">
              <span>{t("actionPage.end")}:</span>
              <RecurrenceTimePicker>
                <TimePicker
                  disabled={isFullDay}
                  minTime={startTime}
                  defaultValue={endTime}
                  ampmInClock={true}
                  ampm={false}
                  slots={{ openPickerIcon: ExpandMoreIcon }}
                  onChange={(newValue) => setEndTime(newValue as Dayjs)}
                />
              </RecurrenceTimePicker>
              <RecurrenceTimeZoneSelect>
                <Select
                  disabled={isFullDay}
                  value={timezone}
                  onChange={handleTimezone}
                  IconComponent={ExpandMoreIcon}
                >
                  {timezoneItems}
                </Select>
              </RecurrenceTimeZoneSelect>
            </Box>
          </RecurrenceTimeBox>
          <RecurenceCheckbox>
            <Checkbox
              isChecked={isFullDay}
              handleOnClick={toggleFullDay}
              label={t("actionPage.fullDay")}
            />
          </RecurenceCheckbox>
        </Container>
      </ActionFormWrapper>
    </RecurrenceTimeWrapper>
  );
};

export default RecurrenceTime;
