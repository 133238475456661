export type TableSortDirection = "asc" | "desc";
export type SortingDataType = "string" | "number" | "date" | "boolean";

export const sortArrayBy = <T extends {}>(
  array: T[],
  dataField: string,
  dataType: SortingDataType,
  direction: TableSortDirection,
  dataSample?: any
) => {
  const arrayToSort = [...array];
  switch (dataType) {
    case "string":
      return sortAsString(arrayToSort, dataField, direction);
    case "number": {
      return sortAsNumber(arrayToSort, dataField, direction);
    }
    case "date": {
      return sortAsDate(arrayToSort, dataField, direction);
    }
    case "boolean": {
      return sortAsBoolean(arrayToSort, dataField, direction, dataSample);
    }
  }
};

const sortAsString = <T extends {}>(
  arr: T[],
  objField: string,
  direction: TableSortDirection
) =>
  arr.sort((a, b) => {
    if (direction === "asc")
      return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
        .compare(a[objField as keyof T] as string, b[objField as keyof T] as string) > 0
        ? -1
        : 1;
    else
      return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
        .compare(a[objField as keyof T] as string, b[objField as keyof T] as string) < 0
        ? -1
        : 1;
  })
  ;

const sortAsArrayDataField = (
  prevArr: any[],
  nextArr: any[],
  direction: TableSortDirection
) => {
  return direction === "asc"
    ? nextArr.length - prevArr.length
    : prevArr.length - nextArr.length;
};

const sortAsNumber = <T extends {}>(
  arr: T[],
  objField: string,
  direction: TableSortDirection
) => {
  return arr.sort((a, b) => {
    const prev = a[objField as keyof T];
    const next = b[objField as keyof T];
    if (direction === "asc") {
      if (Array.isArray(prev)) {
        return sortAsArrayDataField(
          prev as Array<any>,
          next as Array<any>,
          direction
        );
      }
      return (next as number) - (prev as number);
    } else {
      if (Array.isArray(prev)) {
        return sortAsArrayDataField(
          prev as Array<any>,
          next as Array<any>,
          direction
        );
      }
      return (prev as number) - (next as number);
    }
  });
};

export const sortAsDate = <T extends {}>(
  arr: T[],
  objField: string,
  direction: TableSortDirection
) => {
  return arr.sort((a, b) => {
    const prev = new Date(a[objField as keyof T] as string).getTime() as number;
    const next = new Date(b[objField as keyof T] as string).getTime() as number;
    if (direction === "asc") return next - prev;
    else return prev - next;
  });
};

const convertToBoolean = (data: any, dataSample: any) =>
  data === dataSample ? true : false;

export const sortAsBoolean = <T extends {}>(
  arr: T[],
  objField: string,
  direction: TableSortDirection,
  dataSample: any
) => {
  return arr.sort((a, b) => {
    let prev = a[objField as keyof T] as boolean;
    let next = b[objField as keyof T] as boolean;
    if (dataSample) {
      prev = convertToBoolean(a[objField as keyof T], dataSample);
      next = convertToBoolean(b[objField as keyof T], dataSample);
    }
    if (direction === "desc") return prev === next ? 0 : prev ? -1 : 1;
    else return prev === next ? 0 : prev ? 1 : -1;
  });
};
