import {
  SortingDataType,
  TableSortDirection,
} from "../../../common/logic/TableSortUtils";
import { Box } from "@mui/material";
import TableColumnHeader from "../../../components/common/table/TableColumnHeader";
import BorderRow from "../../../components/common/table/BorderRow";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { sortBills } from "../../../store/reducers/billsReducer";

const BillsTableHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOnTableSort = (
    dataField: string,
    direction: TableSortDirection,
    dataType: SortingDataType
  ) => {
    dispatch(sortBills({ dataField, dataType, direction }));
  };
  return (
    <Box
      sx={(theme) => ({
        display: "contents",
        "& > div": {
          height: "60px",
        },
      })}
    >
      <TableColumnHeader
        dataField="name"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.name")}
        firstCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="invoiceNumber"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceNumber")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="date"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceDate")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="type"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.billType")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="paymentMethod"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.paymentMethod")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="status"
        dataType="string"
        isSorted={true}
        label={t("tableColumnHeaders.status")}
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="analysis"
        dataType="string"
        isSorted={false}
        label={t("tableColumnHeaders.analysis")}
        contentPosition="center"
        handleOnTableSort={handleOnTableSort}
      />
      <TableColumnHeader
        dataField="invoice"
        dataType="string"
        isSorted={false}
        label={t("tableColumnHeaders.invoice")}
        contentPosition="center"
        lastCol={true}
        handleOnTableSort={handleOnTableSort}
      />
      <BorderRow />
    </Box>
  );
};

export default BillsTableHeader;
