import GroupsTableRow from "./GroupsTableRow/GroupsTableRow";
import AddOrEditGroupForm from "./GroupsTableRow/GroupFormComponents/AddOrEditGroupForm";
import {
  useGroupsData,
  useOnNewGroupAdd,
  usePagination,
} from "../../../../store/selectors/groupSelectors";
import { GroupItem } from "../../../../types/groupItem";
import { useTableDataToRender } from "../../../../hooks/UseTableDataToRender";
import { setPagination } from "../../../../store/reducers/groupsReducer";
import { useTableDataPaginated } from "../../../../hooks/UseTablePagination";
import Pagination from "../../../../components/common/table/Pagination";
import { Box } from "@mui/material";

const GroupsTableBody = (props: { searchQuery: string }) => {
  const { searchQuery } = props;
  const isNewGroupAdd = useOnNewGroupAdd();
  const tableData = useGroupsData();
  const { totalPages, itemsPerPage, currentPage } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    "name"
  );

  return (
    <>
      {isNewGroupAdd && <AddOrEditGroupForm isNewGroupAdd={isNewGroupAdd} />}
      {tableDataToRender &&
        tableDataToRender.map((item: GroupItem) => (
          <GroupsTableRow key={item.id} {...item} />
        ))}
      <Box sx={{ gridColumn: "1/7" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default GroupsTableBody;
