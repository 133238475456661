import { Outlet } from "react-router-dom";
import { styled } from "@mui/material";
import { useState } from "react";
import { TopNav } from "./TopNav";
import { SideNav } from "./SideNav";
import { LayoutBox, LayoutWithNavBarStyles } from "./Layout.styles";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export function LayoutsWithNavbar() {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <LayoutWithNavBarStyles>
      <TopNav onNavOpen={() => setNavOpen(true)} />
      <SideNav onClose={() => setNavOpen(false)} open={navOpen} />
      <LayoutRoot>
        <LayoutContainer>
          <LayoutBox>
            <Outlet />
          </LayoutBox>
        </LayoutContainer>
      </LayoutRoot>
    </LayoutWithNavBarStyles>
  );
}
