import { Button, Typography } from "@mui/material";
import { FC } from "react";

interface CancelBtnProps {
  title: string;
  handleOnClick: () => void;
}

const CancelBtn: FC<CancelBtnProps> = ({ title, handleOnClick }) => {
  return (
    <Button
      onClick={handleOnClick}
      sx={(theme) => ({
        fontSize: "1.25rem",
        lineHeight: "1.2",
        fontWeight: "700",
        height: "auto",
        textAlign: "center",
        textTransform: "none",
        width: "fit-content",
        padding: " 2px 5px",
        borderRadius: "5px",
        border: `1px solid ${theme.palette.companyMain.main}`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "140px",
        backgroundColor: theme.palette.backgroundColor.main,
        color: theme.palette.companyMain.main,
        "&:hover": {
          backgroundColor: theme.palette.companyMain.hover,
          color: theme.palette.backgroundColor.main,
        },
        ".MuiTypography-root": {
          width: "100%",
          maxWidth: "100%!important",
        },
      })}
    >
      <Typography>{title}</Typography>
    </Button>
  );
};

export default CancelBtn;
