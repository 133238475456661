import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSearch } from "../../../hooks/UseSearch";
import { useBillsData, useBillsEditMode, useNewBillMode } from "../../../store/selectors/billsSelectors";
import BillsTable from "./BillsTable";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import EditBtn from "../../../components/common/table/EditBtn";
import SaveBtn from "../../../components/common/table/SaveBtn";
import { setBillsEditMode, setNewBillMode } from "../../../store/reducers/billsReducer";
import { fetchSuperAdminBillingsThunk, editSuperAdminBillingsThunk } from "../../../store/thunk/superAdminBillingsThunk";
import SearchWithAutocomplete from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";
import { t } from "i18next";
import NewBtn from "../../../components/common/table/NewBtn";

interface CreateBillButtonProps {
  isNewGroupAdd: boolean;
  onNewGroupHandler: () => void;
  adding?: boolean
}

const CreateGroupButton: FC<CreateBillButtonProps> = ({
  isNewGroupAdd,
  onNewGroupHandler,
  adding = true
}) => {
  const editMode = useBillsEditMode();
  if (editMode) return null;
  return (
    <NewBtn
      handleOnClick={onNewGroupHandler}
      isActive={isNewGroupAdd}
      hoverText={adding ? t("toolTip.addNewItem") : t("toolTip.cancelNewItem")}
      adding={adding}
    />
  );
};

const BillingPage = () => {
  const { t } = useTranslation();
  const isEditMode = useBillsEditMode();
  const { searchQuery, handleOnSearch } = useSearch();
  const dispatch = useAppDispatch();
  const bills = useBillsData();
  const newBillMode = useNewBillMode()

  useEffect(() => {
    dispatch(fetchSuperAdminBillingsThunk())
  }, [isEditMode]);

  const billsOptions = bills
    ? bills.map((bill) => ({
      key: bill.id,
      label: bill.invoiceNumber,
    }))
    : [];

  const handleOnSave = async () => {
    dispatch(setProcessingRequest(true))
    let res = await dispatch(editSuperAdminBillingsThunk());
    dispatch(setResponseValue(res.type))
    dispatch(setResponseMessage(`${t("dialogModals.itemsSaved")}`))
  };

  return (
    <>
      <Box
        sx={{
          height: "auto",
          backgroundColor: (theme) => theme.palette.backgroundColor.main,
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px 39px",
            borderBottom: `1px solid ${theme.palette.border.main}`,
            marginBottom: "25px",
          })}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <SearchWithAutocomplete
              options={billsOptions}
              onSearchHandler={handleOnSearch}
              sx={{
                maxWidth: "499px",
                ".MuiAutocomplete-root": {
                  width: "100%",
                },
                ".search-btn": {
                  display: "none",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CreateGroupButton
              isNewGroupAdd={newBillMode}
              onNewGroupHandler={() => dispatch(setNewBillMode(!newBillMode))}
              adding={!newBillMode}
            />
            {!newBillMode &&
              (!isEditMode ? (
                <EditBtn
                  handleOnClick={() => dispatch(setBillsEditMode(true))}
                />
              ) : (
                <SaveBtn
                  handleOnClick={handleOnSave}
                />
              ))}
          </Box>
        </Box>
        <BillsTable searchQuery={searchQuery} />
      </Box>
    </>
  );
};

export default BillingPage;
