import { Box, Dialog, Theme, styled } from "@mui/material";
import { theme } from "../../theme";

export const Container = styled(Box)(
  ({
    vPosition = "center",
    hPosition = "flex-start",
    margin = "0",
  }: {
    vPosition?: string;
    hPosition?: string;
    margin?: string;
  }) => ({
    display: "flex",
    margin: margin,
    alignItems: vPosition,
    justifyContent: hPosition,
    width: "100%",
  })
);

export const MainContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  overflowX: "auto",
  background: theme.palette.backgroundColor.main,
}));

export const TableWrapper = styled(Box)(
  ({
    minWidth,
    maxWidth,
    columnsTemplate,
  }: {
    minWidth?: string;
    maxWidth?: string;
    columnsTemplate: string;
  }) => ({
    minWidth: minWidth,
    maxWidth: "100%",
    display: "grid",
    columnGap: "10px",
    gridTemplateColumns: columnsTemplate,
    gridTemplateRows: "auto",
  })
);

export const TableHeaderWrapper = styled(Box)({
  display: "contents",
  "& > div": {
    height: "60px",
  },
});

export const firstColumnStyles = (theme: Theme) => {
  return {
    paddingLeft: "25px!important",
  };
};

export const lastColumnStyles = (theme: Theme) => {
  return {
    padding: "0 25px 0 0!important",
    "& > div": { justifyContent: "flex-end" },
  };
};

export const TableColumnHeaderStyles = styled(Box)(
  ({
    firstCol,
    lastCol,
    contentPosition,
  }: {
    firstCol?: boolean;
    lastCol?: boolean;
    contentPosition?: string;
  }) => ({
    ...(firstCol && firstColumnStyles(theme)),
    ...(lastCol && lastColumnStyles(theme)),
    boxSizing: "border-box",
    "& > .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: contentPosition ? contentPosition : "flex-start",
      height: "100%",
      width: "100%",
      color: theme.palette.textMain.main,
      ".MuiTypography-root": {
        fontWeight: "600",
        maxWidth: "calc(100% - 30px)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  })
);

export const SortIconStyles = styled(Box)(({ theme }) => ({
  marginLeft: "5px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  button: {
    minWidth: "0",
    padding: "0",
    background: "none",
    height: "12px",
    width: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  svg: {
    fill: theme.palette.textMain.main,
  },
  'svg:hover': {
    fill: theme.palette.primary.main,
    transition: "fill 100ms linear",
  }
}));

export const BasicTableRowStyles = styled(Box)({
  display: "contents",
  "& > div": {
    height: "50px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  '&:hover': {
    color: theme.palette.border.subform,
    transition: "color 100ms linear",
    backgroundColor: "red",
    "& > div": {
      backgroundColor: theme.palette.backgroundColor.secondary,
      transition: "background-color 200ms linear"
    },
  },
});

export const PaginationBtnsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:last-child": {
    marginRight: "0",
  },
  button: {
    border: "none!important",
    background: "none!impotant",
    padding: "0 5px 2px!important",
    width: "auto!important",
    height: "24px!important",
    cursor: "pointer",
  },
  svg: {
    marginLeft: "0!important",
    width: "24px!important",
  },
});

export const pageBtnsSeparator = (
  <Box
    sx={{
      padding: "16px",
      position: "relative",
      span: {
        position: "absolute",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        lineHeight: "10px",
      },
    }}
  >
    <span>...</span>
  </Box>
);

export const PaginationStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "20px 35px",
  [theme.breakpoints.down(1700)]: {
    "&": {
      padding: "20px 25px!important",
    },
  },
  "& > .MuiBox-root": {
    marginRight: "25px",
    "& > button": {
      background: "none",
      padding: "0px 10px",
      textAlign: "center",
      width: "80px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: "inherit",
      border: `1px solid ${theme.palette.textMain.secondary}`,
      svg: {
        marginLeft: "10px",
        width: "10px",
      },
    },
  },
}));

export const PageBtnStyles = styled(Box)(
  ({ isActive }: { isActive: boolean }) => ({
    button: {
      width: "fit-content!important",
      fontFamily: "inherit",
      fontWeight: isActive ? "700" : "400",
      background: "none",
      border: isActive ? `1px solid ${theme.palette.border.secondary}` : "none",
    },
  })
);

export const PaginationItemsPopoverStyles = styled(Box)({
  width: "80px",
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  boxSizing: "border-box",
  cursor: "pointer",
  button: {
    fontFamily: "inherit",
    cursor: "pointer",
    border: "none",
    background: "none",
  },
});

export const CheckboxMuiStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "6px",
  ".MuiCheckbox-root": {
    padding: "0",
    color: theme.palette.textMain.black,
    "&.Mui-checked": {
      color: theme.palette.textMain.black,
    },
  },
}));

export const SimpleInputStyles = styled(Box)(({ theme }) => ({
  input: {
    height: "28px",
    borderRadius: "5px",
    background: "none",
    width: "100%",
    boxSizing: "border-box",
    padding: "4px 5px",
    border: `1px solid ${theme.palette.border.main}`,
    outline: "none",
  },
}));

export const BasicSelectUI = styled(Box)(({ theme }) => ({
  position: "relative",
  minWidth: "180px",
  border: `1px solid ${theme.palette.textMain.main}`,
  background: theme.palette.backgroundColor.main,
  ".MuiInputBase-root": {
    width: "100%",
    borderRadius: "2px",
    height: "24px",
    "& > div": {
      padding: "0 20px 0 5px !important",
    },
  },
  fieldset: {
    border: "none",
  },
  svg: {
    right: "0",
    width: "20px",
    height: "20px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  "&:before": {
    content: "''",
    position: "absolute",
    top: "0",
    right: "20px",
    height: "100%",
    width: "0!important",
    borderRight: `1px solid ${theme.palette.textMain.main}`,
  },
}));

export const BasicSelectLightUI = styled(BasicSelectUI)(
  ({ width, minWidth }: { width?: string; minWidth?: string }) => ({
    border: `1px solid ${theme.palette.border.main}`,
    minWidth: minWidth ? minWidth : "100px",
    width: width,
    "&:before": {
      borderRight: `1px solid ${theme.palette.border.main}`,
    },
  })
);

export const BasicTimePicker = styled(Box)(({ theme }) => ({
  background: theme.palette.backgroundColor.main,
  position: "relative",
  minWidth: "90px",
  border: `1px solid ${theme.palette.textMain.main}`,
  borderRadius: "2px",
  fieldset: {
    border: "none",
  },
  input: {
    height: "24px",
    padding: "0 0 0 5px",
    fontSize: "0.9rem",
  },
  ".MuiInputBase-root": {
    paddingRight: "12px",
  },
  ".MuiButtonBase-root": {
    padding: "0",
    span: {
      display: "none",
    },
  },
  "&:before": {
    content: "''",
    position: "absolute",
    top: "0",
    right: "20px",
    height: "100%",
    width: "0!important",
    borderRight: `1px solid ${theme.palette.textMain.main}`,
  },
  svg: {
    width: "20px",
    height: "20px",
  },
}));

export const BasicCheckboxStyles = styled(Box)(() => ({
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    cursor: "pointer",
    span: {
      width: "16px",
      height: "16px",
      border: `1px solid ${theme.palette.textMain.main}`,
      marginRight: "3px",
    },
  },
  input: {
    visibility: "hidden",
    width: "0",
    height: "0",
    margin: "0",
    "&:checked + span": {
      backgroundColor: theme.palette.textMain.main,
    },
  },
}));

export const BasicRadioButtonStyles = styled(Box)(({ theme }) => ({
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    cursor: "pointer",
    span: {
      padding: "8px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.textMain.main}`,
      marginRight: "3px",
    },
  },
  input: {
    visibility: "hidden",
    width: "0!important",
    height: "0!important",
    margin: "0!important",
    "&:checked + span": {
      backgroundColor: theme.palette.textMain.main,
    },
  },
}));

export const SimpleSearchBasicStyles = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  "& > .MuiFormControl-root": {
    width: "100%",
  },
  "& > .MuiFormControl-root > .MuiInputBase-root": {
    width: "100%",
    "& > input": {
      height: "auto",
      padding: "9px 47px 9px 14px",
    },
  },
  "& > .MuiBox-root": {
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translateY(-50%)",
    width: "41px",
    height: "41px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1",
  },
}));

export const AlertDialogStyled = styled(Dialog)(({ theme }) => ({
  ".MuiPaper-root": { padding: "35px" },
  ".MuiTypography-root": {
    textAlign: "center",
  },
  ".MuiButton-root": {
    width: "fit-content",
    padding: "6px 35px",
    margin: "35px auto 0",
    color: theme.palette.backgroundColor.main,
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.companyMain.main}`,
    background: theme.palette.companyMain.main,
    "&:hover": {
      color: theme.palette.companyMain.main,
      background: theme.palette.backgroundColor.main,
    },
  },
}));
