import { Typography } from "@mui/material";
import { FC } from "react";
import CompanyTableRow from "./CompanyTableRow";
import { useTranslation } from "react-i18next";
import { CompanyManagementType } from "../../types/companyManagementTypes";
import {
  CompanyManagementTableStyles,
  CompanyTableTitle,
  EmptyRow,
  InvalidBillingAdressRow,
} from "./CompanyManagement.styles";
interface CompanyInfoTableProps {
  title: string;
  companyTableInfo: CompanyManagementType;
  displayCanNotBeProcessed: boolean;
}

const CompanyInfoTable: FC<CompanyInfoTableProps> = ({
  title,
  companyTableInfo,
  displayCanNotBeProcessed,
}) => {
  const { t } = useTranslation();
  const { name, address } = companyTableInfo;
  const {
    street,
    extraAddressLine,
    postalCode,
    town,
    number,
    country,
    canBeProcessed,
  } = address;
  return (
    <CompanyManagementTableStyles>
      <CompanyTableTitle> {title}</CompanyTableTitle>
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.company")}
        info={name}
      />
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.streetNo")}
        info={street + ", " + number}
      />
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.extraAddressLine")}
        info={extraAddressLine}
      />
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.postalCode")}
        info={postalCode}
      />
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.city")}
        info={town}
      />
      <CompanyTableRow
        title={t("companyManagementPage.companyInfoTable.country")}
        info={country}
      />
      {(canBeProcessed && displayCanNotBeProcessed) ||
      !displayCanNotBeProcessed ? (
        <EmptyRow />
      ) : (
        <InvalidBillingAdressRow>
          <Typography>
            {t(
              "companyManagementPage.companyInfoTable.isBillingAddressProcessed"
            )}
          </Typography>
          <Typography>
            {t("companyManagementPage.companyInfoTable.contactSupport")}{" "}
            <a href="mailto:support@bonisoft.de">support@bonisoft.de</a> .
          </Typography>
        </InvalidBillingAdressRow>
      )}
    </CompanyManagementTableStyles>
  );
};

export default CompanyInfoTable;
