import { useSearch } from "../../../hooks/UseSearch";
import ActionsBlockSearchBar from "./ActionsBlockSearchBar";
import { ActionsBlockContainer } from "../ActionsBlock.styles";
import ActionCards from "./ActionCards";

const ActionsBlock = () => {
  const { searchQuery, handleOnSearch } = useSearch();
  return (
    <ActionsBlockContainer>
      <ActionsBlockSearchBar
        searchQuery={searchQuery}
        handleOnSearch={handleOnSearch}
      />
      <ActionCards searchQuery={searchQuery} />
    </ActionsBlockContainer>
  );
};

export default ActionsBlock;
