import { FC } from "react";
import { ActionFormWrapperStyles } from "../ActionModuleCard.styles";

interface ActionFormWrapperProps {
  label: string;
  children: JSX.Element;
}

const ActionFormWrapper: FC<ActionFormWrapperProps> = ({ label, children }) => {
  return (
    <ActionFormWrapperStyles>
      <label htmlFor="">{label}</label>
      {children}
    </ActionFormWrapperStyles>
  );
};

export default ActionFormWrapper;
