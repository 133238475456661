import { Box, styled } from "@mui/material";
import { theme } from "../../theme";
import { BasicTableRowStyles } from "../../components/common/Common.styles";

export const BillingContentWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));

const activeSettingsStyles = (isActive: boolean) => {
  if (isActive) {
    return {
      position: "absolute",
      top: "0",
      left: "0",
      width: "386px",
      height: "279px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.backgroundColor.main,
      padding: "0px 40px 37px 0px",
      div: {
        height: "auto",
      },
    };
  } else {
    return {};
  }
};

export const SettingsWrapperStyles = styled(Box)(
  ({ isSettingsActive }: { isSettingsActive: boolean }) => ({
    marginLeft: "26px",
    width: "140px",
    height: "31px",
    position: "relative",
    zIndex: "100",
    "& > div": {
      width: "100%",
      height: "100%",
      fontSize: "18px",
      borderRadius: "2px",
      overflow: "hidden",
      border: `1px solid ${theme.palette.textMain.main}`,
      ...activeSettingsStyles(isSettingsActive),
    },
  })
);

export const SettingsBoxStyles = styled(Box)({
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 9px",
  width: "140px",
  height: "31px",
});

export const CheckboxesWrapperStyles = styled(Box)({
  margin: "9px 0 0 9px",
  "& > .MuiTypography-root": {
    marginTop: "25px",
  },
});

export const InputFieldsBoxStyles = styled(Box)({
  height: "auto!important",
  maxHeight: "61px",
  marginBottom: "5px",
  overflowY: "auto",
  ".MuiBox-root": {
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});

export const AddEmailBtnStyles = styled(Box)(({ theme }) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "28px",
    borderRadius: "5px",
    background: "none",
    width: "100%",
    cursor: "pointer",
    color: theme.palette.border.main,
    border: `1px solid ${theme.palette.border.main}`,
  },
}));

export const BillingPageRowStyles = styled(BasicTableRowStyles)({
  button: {
    border: "none",
    outline: "none",
    padding: "none",
    background: "none",
    cursor: "pointer",
    svg: {
      width: "30px",
      height: "30px",
    },
  },
});
