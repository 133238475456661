import { useState } from 'react'
import { ColorBox, CurrentColorDisplay, CurrentColorPick } from './AppDesign.styles'
import { useTranslation } from 'react-i18next';
import { useAppDesignAccentColor, useConfigEditMode } from '../../../store/selectors/configurationSelector';
import { SketchPicker } from 'react-color';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch } from '../../../hooks/StoreHooks';
import { setAppDesignAccentColor } from '../../../store/reducers/configurationReducer';

const AppDesignCompanyColor = () => {
    const { t } = useTranslation();
    let currentCompanyColor = useAppDesignAccentColor()
    let editMode = useConfigEditMode()
    const dispatch = useAppDispatch();
    const [accentColor, setAccentColor] = useState(currentCompanyColor);
    const [displaySketchPicker, setDisplaySketchPicker] = useState(false)

    const handleColorPick = () => {
        dispatch(setAppDesignAccentColor(accentColor))
        setDisplaySketchPicker(false)
    }

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant='h5'>
                {t("configurationPage.companyColor")}
            </Typography>
            <CurrentColorPick>
                {currentCompanyColor ?
                    <CurrentColorDisplay>
                        <ColorBox pickedColor={currentCompanyColor} />
                        <Box>{currentCompanyColor}</Box>
                    </CurrentColorDisplay>
                    :
                    <Box>{t("configurationPage.noColorPicked")}</Box>
                }
            </CurrentColorPick>
            {
                editMode &&
                <div>
                    <Button onClick={() => setDisplaySketchPicker(true)} variant='outlined'>{t("configurationPage.pickColor")}
                    </Button>
                    {displaySketchPicker ? <Box sx={{...popover}}>
                        <Box sx={{...cover}} onClick={() => handleColorPick()} />
                        <SketchPicker
                            color={accentColor ?? undefined}
                            onChange={(c) => setAccentColor(c.hex)}
                        />
                    </Box> : null}
                </div>


            }
        </Box>
    )
}

export default AppDesignCompanyColor