import { FC } from "react";
import BillsTableHeader from "./BillsTableHeader";
import BillsTableBody from "./BillsTableBody";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../components/common/Common.styles";

const BillsTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1330px"
        columnsTemplate="1.25fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr"
      >
        <BillsTableHeader />
        <BillsTableBody searchQuery={searchQuery} />
      </TableWrapper>
    </MainContentWrapper>
  );
};

export default BillsTable;
