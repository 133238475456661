import { Button, ListItemIcon, ListItemText, styled } from "@mui/material";
import { theme } from "../../theme";

export const AuthButton = styled(Button)({
  color: "#fff",
  fontWeight: "600",
  backgroundColor: theme.palette.primary.main
});

export const ItemIconPasswordCheck = styled(ListItemIcon)({
  minWidth: "20px",
  svg: {
    width: "14px",
    height: "14px",
  },
})

export const ItemTextPasswordCheck = styled(ListItemText)({
  lineHeight: "1",
  margin: "0",
  span: {
    fontSize: "small"
  }
})