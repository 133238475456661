import React, { FC } from "react";
import { BasicCheckboxStyles } from "./Common.styles";

interface CheckboxProps {
  label: string;
  isChecked: boolean;
  handleOnClick: () => void;
}

const Checkbox: FC<CheckboxProps> = ({ label, isChecked, handleOnClick }) => {
  return (
    <BasicCheckboxStyles>
      <label htmlFor={label} onClick={handleOnClick}>
        <input
          type="checkbox"
          name={label}
          value={label}
          onChange={() => {}}
          checked={isChecked}
        />
        <span></span>
        {label}
      </label>
    </BasicCheckboxStyles>
  );
};

export default Checkbox;
