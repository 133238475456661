import { InputAdornment, SxProps, TextField } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../../assets/icons/ActionIcons";
import { SearchBox } from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import debouce from "lodash.debounce";


interface Props {
    sx?: SxProps;
    onSearchHandler: (searchQuery: string) => void;
}

/**
 * A component which displays a search bar which can be used for server side searching. The onSearchHandler callback is
 * debounced and will fire at most every 500ms (to reduce server load and network usage).
 */
export default function ServerSideSearchBar(props: Props) {
    const { t } = useTranslation();

    const debouncedResults = useMemo(() => {
        return debouce((s) => props.onSearchHandler(s), 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <SearchBox sx={props.sx}>
            <TextField
                onChange={(v) => debouncedResults(v.target.value)}
                placeholder={t("search") + "..."}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </SearchBox>
    );
};
