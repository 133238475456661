import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import DashboardContent from "./DashboardContent";
import { Typography } from "@mui/material";

const Dashboard = () => {
  const breadcrumbs = [
    {
      title: "dashboardPage.generalView",
      link: "",
    },
  ];

  const { t } = useTranslation();

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={t("dashboardPage.title")} />
      {/* PLS COMMENT OUT THE NEXT LINE AFTER TESTING */}
      {/* <DashboardContent /> */}
      <Typography>
        Analysetools werden zum späteren Zeitpunkt angezeigt.
      </Typography>
    </>
  );
};

export default Dashboard;
