import { Box } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocale } from "../../../hooks/UseLocale";
import { MainTitle, Subtitle } from "./PageTitle.styles";
import { SidebarLinkPathTypes, SidebarLinkTypes } from "../../../scaffold/ui/LinkTypes";

interface PageTitleProps {
  title: string;
  breadcrumbs?: {
    title: string;
    link: string;
  }[];
}

const PageTitle: FC<PageTitleProps> = ({ breadcrumbs, title }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  if (breadcrumbs && breadcrumbs[1] && breadcrumbs[1].title === SidebarLinkTypes.incentivationTaxfreebenefit) {
    switch (breadcrumbs[2].link) {
      case SidebarLinkPathTypes.efficientDriving:
        breadcrumbs[2].title = SidebarLinkTypes.efficientDriving
        break
      case SidebarLinkPathTypes.officeIncentive:
        breadcrumbs[2].title = SidebarLinkTypes.officeIncentive
        break
      case SidebarLinkPathTypes.salesLeads:
        breadcrumbs[2].title = SidebarLinkTypes.salesLeads
        break
      case SidebarLinkPathTypes.signatureControl:
        breadcrumbs[2].title = SidebarLinkTypes.signatureControl
        break
      case SidebarLinkPathTypes.monthlyTaxFreeIncentive:
        breadcrumbs[2].title = SidebarLinkTypes.monthlyTaxFreeIncentive
        break
      default:
        breadcrumbs[2].title = ""
    }
  }

  return (
    <>
      <MainTitle>{title}</MainTitle>
      <Subtitle>
        {
          breadcrumbs
            ?
            breadcrumbs.map((item) =>
              item.link ? (
                <Link key={item.title} to={`/${locale}/${item.link}`}>
                  {"> "}
                  {t(item.title)}
                </Link>
              ) : (
                <span key={item.title}>{t(item.title)}</span>
              )
            )
            :
            <Box sx={{ height: "36px" }}></Box>
        }
      </Subtitle>
    </>
  );
};

export default PageTitle;
