import { BillItem } from "../types/bills";
import { doApiGet } from "./Requests";

export const companyBillingsApi = {
    getCompanyBillings: (): Promise<BillItem[] | null> =>
    doApiGet("/api/companies/current/billings")
      .then(res => res.result as BillItem[])
      .catch((error) => {
        console.log(error);
        return null
      }),
}