import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import AccountManagementSearchBar from "./AccountManagementSearchBar";
import AccountManagementTable from "./AccountManagementTable/AccountManagementTable";
import { useSearch } from "../../hooks/UseSearch";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { useEffect, useState } from "react";
import { fetchAccountManagementUsersThunk } from "../../store/thunk/accountManagementThunk";
import { AccountManagementWrapper } from "./AccountManagement.styles";
import { useProcessingRequest, useResponseMessage, useResponseValue } from "../../store/selectors/tableManagementSelector";
import { ModalComponent } from "../../components/common/ModalComponent";
import { Box } from "@mui/material";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { setProcessingRequest, setResponseValue } from "../../store/reducers/tableManagementReducer";

const AccountManagement = () => {
  const { t } = useTranslation();
  const { searchQuery, handleOnSearch } = useSearch();
  const dispatch = useAppDispatch();
  const processingRequest = useProcessingRequest()
  const responseValue = useResponseValue()
  const responseMessage = useResponseMessage()

  const [successModal, setSuccessModal] = useState<boolean>(false)

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchAccountManagementUsersThunk());
  }, []);

  useEffect(() => {
    if (processingRequest === false && responseValue.includes("fulfilled")) {
      setSuccessModal(true)
      dispatch(setResponseValue(""))
    }
  }, [processingRequest]);
  
  const closeModal = () => {
    setSuccessModal(false)
  }

  return (
    <>
      <PageTitle
        title={t("accountManagementPage.title")}
      />
      <AccountManagementWrapper>
        <AccountManagementSearchBar handleOnSearch={handleOnSearch} />
        <AccountManagementTable searchQuery={searchQuery} />
      </AccountManagementWrapper>

      <CircularIndeterminate processingRequest={processingRequest} />
      <ModalComponent isOpen={successModal} onClose={closeModal}>
        <Box>{responseMessage}</Box>
      </ModalComponent>
    </>
  );
};

export default AccountManagement;
