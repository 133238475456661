import { FC } from "react";
import ActionButton from "./ActionButton";
import { Popover, Tooltip } from "@mui/material";
import { usePopover } from "../../../hooks/UsePopover";
import {
  ActionBtnsListStyles,
  ActionsPopupBoxStyles,
  MoreActionsBtnStyles,
} from "../../../pages/groups/GroupsBlock/GroupsBlock.styles";

interface ActionButtonsListProps {
  isEditMode?: boolean;
  groupActions: any[];
  handleActionButtonDelete?: (actionId: number) => void;
}

const ActionButtonsList: FC<ActionButtonsListProps> = ({
  isEditMode,
  groupActions,
  handleActionButtonDelete,
}) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover();

  const maxActionsToRender = isEditMode ? 3 : 4;

  const actions = groupActions.map((item: any) => (
    <Tooltip title={item.name}>
      <ActionButton
        key={item.id}
        actionName={item.name}
        iconName={item.iconName}
        colorHex={item.colorHex}
        isEditMode={isEditMode}
        handleActionButtonDelete={() => handleActionButtonDelete && handleActionButtonDelete(item.id)}
      />
    </Tooltip>
  ));

  const actionsToPreview = actions.slice(0, maxActionsToRender);

  return (
    <ActionBtnsListStyles ref={anchorRef}>
      {actionsToPreview}
      {groupActions.length > maxActionsToRender && (
        <MoreActionsBtnStyles onClick={handleOpen}>
          <span></span>
          <span></span>
          <span></span>
        </MoreActionsBtnStyles>
      )}
      <Popover
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        anchorEl={anchorRef.current}
        open={open}
        onClick={handleClose}
      >
        <ActionsPopupBoxStyles>{actions}</ActionsPopupBoxStyles>
      </Popover>
    </ActionBtnsListStyles>
  );
};

export default ActionButtonsList;
