import { Box, styled } from "@mui/material";

export const SearchWithAutocompleteWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "30px 25px",
  borderBottom: `1px solid ${theme.palette.border.main}`,
  marginBottom: "25px",
}));

export const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  maxWidth: "595px",
  ".MuiAutocomplete-root": {
    width: "100%",
    ".MuiFormControl-root": {
      padding: "0",
    },
    ".MuiInputBase-root": {
      padding: "7px 15px",
      fontSize: "1.375rem",
      fontWeight: "600",
      lineHeight: "1.3",
      borderLeft: "0",
      "& fieldset": {
        borderRadius: "5px",
        border: `1px solid ${theme.palette.border?.main}`,
      },
      input: {
        fontWeight: "400",
        fontSize: "20px",
        color: theme.palette.textMain.main,
        "&::-webkit-input-placeholder": {
          color: theme.palette.textMain.main,
        },
      },
    },
    ".MuiAutocomplete-input": {
      padding: "0!important",
    },
  },
}));
