import { NotificationWithPagination } from "../types/notificationType";
import { doApiGet, doApiPatch } from "./Requests";

export const notificationApi = {
    getUnreadNotificationAmount: (): Promise<number | void> =>
        doApiGet(`/api/users/current/notifications/count`)
            .then(res => res.result as number)
            .catch(error => console.log(error)),

    getNotificationList: (offset: number, limit: number): Promise<NotificationWithPagination | void> =>
        doApiGet(`/api/users/current/notifications?offset=${offset}&limit=${limit}`)
            .then(res => res.result as NotificationWithPagination)
            .catch(error => console.log(error)),

    editNotificationVisibility: (id: number, status: string) =>
        doApiPatch(`/api/users/current/notifications/${id}/status`, status)
            .then(res => res.result)
            .catch(error => console.log(error)),
}