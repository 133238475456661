import { FC, useEffect } from "react";
import CertificatesTableHeader from "./CertificatesTableHeader";
import CertificatesTableBody from "./CertificatesTableBody";
import { MainContentWrapper, TableWrapper } from "../../../components/common/Common.styles";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchActionModuleManagementUsers } from "../../../store/thunk/actionModuleManagementThunk";

const CertificatesTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchActionModuleManagementUsers());
  }, []);
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1330px"
        columnsTemplate="1.25fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr"
      >
        <CertificatesTableHeader />
        <CertificatesTableBody searchQuery={searchQuery} />
      </TableWrapper>
    </MainContentWrapper>
  );
};

export default CertificatesTable;
