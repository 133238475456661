import { useState } from "react";

export const useSearch = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleOnSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  return {
    searchQuery,
    handleOnSearch,
  };
};
