export type PaginationType = {
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
};

export type DialogPropsType = {
  handleDialogClose: () => void;
  handleDialogOpen: () => void;
  open: boolean;
};

export enum StatusTypes {
  Active = "Active",
  Blocked = "Blocked",
}