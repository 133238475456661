import { Select, SelectChangeEvent } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { RecurrencySubformProps, YearlyRecurrenceType } from "./RecurrenceForm";
import { validateDataField } from "../../utils";
import RadioButton from "../../../../components/common/RadioButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  useMonthListItems,
  useDayItems,
  useDayNumberItems,
} from "./DateItemsLists";
import { useTranslation } from "react-i18next";
import {
  ContainerWithWrap,
  MonthNameSelectUI,
  RecurrenceInputBox,
  RecurrenceYearlyFormWrapper,
  RecurrenceYearlyInputStyles,
  SpecificDateRadioBtn,
  SpecificDateSelectUI,
  SpecificDateWrapper,
  SpecificDaySelectUI,
  SpecificMonthDaySelectUI,
} from "../ActionModuleCard.styles";
import { Container } from "../../../../components/common/Common.styles";

enum RadioGroupStateType {
  SpecificDate = "SpecificDate",
  SpecificDayOfMonth = "SpecificDayOfMonth",
}

const RecurrenceYearly: FC<RecurrencySubformProps> = ({
  handleOnSetRecurrenceFormData,
}) => {
  const dayItems = useDayItems();
  const dayNumberItems = useDayNumberItems();
  const monthsListItems = useMonthListItems();

  const { t } = useTranslation();

  const [yearNum, setYearNum] = useState<string>("1");
  const [specificDate, setSpecificDate] = useState<string>("1");
  const [radioGroupState, setRadioGroupState] = useState<RadioGroupStateType>(
    RadioGroupStateType.SpecificDate
  );
  const [error, setError] = useState<{
    specificDateErorr: string;
    yearFieldError: string;
  }>({
    yearFieldError: "",
    specificDateErorr: "",
  });
  const [yearlyRecurrence, setYearlyRecurrence] =
    useState<YearlyRecurrenceType>({
      yearToRecure: 1,
      specificDate: {
        dayNum: 2,
        monthName: "December",
      },
      specificDayOfMonth: {
        dayNum: 3,
        dayName: "Monday",
        monthName: "December",
      },
    });

  useEffect(() => {
    if (radioGroupState === RadioGroupStateType.SpecificDate) {
      handleOnSetRecurrenceFormData({
        yearToRecure: yearlyRecurrence.yearToRecure,
        specificDate: yearlyRecurrence.specificDate,
      });
    } else {
      handleOnSetRecurrenceFormData({
        yearToRecure: yearlyRecurrence.yearToRecure,
        specificDayOfMonth: yearlyRecurrence.specificDayOfMonth,
      });
    }
  }, [yearlyRecurrence]);

  useEffect(() => {
    if (radioGroupState === RadioGroupStateType.SpecificDate) {
      if (!error.specificDateErorr)
        setYearlyRecurrence((state) => ({
          ...state,
          specificDate: { ...state.specificDate, dayNum: Number(specificDate) },
        }));
    } else {
      setYearlyRecurrence((state) => ({ ...state }));
    }
  }, [radioGroupState]);

  const handleYearFieldOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      1000
    );
    if (value === "error")
      setError((state) => ({ ...state, yearFieldError: value }));
    else {
      setYearlyRecurrence((state) => ({
        ...state,
        yearToRecure: value as number,
      }));
    }
  };

  const handleSpecificDateFieldOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      28
    );
    if (value === "error")
      setError((state) => ({ ...state, specificDateErorr: value }));
    else {
      setYearlyRecurrence((state) => ({
        ...state,
        specificDate: {
          ...state.specificDate,
          dayNum: value as number,
        },
      }));
    }
  };

  const handleOnSpecificDateChange = (e: SelectChangeEvent) => {
    setYearlyRecurrence((state) => ({
      ...state,
      specificDate: {
        ...state.specificDate,
        monthName: e.target.value,
      },
    }));
  };

  const handleOnSpecificDayOfMonthChange = (e: SelectChangeEvent) => {
    setYearlyRecurrence((state) => ({
      ...state,
      specificDayOfMonth: {
        ...state.specificDayOfMonth,
        dayNum: Number(e.target.value),
      },
    }));
  };

  const handleOnSpecificDayName = (e: SelectChangeEvent) => {
    setYearlyRecurrence((state) => ({
      ...state,
      specificDayOfMonth: {
        ...state.specificDayOfMonth,
        dayName: e.target.value,
      },
    }));
  };

  const handleOnMonthNameChange = (e: SelectChangeEvent) => {
    setYearlyRecurrence((state) => ({
      ...state,
      specificDayOfMonth: {
        ...state.specificDayOfMonth,
        monthName: e.target.value,
      },
    }));
  };

  return (
    <RecurrenceYearlyFormWrapper>
      <RecurrenceYearlyInputStyles>
        <span>{t("actionPage.recurEvery")}</span>
        <input
          type="text"
          onBlur={handleYearFieldOnBlur}
          onChange={(e) => setYearNum(e.target.value)}
          value={yearNum}
        />
        <span>{t("actionPage.year(s)")}:</span>
      </RecurrenceYearlyInputStyles>
      <Container margin="0 0 15px 0">
        <SpecificDateRadioBtn>
          <RadioButton
            label={t("actionPage.on")}
            handleOnClick={() =>
              setRadioGroupState(RadioGroupStateType.SpecificDate)
            }
            isChecked={radioGroupState === RadioGroupStateType.SpecificDate}
          />{" "}
        </SpecificDateRadioBtn>

        <RecurrenceInputBox error={error.specificDateErorr}>
          <input
            type="text"
            disabled={
              radioGroupState === RadioGroupStateType.SpecificDayOfMonth
            }
            onChange={(e) => setSpecificDate(e.target.value)}
            onBlur={handleSpecificDateFieldOnBlur}
            value={specificDate}
          />
        </RecurrenceInputBox>
        <MonthNameSelectUI>
          <Select
            disabled={
              radioGroupState === RadioGroupStateType.SpecificDayOfMonth
            }
            value={yearlyRecurrence.specificDate.monthName}
            onChange={handleOnSpecificDateChange}
            IconComponent={ExpandMoreIcon}
          >
            {monthsListItems}
          </Select>
        </MonthNameSelectUI>
      </Container>
      <Container vPosition="flex-start">
        <SpecificDateRadioBtn>
          <RadioButton
            label={t("actionPage.onThe")}
            handleOnClick={() =>
              setRadioGroupState(RadioGroupStateType.SpecificDayOfMonth)
            }
            isChecked={
              radioGroupState === RadioGroupStateType.SpecificDayOfMonth
            }
          />{" "}
        </SpecificDateRadioBtn>
        <ContainerWithWrap>
          <SpecificDaySelectUI>
            <Select
              disabled={radioGroupState === RadioGroupStateType.SpecificDate}
              value={String(yearlyRecurrence.specificDayOfMonth.dayNum)}
              onChange={handleOnSpecificDayOfMonthChange}
              IconComponent={ExpandMoreIcon}
            >
              {dayNumberItems}
            </Select>
          </SpecificDaySelectUI>
          <SpecificMonthDaySelectUI>
            <Select
              disabled={radioGroupState === RadioGroupStateType.SpecificDate}
              value={yearlyRecurrence.specificDayOfMonth.dayName}
              onChange={handleOnSpecificDayName}
              IconComponent={ExpandMoreIcon}
            >
              {dayItems}
            </Select>
          </SpecificMonthDaySelectUI>
          <SpecificDateWrapper>
            {" "}
            <span>{t("actionPage.of")}</span>
            <SpecificDateSelectUI>
              <Select
                disabled={radioGroupState === RadioGroupStateType.SpecificDate}
                value={yearlyRecurrence.specificDayOfMonth.monthName}
                onChange={handleOnMonthNameChange}
                IconComponent={ExpandMoreIcon}
              >
                {monthsListItems}
              </Select>
            </SpecificDateSelectUI>
          </SpecificDateWrapper>
        </ContainerWithWrap>
      </Container>
    </RecurrenceYearlyFormWrapper>
  );
};

export default RecurrenceYearly;
