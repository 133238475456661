import { Button } from "@mui/material";
import { FC } from "react";
import { useDialog } from "../../../hooks/UseDialog";
import { DeleteIcon } from "../../../assets/icons/ActionIcons";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { theme } from "../../../theme";

interface DeleteBtnProps {
  isCreateNewMode?: boolean;
  dialogMessage: string;
  isDisabled?: boolean;
  handleOnDelete: () => void;
}

const DeleteBtn: FC<DeleteBtnProps> = ({
  handleOnDelete,
  isCreateNewMode,
  dialogMessage,
  isDisabled,
}) => {
  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={isCreateNewMode ? handleOnDelete : handleDialogOpen}
        sx={{
          padding: "5px",
          width: "fit-content",
          minWidth: "0",
          "&": {
            path: {
              fill: isDisabled ? theme.palette.border.subform : theme.palette.warningColor.main,
            }
          }
        }}
      >
        <DeleteIcon />
      </Button>
      {!isCreateNewMode && (
        <ConfirmDeleteDialog
          open={open}
          dialogMessage={dialogMessage}
          handleOnDelete={handleOnDelete}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default DeleteBtn;
