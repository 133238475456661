import { CertificateUploadData } from "../components/common/table/UploadBtn";
import { CertificateItem } from "../types/certificates";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";

export const certificatesApi = {
  getCertificates: (): Promise<CertificateItem[] | null> =>
    doApiGet("/api/companies/current/documentmanagement")
      .then(res => res.result as CertificateItem[])
      .catch((error) => {
        console.log(error);
        return null
      }),

  editCertificate: (id: number, certificateData: Partial<CertificateItem>) =>
    doApiPatch(`/api/companies/current/documentmanagement/${id}`, certificateData)
      .then(res => res.result)
      .catch(error => console.log(error)),

  createCertificate: (certificateData: Partial<CertificateItem>) =>
    doApiPost("/api/companies/current/documentmanagement", certificateData)
      .then(res => res.result)
      .catch(error => console.log(error)),

  deleteCertificate: (id: number) =>
    doApiDelete(`/api/companies/current/documentmanagement/${id}`),

  uploadCertificateFile: (fileDataToSend: CertificateUploadData) =>
    doApiPost(`/api/companies/current/fileexchange/${fileDataToSend.documentExchangeWalletId}/file`, fileDataToSend.filePayload)
      .then(res => res.result)
      .catch(error => console.log(error)),

  deleteCertificateFile: (documentExchangeWalletId: number, documentId: number) =>
    doApiDelete(`/api/companies/current/fileexchange/${documentExchangeWalletId}/file/${documentId}`)
      .then(res => res.result)
      .catch(error => console.log(error)),
}