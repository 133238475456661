interface AppPreviewProps {
    color: string;
}

export default function AppPreview(props: AppPreviewProps) {
    return <svg width="100%" height="100%" viewBox="0 0 334 682" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}>
        <clipPath id="_clip1">
            <rect x="0" y="0" width="334" height="682" />
        </clipPath>
        <g clip-path="url(#_clip1)">
            <g>
                <rect x="331" y="164" width="3" height="46" style={{ fill: "#a8a8a8" }} />
                <rect x="331" y="257" width="3" height="96" style={{ fill: "#a8a8a8" }} />
                <path d="M322.382,590L322.382,662.088C322.382,669.066 316.726,674.723 309.748,674.723C259.686,674.723 75.418,674.723 22.758,674.723C14.949,674.723 8.618,668.392 8.618,660.582C8.618,636.475 8.618,590 8.618,590L322.382,590Z" style={{ fill: "#f3f3f3" }} />
                <clipPath id="_clip2">
                    <path d="M322.382,590L322.382,662.088C322.382,669.066 316.726,674.723 309.748,674.723C259.686,674.723 75.418,674.723 22.758,674.723C14.949,674.723 8.618,668.392 8.618,660.582C8.618,636.475 8.618,590 8.618,590L322.382,590Z" />
                </clipPath>
                <g clip-path="url(#_clip2)">
                    <rect x="40.5" y="602.008" width="250" height="20" style={{ fill: "none" }} />
                    <clipPath id="_clip3">
                        <rect x="40.5" y="602.008" width="250" height="20" />
                    </clipPath>
                    <g clip-path="url(#_clip3)">
                        <path d="M121.138,622.008C120.038,622.008 119.097,621.616 118.313,620.833C117.53,620.05 117.138,619.108 117.138,618.008L117.138,610.008C117.138,608.908 117.53,607.966 118.313,607.183C119.097,606.4 120.038,606.008 121.138,606.008L133.138,606.008C134.238,606.008 135.18,606.4 135.963,607.183C136.747,607.966 137.138,608.908 137.138,610.008L137.138,618.008C137.138,619.108 136.747,620.05 135.963,620.833C135.18,621.616 134.238,622.008 133.138,622.008L121.138,622.008ZM121.138,610.008L133.138,610.008C133.505,610.008 133.855,610.05 134.188,610.133C134.522,610.216 134.838,610.35 135.138,610.533L135.138,610.008C135.138,609.458 134.943,608.987 134.551,608.595C134.159,608.204 133.688,608.008 133.138,608.008L121.138,608.008C120.588,608.008 120.118,608.204 119.726,608.595C119.334,608.987 119.138,609.458 119.138,610.008L119.138,610.533C119.438,610.35 119.755,610.216 120.088,610.133C120.422,610.05 120.772,610.008 121.138,610.008ZM119.288,613.258L130.413,615.958C130.563,615.991 130.713,615.991 130.863,615.958C131.013,615.925 131.155,615.858 131.288,615.758L134.763,612.858C134.58,612.608 134.347,612.404 134.063,612.245C133.78,612.087 133.472,612.008 133.138,612.008L121.138,612.008C120.705,612.008 120.326,612.12 120.001,612.345C119.676,612.57 119.438,612.875 119.288,613.258Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                        <path d="M199.88,622.008C199.33,622.008 198.859,621.812 198.468,621.42C198.076,621.029 197.88,620.558 197.88,620.008C197.88,619.458 198.076,618.987 198.468,618.595C198.859,618.204 199.33,618.008 199.88,618.008C200.43,618.008 200.901,618.204 201.293,618.595C201.684,618.987 201.88,619.458 201.88,620.008C201.88,620.558 201.684,621.029 201.293,621.42C200.901,621.812 200.43,622.008 199.88,622.008ZM209.88,622.008C209.33,622.008 208.859,621.812 208.468,621.42C208.076,621.029 207.88,620.558 207.88,620.008C207.88,619.458 208.076,618.987 208.468,618.595C208.859,618.204 209.33,618.008 209.88,618.008C210.43,618.008 210.901,618.204 211.293,618.595C211.684,618.987 211.88,619.458 211.88,620.008C211.88,620.558 211.684,621.029 211.293,621.42C210.901,621.812 210.43,622.008 209.88,622.008ZM199.03,606.008L201.43,611.008L208.43,611.008L211.18,606.008L199.03,606.008ZM198.08,604.008L212.83,604.008C213.213,604.008 213.505,604.179 213.705,604.52C213.905,604.862 213.913,605.208 213.73,605.558L210.18,611.958C209.997,612.291 209.751,612.55 209.443,612.733C209.134,612.916 208.797,613.008 208.43,613.008L200.98,613.008L199.88,615.008L211.88,615.008L211.88,617.008L199.88,617.008C199.13,617.008 198.563,616.679 198.18,616.02C197.797,615.362 197.78,614.708 198.13,614.058L199.48,611.608L195.88,604.008L193.88,604.008L193.88,602.008L197.13,602.008L198.08,604.008ZM201.43,611.008L208.43,611.008L201.43,611.008Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                        <path d="M272.603,622.008C272.053,622.008 271.582,621.812 271.191,621.42C270.799,621.029 270.603,620.558 270.603,620.008L270.603,609.008C270.603,608.458 270.799,607.987 271.191,607.595C271.582,607.204 272.053,607.008 272.603,607.008L276.603,607.008L276.603,605.008C276.603,604.458 276.799,603.987 277.191,603.595C277.582,603.204 278.053,603.008 278.603,603.008L282.603,603.008C283.153,603.008 283.624,603.204 284.016,603.595C284.407,603.987 284.603,604.458 284.603,605.008L284.603,607.008L288.603,607.008C289.153,607.008 289.624,607.204 290.016,607.595C290.407,607.987 290.603,608.458 290.603,609.008L290.603,620.008C290.603,620.558 290.407,621.029 290.016,621.42C289.624,621.812 289.153,622.008 288.603,622.008L272.603,622.008ZM272.603,620.008L288.603,620.008L288.603,609.008L272.603,609.008L272.603,620.008ZM278.603,607.008L282.603,607.008L282.603,605.008L278.603,605.008L278.603,607.008ZM272.603,620.008L272.603,609.008L272.603,620.008Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                        <path d="M53.697,616.708L55.097,615.308L51.397,611.608L51.397,607.008L49.397,607.008L49.397,612.408L53.697,616.708ZM50.397,622.008C49.013,622.008 47.713,621.745 46.497,621.22C45.28,620.695 44.222,619.983 43.322,619.083C42.422,618.183 41.709,617.125 41.184,615.908C40.659,614.691 40.397,613.391 40.397,612.008C40.397,610.625 40.659,609.325 41.184,608.108C41.709,606.891 42.422,605.833 43.322,604.933C44.222,604.033 45.28,603.32 46.497,602.795C47.713,602.27 49.013,602.008 50.397,602.008C51.78,602.008 53.08,602.27 54.297,602.795C55.513,603.32 56.572,604.033 57.472,604.933C58.372,605.833 59.084,606.891 59.609,608.108C60.134,609.325 60.397,610.625 60.397,612.008C60.397,613.391 60.134,614.691 59.609,615.908C59.084,617.125 58.372,618.183 57.472,619.083C56.572,619.983 55.513,620.695 54.297,621.22C53.08,621.745 51.78,622.008 50.397,622.008ZM50.397,620.008C52.613,620.008 54.501,619.229 56.059,617.67C57.618,616.112 58.397,614.225 58.397,612.008C58.397,609.791 57.618,607.904 56.059,606.345C54.501,604.787 52.613,604.008 50.397,604.008C48.18,604.008 46.293,604.787 44.734,606.345C43.176,607.904 42.397,609.791 42.397,612.008C42.397,614.225 43.176,616.112 44.734,617.67C46.293,619.229 48.18,620.008 50.397,620.008Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                    </g>
                </g>
                <rect x="217.303" y="62.5" width="24" height="24" style={{ fill: "none" }} />
                <g>
                    <g>
                        <path d="M229.303,75.4L227.173,77.49C226.613,78.05 226.303,78.78 226.303,79.56C226.303,81.18 227.653,82.5 229.303,82.5C230.953,82.5 232.303,81.18 232.303,79.56C232.303,78.78 231.993,78.04 231.433,77.49L229.303,75.4Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                    </g>
                    <g>
                        <path d="M233.303,68.5L232.863,69.05C231.683,70.52 229.303,69.69 229.303,67.8L229.303,64.5C229.303,64.5 221.303,68.5 221.303,75.5C221.303,78.42 222.863,80.97 225.193,82.36C224.633,81.57 224.303,80.6 224.303,79.56C224.303,78.24 224.823,77 225.773,76.06L229.303,72.6L232.833,76.07C233.783,77 234.303,78.24 234.303,79.57C234.303,80.59 233.993,81.53 233.453,82.32C235.343,81.17 236.743,79.26 237.163,77.02C237.823,73.47 236.093,70.12 233.303,68.5Z" style={{ fill: props.color, fillRule: "nonzero" }} />
                    </g>
                </g>
                <path d="M297.041,30.41L300.341,30.41L300.341,27.11L297.041,27.11L297.041,30.41ZM296.491,35.91C296.335,35.91 296.205,35.857 296.099,35.752C295.994,35.647 295.941,35.516 295.941,35.36L295.941,26.56C295.941,26.404 295.994,26.274 296.099,26.168C296.205,26.063 296.335,26.01 296.491,26.01L297.591,26.01L297.591,25.46C297.591,25.304 297.644,25.174 297.749,25.068C297.855,24.963 297.985,24.91 298.141,24.91L299.241,24.91C299.397,24.91 299.528,24.963 299.633,25.068C299.738,25.174 299.791,25.304 299.791,25.46L299.791,26.01L300.891,26.01C301.047,26.01 301.178,26.063 301.283,26.168C301.388,26.274 301.441,26.404 301.441,26.56L301.441,35.36C301.441,35.516 301.388,35.647 301.283,35.752C301.178,35.857 301.047,35.91 300.891,35.91L296.491,35.91Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M273.765,35.91L266,28.145C267.025,27.099 268.208,26.299 269.551,25.743C270.893,25.188 272.298,24.91 273.765,24.91C275.231,24.91 276.636,25.188 277.979,25.743C279.321,26.299 280.505,27.099 281.529,28.145L273.765,35.91Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M31.727,31.658C31.727,33.035 31.442,34.087 30.872,34.816C30.302,35.546 29.468,35.91 28.37,35.91C27.285,35.91 26.456,35.552 25.882,34.837C25.308,34.121 25.014,33.096 25,31.761L25,29.929C25,28.539 25.288,27.484 25.865,26.764C26.441,26.044 27.272,25.684 28.356,25.684C29.441,25.684 30.271,26.04 30.845,26.753C31.419,27.467 31.713,28.491 31.727,29.826L31.727,31.658ZM29.751,29.648C29.751,28.824 29.638,28.223 29.413,27.847C29.187,27.471 28.835,27.283 28.356,27.283C27.892,27.283 27.549,27.462 27.328,27.82C27.107,28.178 26.989,28.737 26.976,29.498L26.976,31.918C26.976,32.729 27.086,33.332 27.307,33.726C27.528,34.12 27.882,34.317 28.37,34.317C28.853,34.317 29.202,34.128 29.416,33.75C29.63,33.372 29.742,32.793 29.751,32.014L29.751,29.648Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M37.681,31.795C37.166,32.301 36.564,32.554 35.876,32.554C34.996,32.554 34.292,32.252 33.764,31.648C33.235,31.044 32.971,30.229 32.971,29.204C32.971,28.552 33.113,27.954 33.398,27.41C33.683,26.865 34.08,26.441 34.591,26.138C35.101,25.835 35.675,25.684 36.313,25.684C36.97,25.684 37.553,25.848 38.063,26.176C38.574,26.504 38.97,26.976 39.253,27.591C39.535,28.206 39.679,28.91 39.684,29.703L39.684,30.435C39.684,32.093 39.271,33.397 38.446,34.345C37.621,35.293 36.452,35.799 34.939,35.862L34.454,35.869L34.454,34.222L34.892,34.215C36.61,34.137 37.539,33.331 37.681,31.795ZM36.361,31.043C36.68,31.043 36.955,30.961 37.185,30.797C37.415,30.633 37.59,30.435 37.708,30.202L37.708,29.389C37.708,28.719 37.58,28.199 37.325,27.83C37.07,27.461 36.728,27.276 36.3,27.276C35.903,27.276 35.577,27.458 35.322,27.82C35.067,28.182 34.939,28.637 34.939,29.184C34.939,29.726 35.064,30.171 35.312,30.52C35.56,30.869 35.91,31.043 36.361,31.043Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M41.27,34.803C41.27,34.488 41.375,34.233 41.587,34.037C41.799,33.841 42.065,33.743 42.384,33.743C42.707,33.743 42.975,33.841 43.187,34.037C43.399,34.233 43.505,34.488 43.505,34.803C43.505,35.113 43.4,35.364 43.19,35.558C42.981,35.752 42.712,35.849 42.384,35.849C42.06,35.849 41.794,35.752 41.584,35.558C41.374,35.364 41.27,35.113 41.27,34.803ZM41.27,29.204C41.27,28.89 41.375,28.634 41.587,28.438C41.799,28.243 42.065,28.145 42.384,28.145C42.707,28.145 42.975,28.243 43.187,28.438C43.399,28.634 43.505,28.89 43.505,29.204C43.505,29.514 43.4,29.766 43.19,29.959C42.981,30.153 42.712,30.25 42.384,30.25C42.06,30.25 41.794,30.153 41.584,29.959C41.374,29.766 41.27,29.514 41.27,29.204Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M47.053,29.922L48.105,29.922C48.607,29.922 48.978,29.797 49.22,29.546C49.461,29.295 49.582,28.963 49.582,28.548C49.582,28.147 49.462,27.835 49.223,27.611C48.984,27.388 48.655,27.276 48.235,27.276C47.857,27.276 47.54,27.38 47.285,27.587C47.03,27.795 46.902,28.065 46.902,28.397L44.927,28.397C44.927,27.878 45.067,27.412 45.347,27C45.627,26.587 46.019,26.265 46.523,26.032C47.027,25.8 47.581,25.684 48.187,25.684C49.24,25.684 50.065,25.935 50.662,26.439C51.259,26.943 51.558,27.636 51.558,28.521C51.558,28.976 51.419,29.396 51.141,29.778C50.863,30.161 50.498,30.455 50.047,30.66C50.607,30.861 51.026,31.161 51.301,31.562C51.577,31.964 51.715,32.437 51.715,32.984C51.715,33.868 51.392,34.577 50.748,35.11C50.103,35.644 49.249,35.91 48.187,35.91C47.194,35.91 46.382,35.648 45.75,35.124C45.119,34.6 44.804,33.907 44.804,33.046L46.779,33.046C46.779,33.42 46.919,33.725 47.2,33.962C47.48,34.199 47.825,34.317 48.235,34.317C48.705,34.317 49.073,34.193 49.339,33.945C49.606,33.696 49.739,33.367 49.739,32.957C49.739,31.964 49.192,31.467 48.099,31.467L47.053,31.467L47.053,29.922Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M59.774,31.658C59.774,33.035 59.49,34.087 58.92,34.816C58.35,35.546 57.516,35.91 56.418,35.91C55.333,35.91 54.504,35.552 53.93,34.837C53.355,34.121 53.062,33.096 53.048,31.761L53.048,29.929C53.048,28.539 53.336,27.484 53.913,26.764C54.489,26.044 55.32,25.684 56.404,25.684C57.489,25.684 58.318,26.04 58.893,26.753C59.467,27.467 59.761,28.491 59.774,29.826L59.774,31.658ZM57.799,29.648C57.799,28.824 57.686,28.223 57.46,27.847C57.235,27.471 56.883,27.283 56.404,27.283C55.939,27.283 55.597,27.462 55.375,27.82C55.154,28.178 55.037,28.737 55.023,29.498L55.023,31.918C55.023,32.729 55.134,33.332 55.355,33.726C55.576,34.12 55.93,34.317 56.418,34.317C56.901,34.317 57.25,34.128 57.464,33.75C57.678,33.372 57.79,32.793 57.799,32.014L57.799,29.648Z" style={{ fill: "#a8a8a8", fillRule: "nonzero" }} />
                <path d="M280,35.91L291,35.91L291,24.91L280,35.91Z" style={{ fill: "#a8a8a8" }} />
                <path d="M233,656C233,657.656 231.656,659 230,659L101,659C99.344,659 98,657.656 98,656C98,654.344 99.344,653 101,653L230,653C231.656,653 233,654.344 233,656Z" style={{ fill: "#dadada" }} />
                <circle cx="288.5" cy="74.5" r="17.5" style={{ fill: props.color }} />
                <path d="M306,176.278L306,235.722C306,240.015 302.515,243.5 298.222,243.5L32.778,243.5C28.485,243.5 25,240.015 25,235.722L25,176.278C25,171.985 28.485,168.5 32.778,168.5L298.222,168.5C302.515,168.5 306,171.985 306,176.278Z" style={{ fill: "#f3f3f3" }} />
                <g>
                    <path d="M105,137.593L105,148.407C105,151.77 102.27,154.5 98.907,154.5L31.093,154.5C27.73,154.5 25,151.77 25,148.407L25,137.593C25,134.23 27.73,131.5 31.093,131.5L98.907,131.5C102.27,131.5 105,134.23 105,137.593Z" style={{ fill: props.color, fillOpacity: 0.53 }} />
                    <path d="M86.5,143C86.5,144.854 84.995,146.359 83.141,146.359L46.859,146.359C45.005,146.359 43.5,144.854 43.5,143C43.5,141.146 45.005,139.641 46.859,139.641L83.141,139.641C84.995,139.641 86.5,141.146 86.5,143Z" style={{ fillOpacity: 0.12 }} />
                </g>
                <g>
                    <path d="M199,137.593L199,148.407C199,151.77 196.27,154.5 192.907,154.5L125.093,154.5C121.73,154.5 119,151.77 119,148.407L119,137.593C119,134.23 121.73,131.5 125.093,131.5L192.907,131.5C196.27,131.5 199,134.23 199,137.593Z" style={{ fill: props.color, fillOpacity: 0.53 }} />
                    <path d="M180.5,143C180.5,144.854 178.995,146.359 177.141,146.359L140.859,146.359C139.005,146.359 137.5,144.854 137.5,143C137.5,141.146 139.005,139.641 140.859,139.641L177.141,139.641C178.995,139.641 180.5,141.146 180.5,143Z" style={{ fillOpacity: 0.12 }} />
                </g>
                <g>
                    <path d="M293,137.593L293,148.407C293,151.77 290.27,154.5 286.907,154.5L219.093,154.5C215.73,154.5 213,151.77 213,148.407L213,137.593C213,134.23 215.73,131.5 219.093,131.5L286.907,131.5C290.27,131.5 293,134.23 293,137.593Z" style={{ fill: props.color, fillOpacity: 0.53 }} />
                    <path d="M274.5,143C274.5,144.854 272.995,146.359 271.141,146.359L234.859,146.359C233.005,146.359 231.5,144.854 231.5,143C231.5,141.146 233.005,139.641 234.859,139.641L271.141,139.641C272.995,139.641 274.5,141.146 274.5,143Z" style={{ fillOpacity: 0.12 }} />
                </g>
                <path d="M306,272.278L306,331.722C306,336.015 302.515,339.5 298.222,339.5L32.778,339.5C28.485,339.5 25,336.015 25,331.722L25,272.278C25,267.985 28.485,264.5 32.778,264.5L298.222,264.5C302.515,264.5 306,267.985 306,272.278Z" style={{ fill: "#f3f3f3" }} />
                <path d="M306,368.278L306,427.722C306,432.015 302.515,435.5 298.222,435.5L32.778,435.5C28.485,435.5 25,432.015 25,427.722L25,368.278C25,363.985 28.485,360.5 32.778,360.5L298.222,360.5C302.515,360.5 306,363.985 306,368.278Z" style={{ fill: "#f3f3f3" }} />
                <path d="M237,206C237,209.863 233.863,213 230,213L112,213C108.137,213 105,209.863 105,206C105,202.137 108.137,199 112,199L230,199C233.863,199 237,202.137 237,206Z" style={{ fill: "#e3e3e3" }} />
                <path d="M282,302C282,305.863 278.863,309 275,309L112,309C108.137,309 105,305.863 105,302C105,298.137 108.137,295 112,295L275,295C278.863,295 282,298.137 282,302Z" style={{ fill: "#e3e3e3" }} />
                <path d="M259,398C259,401.863 255.863,405 252,405L112,405C108.137,405 105,401.863 105,398C105,394.137 108.137,391 112,391L252,391C255.863,391 259,394.137 259,398Z" style={{ fill: "#e3e3e3" }} />
                <rect x="49" y="192.5" width="27" height="27" style={{ fill: "#e3e3e3" }} />
                <rect x="49" y="288.5" width="27" height="27" style={{ fill: "#e3e3e3" }} />
                <rect x="49" y="384.5" width="27" height="27" style={{ fill: "#e3e3e3" }} />
                <path d="M331,35L331,647C331,666.317 315.317,682 296,682L35,682C15.683,682 0,666.317 0,647L0,35C0,15.683 15.683,0 35,0L296,0C315.317,0 331,15.683 331,35Z" style={{ fill: "#fff", fillOpacity: 0.22 }} />
                <path d="M331,35L331,647C331,666.317 315.317,682 296,682L35,682C15.683,682 0,666.317 0,647L0,35C0,15.683 15.683,0 35,0L296,0C315.317,0 331,15.683 331,35ZM321,35C321,21.202 309.798,10 296,10L35,10C21.202,10 10,21.202 10,35L10,647C10,660.798 21.202,672 35,672L296,672C309.798,672 321,660.798 321,647L321,35Z" style={{ fill: "#a8a8a8" }} />
            </g>
        </g>
    </svg>;
}