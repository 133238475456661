import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { CompanyInfoTableRowStyles } from "./CompanyManagement.styles";

interface CompanyTableRowProps {
  title: string;
  info: string | number;
}

const CompanyTableRow: FC<CompanyTableRowProps> = ({ title, info }) => {
  return (
    <CompanyInfoTableRowStyles>
      <Box>
        <Typography>{title}</Typography>
      </Box>
      <Box>
        <Typography>{info}</Typography>
      </Box>
    </CompanyInfoTableRowStyles>
  );
};

export default CompanyTableRow;
