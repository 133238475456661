import { Box } from "@mui/material";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import {
  useCertificatesData,
  useOnNewCertificateAdd,
  usePagination,
} from "../../../store/selectors/certificatesSelectors";
import { FC } from "react";
import CertificatesTableRow from "./CertificatesTableRow";
import { useTableDataPaginated } from "../../../hooks/UseTablePagination";
import Pagination from "../../../components/common/table/Pagination";
import { setPagination } from "../../../store/reducers/certificatesReducer";
import { CertificateItem } from "../../../types/certificates";
import CertificatesCreateOrEdit from "./CertificatesCreateOrEdit";

const CertificatesTableBody: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const tableData = useCertificatesData();
  const newUserMode = useOnNewCertificateAdd();
  const { itemsPerPage, currentPage, totalPages } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    ["documentName", "employeeName", "endDate", "notificationDate", "documents", "status"]
  );

  const certificateItemsToRender = tableDataToRender.map((certificate: CertificateItem) => (
    <CertificatesTableRow key={certificate.id} certificateData={certificate} />
  ));

  return (
    <>
      {newUserMode && <CertificatesCreateOrEdit isNewCertificateAdd={newUserMode}/>}
      {certificateItemsToRender}
      <Box sx={{ gridColumn: "1/8" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default CertificatesTableBody;
