import ActionCard from "./ActionCard/ActionCard";
import { useActionsData } from "../../../store/selectors/actionsSelectors";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import {
  ActionEditComponent,
  ActionsBlockWrapper,
  ActionsGrid,
} from "../ActionsBlock.styles";
import { useState } from "react";
import { ActionItem } from "../../../types/actionItem";
import { ActionEdit } from "./ActionEdit";
import { Box, styled } from "@mui/material";

const ActionCards = (props: { searchQuery: string }) => {
  const actionsData = useActionsData();
  const [clickedCard, setClickedCard] = useState<ActionItem | undefined>(undefined)
  const tableDataToRender = useTableDataToRender(
    props.searchQuery,
    actionsData,
    "name"
  );

  const hideEditComponent = () => {
    setClickedCard(undefined)
  }

  const actionCards = tableDataToRender
    ? tableDataToRender.map((action: ActionItem) => (
      <ActionCard
        actionItem={action}
        key={action.id}
        onEditClicked={() => {
          if (clickedCard && clickedCard.id === action.id) {
            setClickedCard(undefined)
          } else {
            setClickedCard(action)
          }
        }}
      />
    ))
    : [];

  return (
    <ActionsBlockWrapper item={clickedCard ? true : false}>
      <ActionsGrid>
        {actionCards}
      </ActionsGrid>
      {
        clickedCard &&
        <ActionEditComponent>
          <CloseButton onClick={hideEditComponent}>X</CloseButton>
          <ActionEdit actionItem={clickedCard} />
        </ActionEditComponent>
      }
    </ActionsBlockWrapper>
  );
};

const CloseButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "0",
  right: "0",
  width: "15px",
  height: "15px",
  margin: "5px",
  backgroundColor: "white",
  color: "lightgrey",
  fontWeight: "bold",
  border: "2px solid lightgrey",
  borderRadius: "10px",
  fontSize: "12px",
  cursor: "pointer",
  '&:hover': {
    color: "grey",
    borderColor: "grey",
    transition: "0.5s"
  },
}));

export default ActionCards;
