import { Box } from "@mui/material";
import { FC } from "react";
import TooltipTypography from "../../../../../components/common/TooltipTypography";

interface GroupNameProps {
  label: string;
  actions?: any[];
}

const GroupName: FC<GroupNameProps> = ({ label }) => {
  return (
    <Box
      sx={(theme) => ({
        padding: "0 0 0 35px!important",
        borderRadius: "5px",
        cursor: "pointer",
        [theme.breakpoints.down(1700)]: {
          "&": {
            padding: "0 0 0 25px!important",
          },
        },
      })}
    >
      <TooltipTypography
        value={label}
        sxTypography={{
          lineHeight: "1.2",
          fontSize: "1.25rem",
          fontWeight: "600"
        }}
        variantTypography="subtitle1"
      />
    </Box>
  );
};

export default GroupName;
