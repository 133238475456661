import { Box, SxProps } from "@mui/material";
import React, { FC } from "react";

interface TimePickerUIWrapProps {
  children: JSX.Element;
  sx?: SxProps | {};
}

const TimePickerUIWrap: FC<TimePickerUIWrapProps> = ({ children, sx = {} }) => {
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        minWidth: "90px",
        border: `1px solid ${theme.palette.textMain.main}`,
        borderRadius: "2px",
        fieldset: {
          border: "none",
        },
        input: {
          height: "24px",
          padding: "0 0 0 5px",
          fontSize: "0.9rem",
        },
        ".MuiInputBase-root": {
          paddingRight: "12px",
        },
        ".MuiButtonBase-root": {
          padding: "0",
          span: {
            display: "none",
          },
        },
        ".border": {
          position: "absolute",
          top: "0",
          right: "20px",
          height: "100%",
          width: "0!important",
          borderRight: `1px solid ${theme.palette.textMain.main}`,
        },
        svg: {
          width: "20px",
          height: "20px",
        },
        ...sx,
      })}
    >
      <span className="border"></span>
      {children}
    </Box>
  );
};

export default TimePickerUIWrap;
