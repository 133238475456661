import {MenuItem} from "@mui/material";
import {doApiPost} from "../../api/Requests";
import {authenticationStateHandler} from "../logic/AuthenticationStateHandler";


export default function LogoutButton() {
    let handleLogout = async function () {
        await doApiPost<any, never>(
            "/api/logout",
            {}
        );
        
        localStorage.removeItem("userRole")

        // Set the user as not authenticated even if this call fails.
        // As "logout" is forgetting the access token this is no problem
        authenticationStateHandler.setNotAuthenticated();
    }

    return <MenuItem onClick={() => handleLogout()}>Sign Out</MenuItem>
}
