import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ItemIconPasswordCheck, ItemTextPasswordCheck } from './Authentication.styles';

interface PasswordRulesProps {
    pw: string;
}

const PasswordRules: FC<PasswordRulesProps> = ({ pw }) => {
    const [eightCharacters, setEightCharacters] = useState(false)
    const [capitalLetter, setCapitalLetter] = useState(false)
    const [lowerCaseLetter, setLowerCaseLetter] = useState(false)
    const [oneNumber, setOneNumber] = useState(false)
    const [specialCharacter, setSpecialCharacter] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        pw.length >= 8 ? setEightCharacters(true) : setEightCharacters(false);
        /[A-Z]/.test(pw) ? setCapitalLetter(true) : setCapitalLetter(false);
        /[a-z]/.test(pw) ? setLowerCaseLetter(true) : setLowerCaseLetter(false);
        /[0-9]/.test(pw) ? setOneNumber(true) : setOneNumber(false);
        /[!-\/:-@[-`{-~]/.test(pw) ? setSpecialCharacter(true) : setSpecialCharacter(false);
    }, [pw])

    return (
        <List
            sx={{
                paddingTop: 0,
                marginLeft: "20px"
            }}>
            <ListItem disablePadding>
                <ItemIconPasswordCheck>
                    {eightCharacters ?
                        <CheckCircleIcon sx={{ color: "green" }} />
                        :
                        <CancelIcon sx={{ color: "red" }} />
                    }
                </ItemIconPasswordCheck>
                <ItemTextPasswordCheck primary={t("authentication.register.passwordRules.eightCharacters")} />
            </ListItem>
            <ListItem disablePadding>
                <ItemIconPasswordCheck>
                    {capitalLetter ?
                        <CheckCircleIcon sx={{ color: "green" }} />
                        :
                        <CancelIcon sx={{ color: "red" }} />
                    }
                </ItemIconPasswordCheck>
                <ItemTextPasswordCheck primary={t("authentication.register.passwordRules.capitalLetter")} />
            </ListItem>
            <ListItem disablePadding>
                <ItemIconPasswordCheck>
                    {lowerCaseLetter ?
                        <CheckCircleIcon sx={{ color: "green" }} />
                        :
                        <CancelIcon sx={{ color: "red" }} />
                    }
                </ItemIconPasswordCheck>
                <ItemTextPasswordCheck primary={t("authentication.register.passwordRules.lowerCaseLetter")} />
            </ListItem>
            <ListItem disablePadding>
                <ItemIconPasswordCheck>
                    {oneNumber ?
                        <CheckCircleIcon sx={{ color: "green" }} />
                        :
                        <CancelIcon sx={{ color: "red" }} />
                    }
                </ItemIconPasswordCheck>
                <ItemTextPasswordCheck primary={t("authentication.register.passwordRules.oneNumber")} />
            </ListItem>
            <ListItem disablePadding>
                <ItemIconPasswordCheck>
                    {specialCharacter ?
                        <CheckCircleIcon sx={{ color: "green" }} />
                        :
                        <CancelIcon sx={{ color: "red" }} />
                    }
                </ItemIconPasswordCheck>
                <ItemTextPasswordCheck primary={t("authentication.register.passwordRules.specialCharacter")} />
            </ListItem>
        </List>
    )
}

export default PasswordRules