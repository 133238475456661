import { useAppSelector } from "../../hooks/StoreHooks";

export const useCertificatesEditMode = () =>
  useAppSelector((state) => state.certificates.editMode);

export const useOnNewCertificateAdd = () =>
  useAppSelector((state) => state.certificates.isNewCertificateToAdd);

export const useCertificatesData = () =>
  useAppSelector((state) => state.certificates.certificatesData);

export const useOnCertificatesSave = () =>
  useAppSelector((state) => state.certificates.onSave);

export const usePagination = () =>
  useAppSelector((state) => state.certificates.pagination);
