import ActionFormWrapper from "./ActionFormWrapper";
import RadioButton from "../../../../components/common/RadioButton";
import { SyntheticEvent, useEffect, useState } from "react";
import { validateDataField } from "../../utils";
import { useActionToEditData } from "../../../../store/selectors/actionsSelectors";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { setActionData } from "../../../../store/reducers/actionsReducer";
import { useTranslation } from "react-i18next";
import {
  ActionFormBoxStyles,
  AllocationFormStyles,
  AllocationInputBox,
  AllocationRadioBtnWrapper,
  AllocationRadioBtnsBox,
} from "../ActionModuleCard.styles";
import { Container } from "../../../../components/common/Common.styles";

enum RadioGroupStateType {
  TwoOptions = "twoAllocationOptions",
  OneOption = "oneAllocationOption",
}

const AllocationPoints = () => {
  const { t } = useTranslation();
  const [radioGroupState, setRadioGroupState] = useState<RadioGroupStateType>(
    RadioGroupStateType.TwoOptions
  );

  const [firstOptionAllocationValue, setFirstOptionAllocationValue] =
    useState<string>("0");
  const [secondOptionAllocationValue, setSecondOptionAllocationValue] =
    useState<string>("0");
  const [partialAllocationValue, setPartialAllocationValue] =
    useState<string>("0");
  const [errors, setErrors] = useState<{
    firstOptionError: string;
    secondOptionError: string;
    partialValueError: string;
  }>({
    firstOptionError: "",
    secondOptionError: "",
    partialValueError: "",
  });

  const dispatch = useAppDispatch();

  const maxActionPoints = useActionToEditData()?.maxPoints;

  useEffect(() => {
    if (
      radioGroupState === RadioGroupStateType.TwoOptions &&
      !errors.firstOptionError &&
      !errors.partialValueError
    ) {
      dispatch(
        setActionData({
          rewardedPointsFullyFullfilled: Number(firstOptionAllocationValue),
          rewardedPointsPartiallyFullfilled: Number(partialAllocationValue),
        })
      );
    }
    if (
      radioGroupState === RadioGroupStateType.OneOption &&
      !errors.secondOptionError
    ) {
      dispatch(
        setActionData({
          rewardedPointsFullyFullfilled: Number(secondOptionAllocationValue),
          rewardedPointsPartiallyFullfilled: 0,
        })
      );
    }
  }, [radioGroupState]);

  const handleFirstAllocationOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      maxActionPoints ? maxActionPoints : 50
    );
    if (value === "error") {
      setErrors((state) => ({ ...state, firstOptionError: value }));
    } else {
      dispatch(
        setActionData({ rewardedPointsFullyFullfilled: value as number })
      );
      setErrors((state) => ({ ...state, firstOptionError: "" }));
    }
  };
  const handleSecondAllocationOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      maxActionPoints ? maxActionPoints : 50
    );
    if (value === "error") {
      setErrors((state) => ({ ...state, secondOptionError: value }));
    } else {
      dispatch(
        setActionData({ rewardedPointsFullyFullfilled: value as number })
      );
      setErrors((state) => ({ ...state, secondOptionError: "" }));
    }
  };
  const handlePartiallyAllocationOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      Number(firstOptionAllocationValue)
        ? Number(firstOptionAllocationValue)
        : 10
    );
    if (value === "error") {
      setErrors((state) => ({ ...state, partialValueError: value }));
    } else {
      dispatch(
        setActionData({ rewardedPointsPartiallyFullfilled: value as number })
      );
      setErrors((state) => ({ ...state, secondOptionError: "" }));
    }
  };
  return (
    <ActionFormBoxStyles>
      <ActionFormWrapper label={t("actionPage.allocationPoints")}>
        <AllocationFormStyles>
          {" "}
          <AllocationRadioBtnsBox>
            <AllocationRadioBtnWrapper>
              <RadioButton
                label={t(`actionPage.${RadioGroupStateType.TwoOptions}`)}
                handleOnClick={() =>
                  setRadioGroupState(RadioGroupStateType.TwoOptions)
                }
                isChecked={radioGroupState === RadioGroupStateType.TwoOptions}
              />
            </AllocationRadioBtnWrapper>
            <Container>
              <AllocationInputBox
                error={errors.firstOptionError}
                maxWidthVal="90px"
              >
                <span>{t("actionPage.fullyFulfilled")}</span>{" "}
                <input
                  type="text"
                  disabled={radioGroupState === RadioGroupStateType.OneOption}
                  onBlur={handleFirstAllocationOnBlur}
                  onChange={(e) =>
                    setFirstOptionAllocationValue(e.target.value)
                  }
                  value={firstOptionAllocationValue}
                />{" "}
              </AllocationInputBox>
              <AllocationInputBox error={""} maxWidthVal="115px">
                <span>{t("actionPage.partiallyFulfilled")}</span>{" "}
                <input
                  type="text"
                  disabled={radioGroupState === RadioGroupStateType.OneOption}
                  onBlur={handlePartiallyAllocationOnBlur}
                  onChange={(e) =>
                    setPartialAllocationValue(
                      +e.target.value > +firstOptionAllocationValue
                        ? String(+firstOptionAllocationValue - 1)
                        : e.target.value
                    )
                  }
                  value={partialAllocationValue}
                />{" "}
              </AllocationInputBox>
            </Container>
          </AllocationRadioBtnsBox>
          <AllocationRadioBtnsBox>
            <AllocationRadioBtnWrapper>
              <RadioButton
                label={t(`actionPage.${RadioGroupStateType.OneOption}`)}
                handleOnClick={() =>
                  setRadioGroupState(RadioGroupStateType.OneOption)
                }
                isChecked={radioGroupState === RadioGroupStateType.OneOption}
              />
            </AllocationRadioBtnWrapper>
            <Container>
              <AllocationInputBox
                maxWidthVal="90px"
                error={errors.secondOptionError}
              >
                <span>{t("actionPage.fullyFulfilled")}</span>{" "}
                <input
                  disabled={radioGroupState === RadioGroupStateType.TwoOptions}
                  type="text"
                  onBlur={handleSecondAllocationOnBlur}
                  onChange={(e) =>
                    setSecondOptionAllocationValue(e.target.value)
                  }
                  value={secondOptionAllocationValue}
                />{" "}
              </AllocationInputBox>
            </Container>
          </AllocationRadioBtnsBox>
        </AllocationFormStyles>
      </ActionFormWrapper>
    </ActionFormBoxStyles>
  );
};

export default AllocationPoints;
