import { createAsyncThunk } from "@reduxjs/toolkit";
import { companyBillingsApi } from "../../api/companyBillingsApi";
import { BillItem } from "../../types/bills";
import { companyManagementApi } from "../../api/companyManagementApi";
import { CompanyManagementType } from "../../types/companyManagementTypes";
import { setCompanyList } from "../reducers/billsReducer";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchCompanyBillingsThunk = createAsyncThunk(
    "companyBillings/fetchCompanyBillings",
    async (_, thunkApi) => {
      try {
        const bills = await companyBillingsApi.getCompanyBillings();
        thunkApi.dispatch(setProcessingRequest(false))
        return bills ? (bills as BillItem[]) : [];
      } catch (error) {
        console.log(error);
      }
      return [];
    }
  );

  export const fetchCompanyList = createAsyncThunk(
    "companyBillings/getCompanyList",
    async (_, thunkApi) => {
      try {
        const users = await companyManagementApi.getAllCompanies();
        thunkApi.dispatch(setCompanyList(users as CompanyManagementType[]));
      } catch (error) {
        console.log(error);
      }
    }
  );