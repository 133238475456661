import { createAsyncThunk } from "@reduxjs/toolkit";
import { certificatesApi } from "../../api/certificatesApi";
import { CertificateItem } from "../../types/certificates";
import { RootState } from "../store";
import { createNewCertificate, setCertificatesEditMode } from "../reducers/certificatesReducer";
import { CertificateUploadData } from "../../components/common/table/UploadBtn";
import { CertificateDocumentDeleteData } from "../../pages/documentManagement/Certificates/DocumentPopUp/PopUpTableRow";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchCertificatesThunk = createAsyncThunk(
  "certificates/fetchCertificates",
  async (_, thunkApi) => {
    try {
      const certificates = await certificatesApi.getCertificates();
      thunkApi.dispatch(setProcessingRequest(false))
      return certificates ? (certificates as CertificateItem[]) : [];
    } catch (error) {
      console.log(error);
    }
  }
)

export const editCertificatesThunk = createAsyncThunk(
  "certificates/editCertificate",
  (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const certificatesDataToEdit = state.certificates.certificatesToEdit;
    const certificatesDataToEditRequests = certificatesDataToEdit.map((certificate) =>
      certificatesApi.editCertificate(certificate.id, {
        documentName: certificate.documentName,
        employeeId: certificate.employeeId,
        endDate: certificate.endDate,
        notificationDate: certificate.notificationDate,
      })
    );

    Promise.all(certificatesDataToEditRequests)
      .then(async () => {
        await thunkApi.dispatch(fetchCertificatesThunk())
        thunkApi.dispatch(setCertificatesEditMode(false))
        thunkApi.dispatch(setProcessingRequest(false))
        return certificatesDataToEdit
      })
      .catch(error => console.log(error))
  }
)

export const createNewCertificateThunk = createAsyncThunk(
  "certificates/createCertificate",
  (certificate: CertificateItem, thunkApi) => {
    const newCertificate = {
      documentName: certificate.documentName,
      employeeId: certificate.employeeId,
      endDate: certificate.endDate,
      notificationDate: certificate.notificationDate
    };

    thunkApi.dispatch(createNewCertificate(certificate));
    certificatesApi.createCertificate(newCertificate)
      .then(async () => {
        await thunkApi.dispatch(fetchCertificatesThunk())
        thunkApi.dispatch(setProcessingRequest(false))
        })
      .catch(error => console.log(error))
  }
)

export const deleteCertificateThunk = createAsyncThunk(
  "certificates/deleteCertificate",
  (certificateId: number, thunkApi) => {
    certificatesApi.deleteCertificate(certificateId)
      .then(async () => {
        await thunkApi.dispatch(fetchCertificatesThunk())
        thunkApi.dispatch(setCertificatesEditMode(false))
        thunkApi.dispatch(setProcessingRequest(false))
      })
      .catch(error => console.log(error))
  }
)

export const certificateFileUploadThunk = createAsyncThunk(
  "certificates/uploadFile",
  (fileDataToSend: CertificateUploadData, thunkApi) => {
    certificatesApi.uploadCertificateFile(fileDataToSend)
      .then(() => thunkApi.dispatch(fetchCertificatesThunk()))
      .catch(error => console.log(error))
  }
)

export const deleteCertificateDocumentThunk = createAsyncThunk(
  "certificates/deleteCertificateDocument",
  (certificateDocumentDeleteData: CertificateDocumentDeleteData, thunkApi) => {
    certificatesApi.deleteCertificateFile(certificateDocumentDeleteData.documentExchangeWalletId, certificateDocumentDeleteData.documentId)
      .then(() => thunkApi.dispatch(fetchCertificatesThunk()))
      .catch(error => console.log(error))
  }
)