import { Box, styled } from "@mui/material";

export const LanguageSwitch = styled(Box)(({ theme }) => ({
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "500px",
    display: 'flex',
    alignItems: 'center',
    border: `1px ${theme.palette.textMain.secondary}`,
    borderColor: 'divider',
    bgcolor: 'background.paper',
    width: 'fit-content',
    marginLeft: 'auto',
    color: 'text.secondary',
    '& svg': {
        m: 1,
    },
}));

export const LanguageOpt = styled(Box)(({ theme }) => ({
    display: 'inline',
    padding: "0 5px",
    cursor: "pointer",
    '&:hover': {
        color: "lightgrey",
    },
}))