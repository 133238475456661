import { ExcelLink, FileHandlingWrapper, TemplateDownload } from '../../../ActionsBlock.styles'
import GridOnIcon from '@mui/icons-material/GridOn';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FileHandling = () => {
  const { t } = useTranslation();

  return (
    <FileHandlingWrapper>
      <Typography variant='h5'>{t("incentivationPage.excelUpload")}</Typography>
      <TemplateDownload>
        <GridOnIcon sx={{ fontSize: "2rem", paddingRight: "0.25em" }} />
        <Typography>
          <ExcelLink href="/files/templates/EmployeeNumber_Score.xlsx">{t("incentivationPage.templateDownload")}</ExcelLink>
        </Typography>
      </TemplateDownload>
    </FileHandlingWrapper>
  )
}

export default FileHandling