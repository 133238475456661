import { Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ActionFormWrapper from "./ActionFormWrapper";
import {
  ActionFormBoxStyles,
  RecurrenceExecutionTimesStyles,
} from "../ActionModuleCard.styles";
import { Container } from "../../../../components/common/Common.styles";

const RecurrenceExecutionTimes: FC<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const [executionTimes, setExecutionTimes] = useState<string>("1");
  const handleOnChange = (e: React.SyntheticEvent) => {
    let value = (e.target as HTMLInputElement).value;
    if (!isNaN(Number(value))) {
      if (Number(value) > 28) setExecutionTimes("28");
      else setExecutionTimes(value);
    } else setExecutionTimes("1");
  };

  useEffect(() => {
    if (isDisabled) setExecutionTimes("1");
  }, [isDisabled]);

  return (
    <ActionFormBoxStyles>
      <ActionFormWrapper label="Execution numbers per month">
        <Container>
          <RecurrenceExecutionTimesStyles>
            <input
              disabled={isDisabled}
              onChange={handleOnChange}
              value={executionTimes}
              type="text"
            />
          </RecurrenceExecutionTimesStyles>
          <Typography>max.28</Typography>
        </Container>
      </ActionFormWrapper>
    </ActionFormBoxStyles>
  );
};

export default RecurrenceExecutionTimes;
