import { Box } from "@mui/material";
import RadioButton from "../../../../components/common/RadioButton";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { stringValidation } from "../../utils";
import { DailyRecurrenceType, RecurrencySubformProps } from "./RecurrenceForm";
import { useTranslation } from "react-i18next";
import { RecurrenceDailyStyles } from "../ActionModuleCard.styles";

enum RecurrencePatternType {
  Every = "every",
  EveryWeekday = "everyWeekday",
}

const RecurrenceDaily: FC<RecurrencySubformProps> = ({
  handleOnSetRecurrenceFormData,
}) => {
  const { t } = useTranslation();
  const [recurrence, setRecurrence] = useState<DailyRecurrenceType>({
    weekdayNum: 1,
  });
  const [dayNumber, setDayNumber] = useState<string>("1");
  const [error, setError] = useState<"error" | "">("");
  const [recurrencePattern, setRecurrencePattern] =
    useState<RecurrencePatternType>(
      t(`actionPage.${RecurrencePatternType.Every}`) as RecurrencePatternType
    );

  useEffect(() => {
    handleOnSetRecurrenceFormData(recurrence);
  }, [recurrence]);

  useEffect(() => {
    if (recurrencePattern === t(`actionPage.${RecurrencePatternType.Every}`)) {
      if (!error) setRecurrence({ weekdayNum: Number(dayNumber) });
    } else {
      setRecurrence({ weekdayNum: 1 });
    }
  }, [recurrencePattern]);

  const handleOnBlur = (e: SyntheticEvent) => {
    const value = stringValidation((e.target as HTMLInputElement).value, true);
    if (value === "error") {
      setError(value);
      return;
    } else {
      if ((value as number) > 7 || (value as number) < 1) {
        setError("error");
        return;
      }
      setError("");
      setRecurrence({ weekdayNum: value as number });
    }
  };

  const handleEveryWeekdayPattern = () => {
    setRecurrencePattern(
      t(
        `actionPage.${RecurrencePatternType.EveryWeekday}`
      ) as RecurrencePatternType
    );
    setRecurrence({ weekdayNum: 1 });
  };

  return (
    <RecurrenceDailyStyles error={error}>
      <Box>
        <RadioButton
          handleOnClick={() => {
            setRecurrencePattern(
              t(
                `actionPage.${RecurrencePatternType.Every}`
              ) as RecurrencePatternType
            );
          }}
          label={t(`actionPage.${RecurrencePatternType.Every}`)}
          isChecked={
            recurrencePattern === t(`actionPage.${RecurrencePatternType.Every}`)
          }
        />
        <input
          type="text"
          disabled={
            t(`actionPage.${RecurrencePatternType.EveryWeekday}`) ===
            recurrencePattern
          }
          value={dayNumber}
          onChange={(e) => setDayNumber(e.target.value)}
          onBlur={handleOnBlur}
        />
      </Box>
      <RadioButton
        handleOnClick={handleEveryWeekdayPattern}
        label={t(`actionPage.${RecurrencePatternType.EveryWeekday}`)}
        isChecked={
          recurrencePattern ===
          t(`actionPage.${RecurrencePatternType.EveryWeekday}`)
        }
      />
    </RecurrenceDailyStyles>
  );
};

export default RecurrenceDaily;
