import { useAppDispatch } from "../../hooks/StoreHooks";
import {
  useAccountManagementEditMode,
  useAccountManagementUsers
} from "../../store/selectors/accountManagementSelectors";
import { setAccountManagementEditMode, setNewUserMode } from "../../store/reducers/accountManagementReducer";
import SaveBtn from "../../components/common/table/SaveBtn";
import SearchWithAutocomplete from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import EditBtn from "../../components/common/table/EditBtn";
import { FC } from "react";
import { editAccountManagementUsersThunk } from "../../store/thunk/accountManagementThunk";
import { SearchWithAutocompleteWrapper } from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import NewBtn from "../../components/common/table/NewBtn";
import FileUploaderBox from "./AccountManagementTable/FileUploaderBox";
import { SearchBarButtonContainer } from "./AccountManagement.styles";
import { t } from "i18next";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../store/reducers/tableManagementReducer";

interface AccountManagementSearchBarProps {
  handleOnSearch: (searchQuery: string) => void;
}

interface NewUserBtnProps {
  handleOnClick: () => void;
}

const NewUserBtn: FC<NewUserBtnProps> = (props) => 
  {
    const editMode = useAccountManagementEditMode();
    if (editMode) return null;
    return ( 
      <NewBtn 
        isActive={true} 
        hoverText={t("label.addNewUser")} 
        {...props}
      />
    )
};

const AccountManagementSearchBar: FC<AccountManagementSearchBarProps> = ({
  handleOnSearch,
}) => {
  const dispatch = useAppDispatch();
  const editMode = useAccountManagementEditMode();
  const usersData = useAccountManagementUsers();
  const usersDataOptions = usersData
    ? usersData.map((user) => ({
      key: user.id,
      label: user.firstName + " " + user.lastName,
    }))
    : [];

  const handleOnSave = async () => {
    dispatch(setProcessingRequest(true))
    let res = await dispatch(editAccountManagementUsersThunk())
    dispatch(setResponseValue(res.type))
    dispatch(setResponseMessage(`${t("dialogModals.itemsSaved")}`))
  }

  return (
    <SearchWithAutocompleteWrapper>
      <SearchWithAutocomplete
        options={usersDataOptions}
        onSearchHandler={handleOnSearch}
      />
      <SearchBarButtonContainer>
        <NewUserBtn
          handleOnClick={() => {
            dispatch(setNewUserMode(true));
          }}
        />
        {!editMode ? (
          <EditBtn handleOnClick={() => dispatch(setAccountManagementEditMode(true))} />
        ) : (
          <SaveBtn handleOnClick={handleOnSave} />
        )}
        <FileUploaderBox />
      </SearchBarButtonContainer>
    </SearchWithAutocompleteWrapper>
  );
};

export default AccountManagementSearchBar;
