import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { SimpleSidebarLink } from "./SidebarLinks/SimpleSidebarLink";
import { SidebarLinkPathTypes, SidebarLinkTypes } from "./LinkTypes";
import ComplexSidebarLink from "./SidebarLinks/ComplexSidebarLink";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { PersonIcon } from "../../assets/icons/ActionIcons";
import { HomeIcon, ReceiptLongIcon } from "../../assets/icons/Icons";
import {
  LogoWrapper,
  NavBox,
  NavDrawerStyles,
  SideNavWrapper,
} from "./Layout.styles";
import { keyValueStorage } from "../../common/logic/KeyValueStore";
import { useEffect, useState } from "react";
import { doApiGet } from "../../api/Requests";

interface Props {
  open: boolean;
  onClose: () => void;
}

export interface FilteredListType {
  subTypeNames: string[];
}

export function SideNav(props: Props) {
  const { open, onClose } = props;
  const pathname = useLocation().pathname;
  const userRole = keyValueStorage.getObject<string>("userRole", "");
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const activePathname = pathname.split("/").splice(2).join("/");

  const [filteredLinks, setFilteredLinks] = useState<string[]>([])

  useEffect(() => {
    doApiGet("/api/companies/current/actionmodules/subtypes")
      .then(res => {
        let filteredLinksResult = res.result as FilteredListType
        filteredLinksResult && setFilteredLinks(filteredLinksResult.subTypeNames)
      })
  }, []);

  const content = (
    <SideNavWrapper>
      <LogoWrapper>
        <Box component={Link} to="/">
          <Logo />
        </Box>
      </LogoWrapper>
      <Divider />
      <NavBox component="nav">
        <Stack component="ul">
          {userRole === "CompanyManagement" && (
            <>
              <SimpleSidebarLink
                isActive={activePathname === SidebarLinkPathTypes.dashboard}
                icon={<DashboardIcon />}
                linkName={SidebarLinkTypes.dashboard}
                path={SidebarLinkPathTypes.dashboard}
              />
              <ComplexSidebarLink
                linkName={SidebarLinkTypes.incentivation}
                activePathname={activePathname}
                filteredLinks={filteredLinks}
              />
              <ComplexSidebarLink
                linkName={SidebarLinkTypes.groups}
                activePathname={activePathname}
              />
              <ComplexSidebarLink
                linkName={SidebarLinkTypes.actionModules}
                activePathname={activePathname}
              />
              {filteredLinks?.includes("CertificatesAndTraining") &&
                <ComplexSidebarLink
                  linkName={SidebarLinkTypes.documentManagement}
                  activePathname={activePathname}
                  filteredLinks={filteredLinks}
                />
              }
              <SimpleSidebarLink
                isActive={activePathname === SidebarLinkPathTypes.companyManagement}
                icon={<HomeIcon />}
                linkName={SidebarLinkTypes.companyManagement}
                path={SidebarLinkPathTypes.companyManagement}
              />
              <SimpleSidebarLink
                isActive={activePathname === SidebarLinkPathTypes.billing}
                icon={<ReceiptLongIcon />}
                linkName={SidebarLinkTypes.billing}
                path={SidebarLinkPathTypes.billing}
              />
              <SimpleSidebarLink
                isActive={activePathname === SidebarLinkPathTypes.accountManagement}
                icon={<PersonIcon />}
                linkName={SidebarLinkTypes.accountManagement}
                path={SidebarLinkPathTypes.accountManagement}
              />
              <ComplexSidebarLink
                linkName={SidebarLinkTypes.configuration}
                activePathname={activePathname}
              />
            </>
          )}
          {userRole === "Administrator" && (
            <>
              <ComplexSidebarLink
                linkName={SidebarLinkTypes.superAdmin}
                activePathname={activePathname}
              />
            </>
          )}
        </Stack>
      </NavBox>
      <Divider />
    </SideNavWrapper>
  );

  if (lgUp) {
    return (
      <NavDrawerStyles anchor="left" open variant="permanent">
        {content}
      </NavDrawerStyles>
    );
  }

  return (
    <NavDrawerStyles
      anchor="left"
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      {content}
    </NavDrawerStyles>
  );
}
