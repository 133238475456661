import { Typography } from "@mui/material";
import { FC } from "react";

export const MainTitle: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: "1.875rem!important",
        lineHeight: "1.233",
      }}
      variant="h1"
    >
      {children}
    </Typography>
  );
};

export const Subtitle: FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={(theme) => ({
        fontWeight: "700",
        margin: "9px 0 11px",
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        linHeight: "1.2",
        a: {
          color: theme.palette.primary.main,
          marginLeft: "5px",
          textDecoration: "none",
          fontSize: "inherit",
        },
      })}
    >
      {children}
    </Typography>
  );
};
