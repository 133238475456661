import { Select, SelectChangeEvent } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import RadioButton from "../../../../components/common/RadioButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { validateDataField } from "../../utils";
import {
  MonthlyNumericDayType,
  MonthlySpecificDayType,
  RecurrencySubformProps,
} from "./RecurrenceForm";
import { useDayItems, useDayNumberItems } from "./DateItemsLists";
import { useTranslation } from "react-i18next";
import { Container } from "../../../../components/common/Common.styles";
import {
  ContainerWithWrap,
  DayNameSelectUI,
  DayNumSelectUI,
  RecurrenceInputBox,
  RecurrenceMonthlyContainer,
  RecurrenceMonthlyStyles,
} from "../ActionModuleCard.styles";

enum RadioGroupStateType {
  NumericDay = "NumericDay",
  SpecificDay = "SpecificDay",
}

const RecurrenceMonthly: FC<RecurrencySubformProps> = ({
  handleOnSetRecurrenceFormData,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<{
    daysFieldError: string;
    firstMonthFieldError: string;
    secondMonthFieldError: string;
  }>({
    daysFieldError: "",
    firstMonthFieldError: "",
    secondMonthFieldError: "",
  });

  const [monthlyRecurrenceData, setMonthlyRecurrenceData] = useState<{
    day: MonthlyNumericDayType;
    specificDay: MonthlySpecificDayType;
  }>({
    day: {
      dayNum: 1,
      monthRecurrence: 2,
    },
    specificDay: {
      dayNum: 1,
      dayName: "Monday",
      monthRecurrence: 2,
    },
  });
  const dayNumberItems = useDayNumberItems();
  const dayItems = useDayItems();

  const [daysField, setDaysField] = useState<string>("1");
  const [firstMonthesField, setFirstMonthesField] = useState<string>("2");
  const [secondMonthesField, setSecondMonthesField] = useState<string>("3");

  const [radioGroupState, setRadioGroupState] = useState<RadioGroupStateType>(
    RadioGroupStateType.NumericDay
  );

  useEffect(() => {
    if (radioGroupState === RadioGroupStateType.NumericDay) {
      handleOnSetRecurrenceFormData(monthlyRecurrenceData.day);
    } else {
      handleOnSetRecurrenceFormData(monthlyRecurrenceData.specificDay);
    }
  }, [monthlyRecurrenceData]);

  useEffect(() => {
    if (radioGroupState === RadioGroupStateType.NumericDay) {
      if (!error.daysFieldError && !error.firstMonthFieldError)
        setMonthlyRecurrenceData((state) => ({
          ...state,
          day: {
            dayNum: Number(daysField),
            monthRecurrence: Number(firstMonthesField),
          },
        }));
    } else {
      if (!error.secondMonthFieldError)
        setMonthlyRecurrenceData((state) => ({
          ...state,
          specificDay: {
            ...state.specificDay,
            monthRecurrence: Number(secondMonthesField),
          },
        }));
    }
  }, [radioGroupState]);

  const handleDaysFieldOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      28
    );
    if (value === "error")
      setError((state) => ({ ...state, daysFieldError: "error" }));
    else {
      setMonthlyRecurrenceData((state) => ({
        ...state,
        day: {
          ...state.day,
          dayNum: value as number,
        },
      }));
      setError((state) => ({ ...state, daysFieldError: "" }));
    }
  };

  const handleFirstMonthFieldOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      12
    );
    if (value === "error")
      setError((state) => ({ ...state, firstMonthFieldError: "error" }));
    else {
      setMonthlyRecurrenceData((state) => ({
        ...state,
        day: {
          ...state.day,
          monthRecurrence: value as number,
        },
      }));
      setError((state) => ({ ...state, firstMonthFieldError: "" }));
    }
  };

  const handleSecondMonthFieldOnBlur = (e: SyntheticEvent) => {
    const value = validateDataField(
      (e.target as HTMLInputElement).value as string,
      12
    );
    if (value === "error")
      setError((state) => ({ ...state, secondMonthFieldError: "error" }));
    else {
      setMonthlyRecurrenceData((state) => ({
        ...state,
        specificDay: {
          ...state.specificDay,
          monthRecurrence: value as number,
        },
      }));
      setError((state) => ({ ...state, secondMonthFieldError: "" }));
    }
  };

  const handleOnSpecificDayNum = (e: SelectChangeEvent) => {
    setMonthlyRecurrenceData((state) => ({
      ...state,
      specificDay: {
        ...state.specificDay,
        dayNum: Number(e.target.value),
      },
    }));
  };

  const handleOnSpecificDayName = (e: SelectChangeEvent) => {
    setMonthlyRecurrenceData((state) => ({
      ...state,
      specificDay: {
        ...state.specificDay,
        dayName: e.target.value,
      },
    }));
  };

  return (
    <RecurrenceMonthlyStyles>
      <RecurrenceMonthlyContainer>
        <RadioButton
          label={t("actionPage.day")}
          handleOnClick={() =>
            setRadioGroupState(RadioGroupStateType.NumericDay)
          }
          isChecked={radioGroupState === RadioGroupStateType.NumericDay}
        />{" "}
        <RecurrenceInputBox error={error.daysFieldError}>
          <input
            type="text"
            disabled={radioGroupState === RadioGroupStateType.SpecificDay}
            onChange={(e) => setDaysField(e.target.value)}
            onBlur={handleDaysFieldOnBlur}
            value={daysField}
          />
        </RecurrenceInputBox>
        <span>{t("actionPage.ofEvery")}</span>
        <RecurrenceInputBox error={error.firstMonthFieldError}>
          <input
            type="text"
            value={firstMonthesField}
            disabled={radioGroupState === RadioGroupStateType.SpecificDay}
            onBlur={handleFirstMonthFieldOnBlur}
            onChange={(e) => setFirstMonthesField(e.target.value)}
          />
        </RecurrenceInputBox>{" "}
        <span>{t("actionPage.month(s)")}</span>
      </RecurrenceMonthlyContainer>
      <RecurrenceMonthlyContainer vPosition="flex-start" margin="15px 0 0 0">
        <RadioButton
          label={t("actionPage.the")}
          handleOnClick={() =>
            setRadioGroupState(RadioGroupStateType.SpecificDay)
          }
          isChecked={radioGroupState === RadioGroupStateType.SpecificDay}
        />
        <ContainerWithWrap>
          <DayNumSelectUI>
            <Select
              disabled={radioGroupState === RadioGroupStateType.NumericDay}
              value={String(monthlyRecurrenceData.specificDay.dayNum)}
              onChange={handleOnSpecificDayNum}
              IconComponent={ExpandMoreIcon}
            >
              {dayNumberItems}
            </Select>
          </DayNumSelectUI>
          <DayNameSelectUI>
            <Select
              disabled={radioGroupState === RadioGroupStateType.NumericDay}
              value={t(monthlyRecurrenceData.specificDay.dayName)}
              onChange={handleOnSpecificDayName}
              IconComponent={ExpandMoreIcon}
            >
              {dayItems}
            </Select>
          </DayNameSelectUI>
          <Container margin="10px 0 0 5px">
            <span>{t("actionPage.everyLowerCase")}</span>
            <RecurrenceInputBox error={error.secondMonthFieldError}>
              <input
                type="text"
                disabled={radioGroupState === RadioGroupStateType.NumericDay}
                value={secondMonthesField}
                onBlur={handleSecondMonthFieldOnBlur}
                onChange={(e) => setSecondMonthesField(e.target.value)}
              />
            </RecurrenceInputBox>{" "}
            <span>{t("actionPage.month(s)")}</span>
          </Container>
        </ContainerWithWrap>
      </RecurrenceMonthlyContainer>
    </RecurrenceMonthlyStyles>
  );
};

export default RecurrenceMonthly;
