import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: [
    "path",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
  ],
  lookupFromPathIndex: 1,
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
};

i18n

  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    debug: true,
    fallbackLng: "en",
    supportedLngs: ["de", "en"],
  });

export default i18n;
