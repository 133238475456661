import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import SearchWithAutocomplete from "../../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete";
import { useSearch } from "../../../../hooks/UseSearch";
import { useCompanyListData } from "../../../../store/selectors/billsSelectors";

interface DropDownProps {
    currentSelectionId: number;
    handleUpdate: (arg0: any, column?: string) => void;
    zeroValueAllowed?: boolean;
}

interface NameAndId {
    [key: number]: string;
}

const DropdownCompanies: FC<DropDownProps> = (props) => {
    const { currentSelectionId, handleUpdate, zeroValueAllowed } = props;
    const [filteredItems, setFilteredItems] = useState<string[]>([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const { handleOnSearch } = useSearch();

    const companyList = useCompanyListData();

    let companyIdAndName: NameAndId = {}
    companyList.forEach((company) => {
        companyIdAndName[company.id] = company.name
    });

    let filteredCompanyIdAndName: NameAndId = {}
    for (let key in companyIdAndName) {
        if (filteredItems.indexOf(companyIdAndName[key]) > -1) {
            filteredCompanyIdAndName[key] = companyIdAndName[key]
        }
    }

    let listToSearch = Object.keys(companyIdAndName).map((key) => ({
        label: companyIdAndName[key], key: key
    }))

    const handleOnChange = (e: SelectChangeEvent<Number>) => {
        handleUpdate(e.target.value)
    };

    const handleClose = () => {
        setDropdownOpen(false);
    };

    const handleOpen = () => {
        setDropdownOpen(true);
    };

    const liftUpFilteredList = (searchListItems: string[]) => {
        setFilteredItems(searchListItems);
    };

    let selectDropdownItems = Object.keys(filteredCompanyIdAndName).length === 0
        ?
        Object.keys(companyIdAndName).map((key) => (
            <MenuItem key={key} autoFocus={false} value={key}>
                {companyIdAndName[key]}
            </MenuItem>))
        :
        Object.keys(filteredCompanyIdAndName).map((key) => (
            <MenuItem key={key} autoFocus={false} value={key}>
                {filteredCompanyIdAndName[key]}
            </MenuItem>))

    zeroValueAllowed && selectDropdownItems && selectDropdownItems.unshift(
        <MenuItem key='' value=''>
            -
        </MenuItem>
    )

    selectDropdownItems && selectDropdownItems.unshift(
        <SearchWithAutocomplete
            options={listToSearch}
            onSearchHandler={handleOnSearch}
            liftUpFilteredList={liftUpFilteredList}
            sx={{
                maxWidth: "499px",
                ".MuiAutocomplete-root": {
                    width: "100%",
                },
                ".search-btn": {
                    display: "none",
                },
            }}
        />
    )

    return (
        <Box
            sx={{
                width: "100%",
                ".MuiSelect-select": { padding: "9px" },
                fieldset: {
                    borderColor: (theme) => theme.palette.border,
                },
            }}
        >
            <Select
                sx={{ width: "100%" }}
                value={currentSelectionId}
                onChange={(e) => handleOnChange(e)}
                IconComponent={ExpandMoreIcon}
                autoFocus={false}
                open={dropdownOpen}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                {selectDropdownItems}
            </Select>
        </Box >
    );
};

export default DropdownCompanies