import { Box, Button, Popover } from "@mui/material";
import { MoreIcon } from "../../assets/icons/Icons";
import { usePopover } from "../../hooks/UsePopover";
import { ActionItem } from "../../types/actionItem";
import { FC, useState } from "react";
import SelectLogoModule from "./ActionModuleComponents/SelectLogoModule";
import ActionDescription from "./ActionModuleComponents/ActionDescription";
import RecurrenceTime from "./ActionModuleComponents/ActionForms/RecurrenceTime";
import RecurrencePattern from "./ActionModuleComponents/ActionForms/RecurrencePattern";
import RangeOfRecurrenceForm from "./ActionModuleComponents/ActionForms/RangeOfRecurrenceForm";
import AllocationPoints from "./ActionModuleComponents/ActionForms/AllocationPoints";
import DistributionType from "./ActionModuleComponents/ActionForms/DistributionType";
import { useLocale } from "../../hooks/UseLocale";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { createActionThunk } from "../../store/thunk/actionsThunk";
import { useTranslation } from "react-i18next";
import RecurrenceExecutionTimes from "./ActionModuleComponents/ActionForms/RecurrenceExecutionTimes";
import {
  ActionModuleCardStyles,
  DeleteBtnStyles,
  SubmitBtnsBoxStyles,
} from "./ActionModuleComponents/ActionModuleCard.styles";

interface ActionModuleCardProps {
  actionData?: ActionItem;
}

const ActionModuleCard: FC<ActionModuleCardProps> = ({ actionData }) => {
  const { t } = useTranslation();
  const { anchorRef, handleClose, handleOpen, open } = usePopover();
  const [isDisabledExecutionField, setIsDisabledExecutionField] =
    useState<boolean>(false);
  const locale = useLocale();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { groupsType } = useParams();

  const handleOnCancel = () => {
    navigate(`/${locale}/groups/${groupsType}`);
  };

  const handleOnSave = () => {
    dispatch(createActionThunk());
  };

  const handleOnDelete = () => {
    navigate(`/${locale}/groups/${groupsType}`);
  };

  return (
    <ActionModuleCardStyles ref={anchorRef}>
      <Box>
        <Box>
          <SelectLogoModule
            colorHex={actionData?.colorHex}
            iconName={actionData?.iconName}
          />
        </Box>
        <Button onClick={handleOpen}>
          <MoreIcon />
        </Button>
        <Popover
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorEl={anchorRef.current}
          onClick={handleClose}
          open={open}
        >
          popup
        </Popover>
      </Box>
      <ActionDescription />
      <RecurrenceTime />
      <RecurrencePattern
        setDisabledExecutionField={(isDisabled: boolean) =>
          setIsDisabledExecutionField(isDisabled)
        }
      />
      <RangeOfRecurrenceForm />
      <RecurrenceExecutionTimes isDisabled={isDisabledExecutionField} />
      <AllocationPoints />
      <DistributionType />
      <SubmitBtnsBoxStyles>
        <Button
          onClick={handleOnCancel}
          sx={(theme) => ({
            color: theme.palette.textMain.black,
            borderColor: theme.palette.textMain.black,
          })}
        >
          {t("buttons.cancel")}
        </Button>
        <Button onClick={handleOnSave}>{t("buttons.save")}</Button>
        <DeleteBtnStyles
          onClick={handleOnDelete}
          disabled={actionData?.id ? false : true}
          actionData={actionData}
        >
          {t("buttons.deleteModule")}
        </DeleteBtnStyles>
      </SubmitBtnsBoxStyles>
    </ActionModuleCardStyles>
  );
};

export default ActionModuleCard;
