import { Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { AlertDialogStyled } from "./Common.styles";
import { DialogPropsType } from "../../types/common";

interface AlertDialogProps {
  alertMessage: string;
  dialog: DialogPropsType;
}

const AlertDialog: FC<AlertDialogProps> = ({ alertMessage, dialog }) => {
  const { open, handleDialogClose } = dialog;
  return (
    <AlertDialogStyled open={open} onClose={handleDialogClose}>
      <Typography>{alertMessage}</Typography>
      <Button onClick={handleDialogClose}>Ok</Button>
    </AlertDialogStyled>
  );
};

export default AlertDialog;
