import { Typography } from "@mui/material";
import { ActionOptionsStyles, ActionsBlockDivider } from "../../actionModules/ActionsBlock.styles";
import FileHandling from "../../actionModules/ActionsBlock/ActionCard/ExcelIncentivation/FileHandling";
import UploadActionDocWidget from "../../actionModules/ActionsBlock/ActionCard/UploadActionDocWidget";
import { useTranslation } from "react-i18next";

interface Props {
    subtype: string;
    showScore: boolean;
}

export default function SubtypeOverviewCard(props: Props) {
    const { t } = useTranslation();

    return <ActionOptionsStyles>
        <Typography variant="h5" flex={1}>{t(`incentivationPage.${props.subtype}`)}</Typography>
        {
            props.showScore && <>
                <ActionsBlockDivider />
                <FileHandling />
                <UploadActionDocWidget
                    uploadPopupIsActive={true}
                    setUploadPopupIsActive={() => { }}
                    actionModuleSubtype={props.subtype}
                />
            </>
        }
    </ActionOptionsStyles>;
}