import { TableSortDirection } from "../common/logic/TableSortUtils";

export interface PaginationParameters {
    offset: number,
    limit: number,
    search?: string | undefined,
    sort?: string | undefined,
    order?: TableSortDirection
}

export interface PaginationResult<TItem> {
    totalCount: number,
    items: TItem[]
}

export function toQueryParameters(pagination: PaginationParameters): string {
    return `offset=${pagination.offset}` +
        `&limit=${pagination.limit}` +
        `${pagination.search ? `&search=${pagination.search}` : ""}` +
        `${pagination.sort ? `&sort=${pagination.sort}` : ""}` +
        `${pagination.order ? `&order=${tableSortDirectionToApiOrder(pagination.order)}` : ""}`;
}

function tableSortDirectionToApiOrder(direction: TableSortDirection) {
    switch(direction) {
        case "asc": return "Ascending";
        case "desc": return "Descending";
    }
}