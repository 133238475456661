import { Box, Button, Typography } from "@mui/material";
import { PaginationParameters } from "../../api/Pagination";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import { TableSortDirection } from "../../common/logic/TableSortUtils";
import BorderRow from "../../components/common/table/BorderRow";
import CellBox from "../../components/common/table/CellBox";
import StatusDisplay from "../../components/common/table/StatusDisplay";
import { ServerSidePagination } from "../../components/common/table/ServerSidePagination";
import EmployeeIncentivationType from "../../types/employeeIncentivationType";
import { ActionItem, FulfilledStatusType } from "../../types/actionItem";
import FulfilledStatusIcon, { FulfilledIconWrapper } from "../../components/common/table/FulfilledStatusIcon";
import ServerSideTableColumnHeader, { SortDelegate } from "../../components/common/table/ServerSideTableColumnHeader";
import { NumberInput } from "../../components/common/table/NumberInput";


interface Props {
    pagination: PaginationParameters;
    totalCount: number;
    onUpdatePagination: (newValue: PaginationParameters) => void;
    employees: EmployeeIncentivationType[];
    actionModule: ActionItem;
    onRowUpdated: (newValue: EmployeeIncentivationType) => void;
    isEditing: boolean;
}


export default function IncentivationTable(props: Props) {
    const showScore = props.actionModule.type.includes("ExcelIncentive");
    const showFulfillment = props.actionModule.type.includes("Manual");
    const showPartiallyFulfilled = showFulfillment && props.actionModule.rewardedPointsPartiallyFullfilled > 0;

    const fulfillmentTableTemplate = `1fr ${showPartiallyFulfilled ? `1fr` : ""} 1fr`;
    const tableTemplate = `3fr 2fr 2fr 1fr ${showFulfillment ? fulfillmentTableTemplate : ""} ${showScore ? "1fr" : ""} 1fr 1fr`;

    function onTableSortUpdate(direction: TableSortDirection | null, field: string | null) {
        props.onUpdatePagination({
            ...props.pagination,
            order: direction ?? undefined,
            sort: field ?? undefined,
        });
    }

    return <Box>
        <Box gridTemplateColumns={tableTemplate} display="grid">
            <IncentivationTableHeader
                showFulfillment={showFulfillment}
                showPartiallyState={showPartiallyFulfilled}
                showScore={showScore}
                onTableSort={onTableSortUpdate}
                sortDirection={props.pagination.order}
                sortFieldName={props.pagination.sort} />
            <Box sx={{ display: "contents" }}>
                {
                    props.employees.map((u) => <IncentivationTableRow
                        key={u.id}
                        row={u}
                        showFulfillment={showFulfillment}
                        showPartiallyState={showPartiallyFulfilled}
                        showScore={showScore}
                        isEditing={props.isEditing}
                        onRowUpdated={props.onRowUpdated} />)
                }
            </Box>
        </Box>

        <ServerSidePagination
            totalCount={props.totalCount}
            pagination={props.pagination}
            onUpdatePagination={props.onUpdatePagination} />
    </Box>
}


interface IncentivationTableHeaderProps {
    showFulfillment: boolean;
    showPartiallyState: boolean;
    showScore: boolean;

    sortDirection?: TableSortDirection;
    sortFieldName?: string;
    onTableSort: SortDelegate;
}

function IncentivationTableHeader(props: IncentivationTableHeaderProps) {
    const { t } = useTranslation();

    return <TableHeaderWrapper>
        <ServerSideTableColumnHeader
            fieldName="Name"
            isSorted={true}
            label={t("tableColumnHeaders.name")}
            onTableSort={props.onTableSort}
            sortDirection={props.sortDirection}
            sortFieldName={props.sortFieldName}
            firstCol={true}
        />
        <ServerSideTableColumnHeader
            fieldName="Position"
            isSorted={true}
            label={t("tableColumnHeaders.position")}
            onTableSort={props.onTableSort}
            sortDirection={props.sortDirection}
            sortFieldName={props.sortFieldName}
        />
        <ServerSideTableColumnHeader
            fieldName="EmployeeNumber"
            isSorted={true}
            label={t("tableColumnHeaders.employeeNo")}
            onTableSort={props.onTableSort}
            sortDirection={props.sortDirection}
            sortFieldName={props.sortFieldName}
        />
        <ServerSideTableColumnHeader
            isSorted={false}
            label={t("tableColumnHeaders.dateOfAction")}
        />
        {props.showFulfillment && <>
            <ServerSideTableColumnHeader
                label={t("tableColumnHeaders.notFulfilled")}
                prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.NotFulfilled} />}
            />

            {props.showPartiallyState && (
                <ServerSideTableColumnHeader
                    label={t("tableColumnHeaders.partiallyFulfilled")}
                    prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.Partially} />}
                />
            )}

            <ServerSideTableColumnHeader
                label={t("tableColumnHeaders.fulfilled")}
                prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.Fulfilled} />}
            />
        </>}

        {props.showScore && <ServerSideTableColumnHeader
            fieldName="score"
            label={t("tableColumnHeaders.scoreValue")}
        />}

        <ServerSideTableColumnHeader
            fieldName="points"
            label={t("tableColumnHeaders.points")}
        />

        <ServerSideTableColumnHeader
            fieldName="status"
            label={t("tableColumnHeaders.status")}
            lastCol={true}
        />
        <BorderRow />
    </TableHeaderWrapper>;
}

interface IncentivationOverviewTableRowProps {
    row: EmployeeIncentivationType;
    showFulfillment: boolean;
    showPartiallyState: boolean;
    showScore: boolean;
    isEditing: boolean;
    onRowUpdated: (newRow: EmployeeIncentivationType) => void;
}

function IncentivationTableRow(props: IncentivationOverviewTableRowProps) {
    function handleFulfillmentStatusChange(newState: FulfilledStatusType) {
        props.onRowUpdated({
            ...props.row,
            fulfillmentStatus: newState,
            score: undefined,
        })
    }

    return (
        <BasicTableRowStyles>
            <CellBox firstCol={true}>
                <Typography>{props.row.name}</Typography>
            </CellBox>
            <CellBox>
                <Typography>{props.row.position}</Typography>
            </CellBox>
            <CellBox>
                <Typography>{props.row.employeeNumber}</Typography>
            </CellBox>
            <CellBox>
                <Typography>{props.row.dateOfAction
                    ? new Date(props.row.dateOfAction).toLocaleDateString()
                    : ""}</Typography>
            </CellBox>
            {
                props.showFulfillment && <>
                    <CellBox>
                        <Button
                            disabled={!props.isEditing}
                            onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.NotFulfilled)}>
                            {props.row.fulfillmentStatus === FulfilledStatusType.NotFulfilled
                                ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.NotFulfilled} />
                                : <FulfilledIconWrapper />
                            }
                        </Button>
                    </CellBox>

                    {
                        props.showPartiallyState &&
                        <CellBox>
                            <Button
                                disabled={!props.isEditing}
                                onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Partially)}>
                                {props.row.fulfillmentStatus === FulfilledStatusType.Partially
                                    ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Partially} />
                                    : <FulfilledIconWrapper />
                                }
                            </Button>
                        </CellBox>
                    }

                    <CellBox>
                        <Button
                            disabled={!props.isEditing}
                            onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Fulfilled)}>
                            {props.row.fulfillmentStatus === FulfilledStatusType.Fulfilled
                                ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Fulfilled} />
                                : <FulfilledIconWrapper />
                            }
                        </Button>
                    </CellBox>
                </>
            }
            {
                props.showScore &&
                <CellBox contentPosition="center">
                    {
                        !props.isEditing
                            ? <Typography>{props.row.score === 0 || !props.row.score || isNaN(props.row.score) ? "N/A" : props.row.score}</Typography>
                            : <NumberInput
                                aria-label="Input"
                                placeholder={"0"}
                                value={props.row.score}
                                onChange={(e, val) => props.onRowUpdated({
                                    ...props.row,
                                    score: val ?? undefined
                                })}
                            />
                    }

                </CellBox>
            }
            <CellBox>
                <Typography>{props.row.points}</Typography>
            </CellBox>

            <CellBox lastCol={true} contentPosition="center">
                <StatusDisplay status={props.row.status} />
            </CellBox>
        </BasicTableRowStyles>
    );
}