import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LoginScreen } from "../../authentication/ui/LoginScreen";
import * as React from "react";
import { NotFoundPage } from "../../common/ui/NotFoundPage";
import { LayoutsWithNavbar } from "./LayoutWithNavbar";
import { authenticationStateHandler } from "../../authentication/logic/AuthenticationStateHandler";
import { useObservable } from "../../common/ui/ObservableHook";
import LinkingEmailConfirmationScreen from "../../authentication/ui/LinkingEmailConfirmationScreen";
import { RegistrationScreen } from "../../authentication/ui/RegistrationScreen";
import { ResetPasswordScreen } from "../../authentication/ui/ResetPasswordSceen";
import LinkingResetPasswordScreen from "../../authentication/ui/LinkingResetPasswordScreen";
import Groups from "../../pages/groups/Groups";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CreateActionPage from "../../pages/actionModule/CreateActionPage";
import EditActionPage from "../../pages/actionModule/EditActionPage";
import { useLocale } from "../../hooks/UseLocale";
import Incentivation from "../../pages/incentivation/Incentivation";
import CompanyManagement from "../../pages/companyManagement/CompanyManagement";
import AccountManagement from "../../pages/accountManagement/AccountManagement";
import ActionModuleManagement from "../../pages/actionModuleManagement/ActionModuleManagement";
import BillingPage from "../../pages/billing/BillingPage";
import ActionModules from "../../pages/actionModules/ActionModules";
import Certificates from "../../pages/documentManagement/Certificates/Certificates";
import SuperAdminArea from "../../pages/superAdminArea/SuperAdminArea";
import BillingAdministration from "../../pages/superAdminArea/BillingAdministration";
import Dashboard from "../../pages/dashboard/Dashboard";
import { keyValueStorage } from "../../common/logic/KeyValueStore";
import AppDesignPage from "../../pages/configuration/AppDesign/AppDesignPage";

export function RouteDefinitions() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const locale = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const userRole = keyValueStorage.getObject<string>("userRole", "");
  useEffect(() => {
    if (location.pathname === "/") {
      navigate(`/${i18n.language}`);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  const isAuthenticated =
    useObservable(
      authenticationStateHandler.isAuthenticatedObservable,
      authenticationStateHandler.isAuthenticated
    ) ?? false;

  const isSuperAdmin = (userRole === "Administrator")

  return (
    <Routes location={location}>
      <Route path={`/${locale}`} element={<LayoutsWithNavbar />}>
        {isSuperAdmin &&
          <Route
            path={`/${locale}`}
            element={
              <ProtectedRoute
                isLoggedIn={isAuthenticated}
                element={<SuperAdminArea />}
              />
            }
          />
        }
        <Route
          path={`/${locale}`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<Dashboard />}
            />
          }
        />
        <Route
          path={`/${locale}/*`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<NotFoundPage />}
            />
          }
        />
        <Route
          path={`/${locale}/groups/:groupsType`}
          element={
            <ProtectedRoute isLoggedIn={isAuthenticated} element={<Groups />} />
          }
        />
        <Route
          path={`/${locale}/groups/:groupsType/create-action`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<CreateActionPage />}
            />
          }
        />
        <Route
          path={`/${locale}/groups/:groupsType/edit-action/:actionId`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<EditActionPage />}
            />
          }
        />
        <Route
          path={`/${locale}/actionmodules/:actionModulesType`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<ActionModules />}
            />
          }
        />
        <Route
          path={`/${locale}/documentManagement/:certificatesTrainings`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<Certificates />}
            />
          }
        />
        <Route
          path={`/${locale}/incentivation/:groupsType`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<Incentivation />}
            />
          }
        />
        <Route
          path={`/${locale}/company-management`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<CompanyManagement />}
            />
          }
        />
        <Route
          path={`/${locale}/account-management`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<AccountManagement />}
            />
          }
        />
        <Route
          path={`/${locale}/incentivation/:groupsType/:subtype`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<ActionModuleManagement />}
            />
          }
        />
        <Route
          path={`/${locale}/incentivation/:groupsType/:subtype/:actionModuleId`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<ActionModuleManagement />}
            />
          }
        />
        <Route
          path={`/${locale}/billing`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<BillingPage />}
            />
          }
        />
        <Route
          path={`/${locale}/configuration/app-design`}
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              element={<AppDesignPage />}
            />
          }
        />
        <Route
          path={`/${locale}/superadminarea`}
          element={
            <SuperAdminRoute
              isSuperAdmin={isSuperAdmin}
              isLoggedIn={isAuthenticated}
              element={<SuperAdminArea />}
            />
          }
        />
        <Route
          path={`/${locale}/superadminarea/billingadministration`}
          element={
            <SuperAdminRoute
              isSuperAdmin={isSuperAdmin}
              isLoggedIn={isAuthenticated}
              element={<BillingAdministration />}
            />
          }
        />
      </Route>

      <Route
        path={`/${locale}/login`}
        element={<LoginScreen navigateToUrlWhenLoggedIn="/" />}
      />
      <Route path={`/${locale}/register`} element={<RegistrationScreen />} />
      <Route
        path={`/${locale}/reset_password`}
        element={<ResetPasswordScreen />}
      />
      <Route
        path={`/${locale}/linking/email_confirmation`}
        element={
          <LinkingEmailConfirmationScreen navigateToUrlWhenConfirmed="/" />
        }
      />
      <Route
        path={`/${locale}/linking/password_reset`}
        element={<LinkingResetPasswordScreen />}
      />
    </Routes>
  );
}

interface ProtectedRouteProps {
  isLoggedIn: boolean;
  element: React.JSX.Element;
}

interface SuperAdminRouteProps {
  isSuperAdmin: boolean;
  isLoggedIn: boolean;
  element: React.JSX.Element;
}

function ProtectedRoute(props: ProtectedRouteProps): React.JSX.Element {
  const locale = useLocale();
  if (props.isLoggedIn) {
    return props.element;
  } else {
    return <Navigate to={`/${locale}/login`} />;
  }
}

function SuperAdminRoute(props: SuperAdminRouteProps): React.JSX.Element {
  const locale = useLocale();
  if (props.isSuperAdmin) {
    return props.element;
  } else {
    return <Navigate to={`/${locale}/login`} />;
  }
}
