import { BillType, PaymentMethod, PaymentStatus } from "../../types/bills";

export const renderBillTypeSwitch = (billType: string) => {
  switch (billType) {
    case BillType.GeneralLicense:
      return 'General license';
    case BillType.TaxFreeBenefitBill:
      return 'Tax free benefit bill'
    case BillType.NewEmployeesActivationBill:
      return 'New employees activation bill'
    default:
      return billType
  }
}

export const renderPaymentMethodSwitch = (paymentMethod: string) => {
  switch (paymentMethod) {
    case PaymentMethod.OnAccount:
      return 'On account';
    case PaymentMethod.SepaDirectDebit:
      return 'Sepa Direct Debit'
    default:
      return paymentMethod
  }
}

export const renderPaymentStatusSwitch = (paymentStatus: string) => {
  switch (paymentStatus) {
    case PaymentStatus.WaitingForPayment:
      return 'Waiting for payment'
    default:
      return paymentStatus
  }
}