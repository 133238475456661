import { Box } from "@mui/material";
import {
  usePagination,
  useTaxFreeBenefitUsers,
} from "../../../../store/selectors/taxFreeBenefitSelectors";
import TaxFreeBenefitTableRow from "./TaxFreeBenefitTableRow";
import { TaxFreeBenefitUserType } from "../../../../types/taxFreeBenefitUsersType";
import { useTableDataToRender } from "../../../../hooks/UseTableDataToRender";
import { useTableDataPaginated } from "../../../../hooks/UseTablePagination";
import Pagination from "../../../../components/common/table/Pagination";
import { setPagination } from "../../../../store/reducers/taxFreeBenefitReducer";

const TaxFreeBenefitTableBody = (props: { searchQuery: string }) => {
  const { searchQuery } = props;
  const tableData = useTaxFreeBenefitUsers();
  const { totalPages, itemsPerPage, currentPage } = usePagination();
  const tableDataPaginated = useTableDataPaginated(
    tableData,
    itemsPerPage,
    currentPage
  );
  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableDataPaginated,
    ["firstName", "lastName", "employeeNumber", "position"]
  );

  const tableRows = tableDataToRender.map((item: TaxFreeBenefitUserType) => (
    <TaxFreeBenefitTableRow key={item.id} {...item} />
  ));

  return (
    <>
      {tableRows}
      <Box sx={{ gridColumn: "1/6" }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={tableData.length}
          setPagination={setPagination}
        />
      </Box>
    </>
  );
};

export default TaxFreeBenefitTableBody;
