import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      main: string;
      secondary: string;
      subform: string;
    };
    backgroundColor: {
      main: string;
      secondary: string;
    };
    companyMain: {
      main: string;
      hover: string;
    };
    textMain: {
      main: string;
      secondary: string;
      black: string;
    };
    warningColor: {
      main: string;
    };
  }
  interface PaletteOptions {
    border: {
      main: string;
      secondary: string;
      subform: string;
    };
    backgroundColor: {
      main: string;
      secondary: string;
    };
    companyMain: {
      main: string;
      hover: string;
    };
    textMain: {
      main: string;
      secondary: string;
      black: string;
    };
    warningColor: {
      main: string;
    };
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#43B8BB",
      },
      secondary: {
        main: "#C5BEA2",
      },
      border: {
        main: "#e7e7e7",
        secondary: "#333333",
        subform: "#707070",
      },
      backgroundColor: {
        main: "#fff",
        secondary: "#f1f5f9",
      },
      companyMain: {
        main: "#43B8BB",
        hover: "#3da5a9",
      },
      textMain: {
        main: "#404040",
        secondary: "#b7b5b5",
        black: "#000",
      },
      warningColor: {
        main: "#be2621",
      },
    },
    typography: {
      h1: { fontSize: "3rem", fontWeight: "700" },
      h2: { fontSize: "2.5rem", fontWeight: "700" },
      h3: { fontSize: "2.2rem", fontWeight: "700" },
      h4: { fontSize: "1.75rem", fontWeight: "700" },
      h5: { fontSize: "1.5rem", fontWeight: "700" },
      h6: { fontSize: "1.25rem", fontWeight: "700" },
      subtitle1: {
        fontSize: "1.25rem",
      },
    },
  })
);
