import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveTaxFreeBenefitTable } from "../reducers/taxFreeBenefitReducer";
import { RootState } from "../store";
import { employeesApi } from "../../api/employeesApi";
import { TaxFreeBenefitUserType } from "../../types/taxFreeBenefitUsersType";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchTaxFreeBenefitUsersThunk = createAsyncThunk(
  "taxFreeBenefit/taxFreeBenefitUsers",
  async (_, thunkApi) => {
    try {
      const users = await employeesApi.getTaxFreeBenefitUsers();
      thunkApi.dispatch(setProcessingRequest(false))
      return users ? (users as TaxFreeBenefitUserType[]) : [];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const editTaxFreeBenefitUserThunk = createAsyncThunk(
  "taxFreeBenefit/editTaxFreeBenefit",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const usersToEdit = state.taxFreeBenefit.usersToEdit;
    const userToEditRequests: any[] = usersToEdit.map((user) =>
      employeesApi.editTaxFreeBenefitUser(user.id, {
        groupId: user.groupId,
        status: user.status,
      })
    );

    Promise.all(userToEditRequests)
      .then(res => {
        thunkApi.dispatch(saveTaxFreeBenefitTable());
        return res
      })
      .catch(error => console.log(error))
  }
);
