import { AccentColorPayload, AppDesign } from "../types/configurationType";
import { doApiGet, doApiPatch, doApiPost } from "./Requests";

export const configurationApi = {
    getAppDesignData: (): Promise<Partial<AppDesign> | void> =>
        doApiGet(`/api/companies/current/customizations`)
            .then(res => res.result as Partial<AppDesign>)
            .catch(error => console.log(error)),

    uploadCompanyLogo: (fileContent: string | null) =>
        doApiPost(`/api/companies/current/customizations/companyLogo`, fileContent)
            .then(res => res.result)
            .catch(error => console.log(error)),

    editAccentColor: (accentColorJSON: AccentColorPayload) =>
        doApiPatch(`/api/companies/current/customizations`, accentColorJSON)
            .then(res => res.result)
            .catch(error => console.log(error)),
}