import { useRef, useState } from 'react'
import UploadIcon from "@mui/icons-material/Upload";
import { theme } from "../../../../App";
import { UploadButtonStyles } from "../../BillingPage.styles";
import { billingFileUploadThunk } from '../../../../store/thunk/superAdminBillingsThunk';
import { useAppDispatch } from '../../../../hooks/StoreHooks';

export interface FileUploadData {
    billingId: number
    fileType: string
    fileContent: string | undefined
}

const FileUploaderBox = ({ billingId, fileType }: { billingId: number, fileType: string }) => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [base64, setBase64] = useState<string>();
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onload = onReaderLoaded
            reader.readAsBinaryString(e.target.files[0])
        }
    };

    const onReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        setBase64(btoa(binaryString))
    }

    const handleUpload = async () => {
        if (file) {
            let fileDataToSend: FileUploadData = { billingId, fileType, fileContent: base64 }
            dispatch(billingFileUploadThunk(fileDataToSend));
        }
    };

    return (
        <div>
            <UploadButtonStyles onClick={() => handleClick()}>
                <input type="file" ref={hiddenFileInput} style={{ display: "none" }} onChange={handleChange} />
                <UploadIcon sx={{ color: file ? theme.palette.border.subform : theme.palette.companyMain.main }} />
            </UploadButtonStyles>

            {file ? (
                <p>{file.name}</p>
            ) : (
                <p>Select</p>
            )}
            {file &&
                <div>
                    <button onClick={handleUpload}>Submit</button>
                </div>
            }
        </div>
    )
}

export default FileUploaderBox