import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { configurationApi } from "../../api/configurationApi";
import { AccentColorPayload, AppDesign } from "../../types/configurationType";

export const fetchAppDesignThunk = createAsyncThunk(
    "configuration/appDesign",
    async () => {
        try {
            const res = await configurationApi.getAppDesignData();
            return res as Partial<AppDesign>;
        } catch (error) {
            console.log(error);
        }
        return [];
    }
);

export const companyLogoUploadThunk = createAsyncThunk(
    "configuration/appDesign/uploadLogo",
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const newCompanyLogo = state.configuration.appDesign.companyLogoBase64;
        let res = await configurationApi.uploadCompanyLogo(newCompanyLogo ?? null)
        return res;
    }
  );

export const editAccentColorThunk = createAsyncThunk(
    "configuration/appDesign/editColor",
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const newCompanyColor : AccentColorPayload = {
            accentColor: state.configuration.appDesign.accentColor ?? null
        }
        let res = newCompanyColor && await configurationApi.editAccentColor(newCompanyColor)
        return res;
    })
