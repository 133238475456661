import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { fetchGroupsThunk } from "../../store/thunk/groupsThunk";
import { fetchActions } from "../../store/thunk/actionsThunk";
import ActionsBlock from "./ActionsBlock/ActionsBlock";
import PageTitle from "../../components/common/PageTitle/PageTitle";

const breadcrumbs = [
  {
    title: "actionModulesPage.actionModules.taxFreeBenefit",
    link: "actionmodules/taxfreebenefit",
  },
];

const ActionModules = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchGroupsThunk());
    dispatch(fetchActions());
  }, []);

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("actionModulesPage.actionModules.title")}
      />
      <ActionsBlock />
    </>
  );
};

export default ActionModules;
